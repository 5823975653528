import styled, { css, keyframes } from 'styled-components';
import IconButton from '@mui/material/IconButton';
import { grey, green } from '@mui/material/colors';

const rotate = keyframes`
  0% {
    transform: rotate(180deg);
    color: ${grey[800]};
  }

  10% {
    transform: rotate(0deg);
    color: ${green[800]};
  }

  100% {
    transform: rotate(0deg);
    color: ${green[800]};
  }
`;

const floatingAnimation = keyframes`
  0% {
    box-shadow: 0 0 0 0 rgba(46, 125, 50, 0.8);
    background-color: ${green[200]};
  }
  15% {
    box-shadow: 0 0 0 15px transparent;
    background-color: white;
  }
  100% {
    box-shadow: 0 0 0 0 transparent;
    background-color: white;
  }
`;

export const ToggleScreenBtn = styled(IconButton)`
  padding: 6px !important;

  svg {
    font-size: 1.2em !important;
  }
`;

export const SettingsPanelArea = styled.div`
  display: flex;
  flex-flow: row nowrap;
  width: 0;
  transition: width 0.2s ease;

  ${(props) => props.isOpened && css`
    width: 500px;
  `}
`;

export const SettingsPanelWrapper = styled.div`
  background-color: white;
  display: flex;
  flex-flow: row nowrap;

  border-radius: 50% 0 0 50%;
  border: 1px solid ${grey[100]};
  padding: 0.5em;
  position: absolute;
  top: 1em;
  right: 0;

  animation: ${floatingAnimation} 4s linear infinite;
  box-sizing: border-box;
  box-shadow: 0 0 10px 0 rgba(01,0,0,0.6);

  z-index: 998;

  &:hover {
    animation: none;
    transform: translateX(0px);

    ${ToggleScreenBtn} {
      animation: none;
      transform: rotate(0deg);
    }
  }

  ${ToggleScreenBtn} {
    animation: ${rotate} 4s linear infinite;
  }
`;

export const DraggableList = styled.div`
  display: flex;
  flex-flow: column nowrap;
`;

export const DraggableItem = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  border-radius: 0.5em;
  margin-bottom: 0.5em;
  padding: 0.5em 1em 0.5em 0.5em;

  &::before {
    display: flex;
    align-self: center;
    align-items: center;
    justify-content: center;

    counter-increment: draggableItem;
    content: counter(draggableItem);

    line-height: 1;
    height: 1em;
    width: 1em;

    background-color: ${grey[200]};
    border-radius: 500rem;
    margin-right: 0.5em;
    padding: 0.5em;
  }

  ${(props) => props.isActive && css`
    background-color: ${grey[100]};
  `}

  ${(props) => props.isDragging && css`
    background-color: ${grey[50]};
  `}
`;
