import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  DialogContent,
  DialogSection,
  DialogSectionTitle,
  DialogSectionText,
  DialogShowAllLink,
  AmenityList,
} from '../styles';

import { FlexFlow } from '../../../../Common/helpers/DisplayHelpers';
import { i18n } from '../../../../../src/custom/Internationalization';

function HotelDescription({
  amenities,
  description,
}) {
  const [showAll, setShowAll] = useState(false);
  return (
    <DialogSection>
      <DialogSectionTitle dark>
        {i18n.t('hotel_card.headings.hotel_info')}
      </DialogSectionTitle>

      <DialogContent>
        <FlexFlow splitSpace="3em" nowrap>
          <AmenityList>
            <DialogSectionTitle>
              {i18n.t('hotel_card.headings.amenities')}
            </DialogSectionTitle>

            <ul id="amenities_list" className={showAll ? 'show_all' : ''}>
              {amenities.map((a) => <li key={a}>{a}</li>)}
            </ul>

            {amenities.length > 5 && (
              <DialogShowAllLink onClick={() => setShowAll(!showAll)}>
                {showAll ? i18n.t('actions.hide') : i18n.t('actions.show_all')}
              </DialogShowAllLink>
            )}
          </AmenityList>

          <FlexFlow column shrink>
            <DialogSectionTitle>
              {i18n.t('hotel_card.headings.description')}
            </DialogSectionTitle>
            <DialogSectionText>
              {description}
            </DialogSectionText>
          </FlexFlow>
        </FlexFlow>
      </DialogContent>
    </DialogSection>
  );
}

HotelDescription.defaultProps = {
  description: '',
};

HotelDescription.propTypes = {
  amenities: PropTypes.arrayOf(
    PropTypes.shape(),
  ).isRequired,
  description: PropTypes.string,
};

export default HotelDescription;
