import styled from 'styled-components';
import { styled as MuiStyled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { grey } from '@mui/material/colors';
import colors from '../../Common/constants/colors';

export const ActionsWrapper = styled.div`
  margin-left: auto;
`;

export const IconsWrapper = styled.div`
  color: ${colors.groupizeBrand};
  font-size: 1.6em;
`;

export const StyledTable = MuiStyled(Table)({
  border: `1px solid ${grey[200]}`,
});

export const StyledTableCell = MuiStyled(TableCell)({
  [`&.${tableCellClasses.body}`]: {
    '&.iconWrapper': {
      color: grey[500],
      fontSize: '2em',
      lineHeight: 1,
      padding: '1rem 0 1rem 1rem',
      textAlign: 'center',
    },
    '& > p': {
      fontSize: '1em',
      fontWeight: 400,
      lineHeight: 1.4,
      margin: 0,
    },

    '& > small': {
      fontSize: '0.8em',
      lineHeight: 1.4,
      color: grey[500],
      margin: 0,
    },
  },
});
