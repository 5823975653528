/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';

import { FlexFlow } from '../Common/helpers/DisplayHelpers';

import EbidContent from './EbidContent';
import HotelCard from './HotelCard';
import EbidsSidebar from './EbidsSidebar';

import { i18n } from '../../src/custom/Internationalization';

function HotelDetailsPage({
  hotelCardOptions,
  ebidsContentOptions,
  sidebarOptions,
  filepickerConfigs,
}) {
  return (
    <React.Suspense fallback={i18n.t('actions.loading')}>
      <FlexFlow
        splitSpace="1em"
        grow
        shrink
      >
        <FlexFlow
          column
          grow
          shrink
          basis="0%"
          splitSpace="1em"
        >
          <HotelCard {...hotelCardOptions} filepickerConfigs={filepickerConfigs} />
          <EbidContent
            addNoteUrl={ebidsContentOptions.addNoteUrl}
            uploadFileUrl={ebidsContentOptions.uploadFileUrl}
            messages={ebidsContentOptions.messages}
            attachments={ebidsContentOptions.attachments}
            notes={ebidsContentOptions.notes}
            contactEmails={ebidsContentOptions.contactEmails}
            filepickerConfigs={filepickerConfigs}
            followUpUrl={hotelCardOptions.followUpUrl}
            changeDatesUrl={hotelCardOptions.changeDatesUrl}
            changeRequirementsUrl={hotelCardOptions.changeRequirementsUrl}
            requestContractUrl={hotelCardOptions.requestContractUrl}
            hotelReminderUrl={hotelCardOptions.hotelReminderUrl}
            sendMessageUrl={hotelCardOptions.sendMessageUrl}
            setLabelUrl={hotelCardOptions.setLabelUrl}
            bidId={hotelCardOptions.bidId}
            bidSent={hotelCardOptions.bidSent}
            event={hotelCardOptions.event}
            emailTemplates={hotelCardOptions.emailTemplates}
          />
        </FlexFlow>

        <EbidsSidebar
          baseURL={sidebarOptions.baseURL}
          linkURL={sidebarOptions.linkURL}
        />
      </FlexFlow>
    </React.Suspense>
  );
}

HotelDetailsPage.propTypes = {
  hotelCardOptions: PropTypes.shape().isRequired,
  ebidsContentOptions: PropTypes.shape().isRequired,
  sidebarOptions: PropTypes.shape().isRequired,
  filepickerConfigs: PropTypes.shape().isRequired,
};

export default HotelDetailsPage;
