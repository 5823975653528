import React from 'react';
import PropTypes from 'prop-types';

import {
  DialogSection,
  DialogSectionTitle,
} from '../styles';

import GMapArea from '../../../../Common/GoogleMap/GMapArea';
import { i18n } from '../../../../../src/custom/Internationalization';

function Location({
  event: {
    latitude: eventLat,
    longitude: eventLng,
  },
  property: {
    id,
    latitude,
    longitude,
    name,
  },
}) {
  return (
    <DialogSection>
      <DialogSectionTitle dark>
        {i18n.t('hotel_card.headings.location')}
      </DialogSectionTitle>

      <div className="content">
        <GMapArea
          poi={{
            icon: 'https://s3.amazonaws.com/groupizereg-production/icons/poi_map_icon_m.png',
            position: {
              lat: eventLat,
              lng: eventLng,
            },
          }}
          markers={[{
            id,
            position: {
              lat: latitude,
              lng: longitude,
            },
            icon: 'https://s3.amazonaws.com/groupizereg-production/icons/place_green_m.png',
            isActive: false,
            content: (<p>{name}</p>),
          }]}
        />
      </div>
    </DialogSection>
  );
}

Location.propTypes = {
  event: PropTypes.shape().isRequired,
  property: PropTypes.shape().isRequired,
};

export default Location;
