/* eslint-disable react/jsx-props-no-spreading */
import React, { Fragment, useState } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import Alert from '@mui/material/Alert';
import Input from '@mui/material/Input';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {
  FiFile,
  FiTrash2,
  FiDownloadCloud,
} from 'react-icons/fi';
import FilePicker from '../../Common/Form/FilePicker/FilePicker';
import MessageSendForm from '../../Common/Messages/SendForm';
import SimpleTabs from '../../Common/StyledTabs/SimpleTabs';
import MessageItem from '../../Common/Messages/Item';
import Divider from '../../Common/Divider/Divider';
import AlertMessage from '../../Common/AlertMessage/AlertMessage';
import MenuActions from './MenuActions';
import {
  StyledTable,
  StyledTableCell,
  ActionsWrapper,
} from './styles';
import ConfirmationDialog from '../../Dialogs/ConfirmationDialog';
import { FlexFlow, DashboardContent } from '../../Common/helpers/DisplayHelpers';
import { GroupizeBtn } from '../../Common/helpers/styles';
import { i18n } from '../../../src/custom/Internationalization';

const token = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
axios.defaults.headers.common['X-CSRF-Token'] = token;

function EbidContent({
  uploadFileUrl,
  addNoteUrl,
  followUpUrl,
  changeDatesUrl,
  changeRequirementsUrl,
  requestContractUrl,
  hotelReminderUrl,
  sendMessageUrl,
  setLabelUrl,
  bidId,
  bidSent,
  event,
  emailTemplates,
  notes,
  messages,
  attachments,
  contactEmails,
  filepickerConfigs,
  filepickerConfigs: {
    apiKey,
    policy,
    signature,
    cname,
  },
}) {
  const [tab, setTab] = useState(0);
  const [isNotesModalOpen, setNotesModalOpen] = useState(false);
  const [attachmentsList, setAttachmentsList] = useState(attachments);
  const [messagesList, setMessagesList] = useState(messages);
  const [notesList, setNotesList] = useState(notes);
  const [messageFilter, setMessageFilter] = useState('all');
  const [newNote, setNewNote] = useState('');

  const [alertData, setAlertData] = useState({
    open: false,
    handleClose: () => setAlertData({ ...alertData, open: false }),
    variant: 'success',
    message: '',
    duration: 2000,
  });

  const showSuccessAlert = (message) => {
    setAlertData({
      open: true,
      variant: 'success',
      message,
      handleClose: () => setAlertData({ ...alertData, open: false }),
      duration: 2000,
    });
  };

  const showErrorAlert = (message, error) => {
    window.Rollbar.log(error);
    setAlertData({
      open: true,
      variant: 'error',
      message,
      handleClose: () => setAlertData({ ...alertData, open: false }),
      duration: 2000,
    });
  };

  const handleSendMessageFields = (fields) => {
    setNewNote(fields.message);
  };

  const axiosInstance = axios.create({
    baseUrl: '/',
    headers: {
      'Content-Type': 'application/json',
    },
  });

  const handleUpdateMessageLabel = (messageId, label) => {
    axiosInstance.put(setLabelUrl, {
      message_id: messageId,
      label,
    })
      .then((res) => {
        if (res.status === 200) {
          const newMessageList = messagesList.map((m) => (
            m.id === messageId
              ? {
                ...m,
                label,
              }
              : { ...m }
          ));
          setMessagesList(newMessageList);
        }
      })
      .catch((error) => {
        showErrorAlert(i18n.t('errors.notices.generic_failure'), error);
      });
  };

  const handleAddNote = (messageText) => {
    setNotesModalOpen(false);
    axiosInstance.put(addNoteUrl, {
      ebid_note: messageText,
    }).then((res) => {
      if (res.status === 200) {
        const newNotes = notesList.slice();
        newNotes.unshift(res.data);
        setNotesList(newNotes);
      }
    }).catch((error) => {
      showErrorAlert(i18n.t('errors.notices.generic_failure'), error);
    });
  };

  const handleFollowUp = (message) => {
    axiosInstance.post(followUpUrl, {
      bid_ids: [bidId],
      message,
    }).then((res) => {
      if (res.status === 201) {
        showSuccessAlert(i18n.t('notices.generic_request_success'));
      }
    }).catch((error) => {
      showErrorAlert(i18n.t('errors.notices.generic_failure'), error);
    });
  };

  const handleUploadFile = (files) => {
    if (!files || files.length <= 0) {
      return;
    }
    const file = files[0];
    axiosInstance.put(uploadFileUrl, {
      url: file.url,
      file_file_name: file.filename,
      file_content_type: file.mimetype,
      file_file_size: file.size,
      file_handle: file.handle,
    }).then((res) => {
      if (res.status === 200) {
        const newAttachments = attachmentsList.slice();
        newAttachments.unshift(res.data);
        setAttachmentsList(newAttachments);
      }
    }).catch((error) => {
      showErrorAlert(i18n.t('errors.notices.generic_failure'), error);
    });
  };

  const handleRemoveUploadFile = ({ removeUrl }) => {
    axiosInstance.put(removeUrl).then((res) => {
      if (res.status === 200) {
        const newAttachments = attachmentsList.filter((att) => att.id !== res.data.id);
        setAttachmentsList(newAttachments);
      }
    }).catch((error) => {
      showErrorAlert(i18n.t('errors.notices.generic_failure'), error);
    });
  };

  const handleChangeDates = (data) => {
    const { startDate, endDate, message } = data;
    axiosInstance.post(changeDatesUrl, {
      bid_ids: [bidId],
      start_date_field: startDate,
      end_date_field: endDate,
      message,
    }).then((res) => {
      if (res.status === 201) {
        showSuccessAlert(i18n.t('notices.generic_request_success'));
      }
    }).catch((error) => {
      showErrorAlert(i18n.t('errors.notices.generic_failure'), error);
    });
  };

  const handleChangeRequirements = (data) => {
    const { requirements, message } = data;
    axiosInstance.post(changeRequirementsUrl, {
      bid_ids: [bidId],
      message,
      requirements,
    }).then((res) => {
      if (res.status === 201) {
        showSuccessAlert(i18n.t('notices.generic_request_success'));
      }
    }).catch((error) => {
      showErrorAlert(i18n.t('errors.notices.generic_failure'), error);
    });
  };

  const handleRequestContract = (data) => {
    const { message } = data;
    axiosInstance.post(requestContractUrl, {
      bid_ids: [bidId],
      message,
    }).then((res) => {
      if (res.status === 201) {
        showSuccessAlert('Request successfully sent!');
      }
    }).catch((error) => {
      showErrorAlert(i18n.t('errors.notices.generic_failure'), error);
    });
  };

  const handleHotelReminder = (alert) => {
    axiosInstance.post(hotelReminderUrl, {
      bid_ids: [bidId],
    }).then((res) => {
      if (res.status === 201) {
        showSuccessAlert(alert);
      }
    }).catch((error) => {
      showErrorAlert(i18n.t('errors.notices.generic_failure'), error);
    });
  };

  const handleSendMessage = (data) => {
    const {
      attachment,
      label,
      recipients,
      message,
      subject,
    } = data;
    axiosInstance.post(sendMessageUrl, {
      bid_id: bidId,
      attachments: attachment,
      label,
      recipients,
      message,
      subject,
    })
      .then((res) => {
        if (res.status === 204) {
          const newMessages = messagesList.slice();
          newMessages.unshift(res.data);
          setMessagesList(newMessages);
          showSuccessAlert(i18n.t('ebids.notices.message_sent'));
        }
      })
      .catch((error) => {
        showErrorAlert(i18n.t('errors.notices.generic_failure'), error);
      });
  };

  const filteredMessages = messagesList.filter(({ label }) => {
    const filteredByLabel = (messageFilter === 'all') || (messageFilter === label);
    return filteredByLabel;
  });

  return (
    <>
      {!bidSent && (
        <Alert severity="warning">
          {i18n.t('ebids.notices.bid_not_sent')}
        </Alert>
      )}
      <SimpleTabs
        value={tab}
        onChange={(e, v) => setTab(v)}
        variant="scrollable"
        scrollButtons="auto"
        items={[
          { notification: 0, label: i18n.t('ebids.headings.messages') },
          { notification: 0, label: i18n.t('ebids.headings.attachments') },
          { notification: 0, label: i18n.t('ebids.headings.notes') },
        ]}
      />

      {tab === 0 && ( // Messages
        <DashboardContent paddedY>
          <FlexFlow grow align="center">
            <InputLabel>
              {`${i18n.t('actions.show')}:`}
              &nbsp;
            </InputLabel>
            <FormControl>
              <Select
                value={messageFilter}
                onChange={(e) => setMessageFilter(e.target.value)}
                input={<Input id="select-multiple-chip" />}
              >
                <MenuItem value="all">{i18n.t('ebids.message_types.all')}</MenuItem>
                <MenuItem value="property">{i18n.t('ebids.message_types.property')}</MenuItem>
                <MenuItem value="internal">{i18n.t('ebids.message_types.internal')}</MenuItem>
                <MenuItem value="other">{i18n.t('ebids.message_types.other')}</MenuItem>
              </Select>
            </FormControl>

            <ActionsWrapper>
              <MenuActions
                bidSent={bidSent}
                filepickerOptions={filepickerConfigs}
                event={event}
                contactEmails={contactEmails}
                emailTemplates={emailTemplates}
                onDatesChange={(data) => handleChangeDates(data)}
                onChangeRequirements={handleChangeRequirements}
                onRequestContract={handleRequestContract}
                onHotelReminder={handleHotelReminder}
                onFollowUp={handleFollowUp}
                onSendMessage={handleSendMessage}
              />
            </ActionsWrapper>
          </FlexFlow>
          <Divider />

          {filteredMessages.map((message) => (
            <Fragment key={message.id}>
              <div className="message-item">
                <MessageItem
                  message={message}
                  handleUpdateMessageLabel={handleUpdateMessageLabel}
                />
              </div>
              <Divider />
            </Fragment>
          ))}
        </DashboardContent>
      )}

      {tab === 1 && ( // Attachments
        <DashboardContent paddedY>
          <FilePicker
            apiKey={apiKey}
            signature={signature}
            policy={policy}
            cname={cname}
            id="file_to_attach"
            btnAddName={i18n.t('actions.attach_files')}
            onSubmit={(file) => handleUploadFile(file)}
          />

          <Divider />

          {attachmentsList && attachmentsList.length > 0 && (
            <StyledTable>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox" />
                  <StyledTableCell>{i18n.t('attachment.labels.file_name')}</StyledTableCell>
                  <StyledTableCell>{i18n.t('attachment.labels.owner')}</StyledTableCell>
                  <StyledTableCell align="right">{i18n.t('attachment.labels.size')}</StyledTableCell>
                  <StyledTableCell align="right">{i18n.t('actions.actions')}</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {attachmentsList.map((row) => (
                  <TableRow key={row.id}>
                    <StyledTableCell className="iconWrapper">
                      <FiFile />
                    </StyledTableCell>
                    <StyledTableCell>
                      <p>
                        {row.file_name}
                      </p>
                      <small>
                        {row.created_at}
                      </small>
                    </StyledTableCell>
                    <StyledTableCell>
                      {row.attachment_owner}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {row.file_size}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      <FlexFlow splitSpace="0.5rem" justify="flex-end">
                        <a href={row.file_url} target="blank">
                          <IconButton>
                            <FiDownloadCloud />
                          </IconButton>
                        </a>
                        {row.deleatable && (
                          <IconButton
                            color="secondary"
                            onClick={() => handleRemoveUploadFile(row)}
                          >
                            <FiTrash2 />
                          </IconButton>
                        )}
                      </FlexFlow>
                    </StyledTableCell>
                  </TableRow>
                ))}
              </TableBody>
            </StyledTable>
          )}
        </DashboardContent>
      )}

      {tab === 2 && ( // Notes
        <DashboardContent paddedY>
          <GroupizeBtn
            variant="outlined"
            onClick={() => setNotesModalOpen(true)}
          >
            {i18n.t('ebids.actions.add_note')}
          </GroupizeBtn>

          <Divider />

          {notesList.map(({
            id, message, date, author,
          }) => (
            <Fragment key={id}>
              <MessageItem message={{
                message,
                date,
                title: '',
                author,
                attachments: [],
                label: '',
              }}
              />
              <Divider />
            </Fragment>
          ))}
        </DashboardContent>
      )}

      <ConfirmationDialog
        open={isNotesModalOpen}
        title={i18n.t('ebids.actions.add_note')}
        maxWidth="md"
        cancelBtnCopy={i18n.t('actions.cancel')}
        confirmBtnCopy={i18n.t('actions.add')}
        handleCancel={() => setNotesModalOpen(false)}
        handleConfirm={() => handleAddNote(newNote)}
        showActionBtns
      >
        <MessageSendForm
          handleChangeFields={handleSendMessageFields}
          handleSendClick={() => (false)}
          hasRecipients={false}
          filepickerOptions={{
            apiKey,
            signature,
            policy,
          }}
        />
      </ConfirmationDialog>

      <AlertMessage {...alertData} />
    </>
  );
}

EbidContent.defaultProps = {
  notes: [],
  messages: [],
  contacts: [],
  contactEmails: [],
  attachments: [],
  bidSent: false,
  filepickerConfigs: {
    apiKey: '',
    policy: '',
    signature: '',
  },
};

EbidContent.propTypes = {
  uploadFileUrl: PropTypes.string.isRequired,
  addNoteUrl: PropTypes.string.isRequired,
  followUpUrl: PropTypes.string.isRequired,
  changeDatesUrl: PropTypes.string.isRequired,
  changeRequirementsUrl: PropTypes.string.isRequired,
  requestContractUrl: PropTypes.string.isRequired,
  hotelReminderUrl: PropTypes.string.isRequired,
  sendMessageUrl: PropTypes.string.isRequired,
  setLabelUrl: PropTypes.string.isRequired,
  bidId: PropTypes.number.isRequired,
  bidSent: PropTypes.bool,
  event: PropTypes.shape().isRequired,
  emailTemplates: PropTypes.shape().isRequired,
  notes: PropTypes.arrayOf(PropTypes.shape()),
  messages: PropTypes.arrayOf(PropTypes.shape()),
  attachments: PropTypes.arrayOf(PropTypes.shape()),
  contacts: PropTypes.arrayOf(PropTypes.shape()),
  contactEmails: PropTypes.arrayOf(PropTypes.string),
  filepickerConfigs: PropTypes.shape(),
};

export default EbidContent;
