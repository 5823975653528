import React from 'react';
import PropTypes from 'prop-types';

import {
  DialogContent,
  DialogSection,
  DialogSectionTitle,
  AmenityList,
} from '../styles';
import { FlexFlow } from '../../../../Common/helpers/DisplayHelpers';
import { i18n } from '../../../../../src/custom/Internationalization';

function Amenities({
  meetingRoomInfo: {
    amenitiesSet: {
      property,
      meetingService,
      propertyBusinessService,
    },
  },
}) {
  return (
    <DialogSection>
      <DialogSectionTitle dark>
        {i18n.t('hotel_card.headings.amenities_and_services')}
      </DialogSectionTitle>

      <DialogContent>
        <FlexFlow splitSpace="3em">
          {property.length > 0 && (
            <AmenityList>
              <DialogSectionTitle>
                {i18n.t('hotel_card.headings.amenities')}
              </DialogSectionTitle>
              <ul id="amenities_list">
                {property.map((a) => <li key={a}>{a}</li>)}
              </ul>
            </AmenityList>
          )}

          {meetingService.length > 0 && (
            <AmenityList>
              <DialogSectionTitle>
                {i18n.t('hotel_card.headings.meeting_services')}
              </DialogSectionTitle>
              <ul id="meeting_services_list">
                {meetingService.map((a) => <li key={a}>{a}</li>)}
              </ul>
            </AmenityList>
          )}

          {propertyBusinessService.length > 0 && (
            <AmenityList>
              <DialogSectionTitle>
                {i18n.t('hotel_card.headings.business_services')}
              </DialogSectionTitle>
              <ul id="business_services_list">
                {propertyBusinessService.map((a) => <li key={a}>{a}</li>)}
              </ul>
            </AmenityList>
          )}
        </FlexFlow>
      </DialogContent>
    </DialogSection>
  );
}

Amenities.propTypes = {
  meetingRoomInfo: PropTypes.shape().isRequired,
};

export default Amenities;
