import React, { useState } from 'react';
import PropTypes from 'prop-types';
import MoneyIcon from '@mui/icons-material/AttachMoney';
import EBidsCostsFormContent from './content/EBidsCostsFormContent';
import {
  SettingsPanelArea,
  SettingsPanelWrapper,
  ToggleScreenBtn,
} from './styles';
import DashboardSettingsPanel from '../../Common/DashboardSettingsPanel/DashboardSettingsPanel';
import { EbidsSidebarContextProvider } from './context';
import { i18n } from '../../../src/custom/Internationalization';

function EbidsSidebar({ baseURL, linkURL }) {
  const [openSettings, setOpenSettings] = useState(true);

  const handleOpenSettings = () => {
    setOpenSettings(!openSettings);
  };

  return (
    <SettingsPanelArea isOpened={openSettings}>
      <SettingsPanelWrapper>
        <ToggleScreenBtn onClick={handleOpenSettings}>
          <MoneyIcon />
        </ToggleScreenBtn>
      </SettingsPanelWrapper>

      <DashboardSettingsPanel
        isSeparated
        handleOpen={handleOpenSettings}
        isOpened={openSettings}
        mainForm={(
          <EbidsSidebarContextProvider baseURL={baseURL}>
            <EBidsCostsFormContent linkURL={linkURL} />
          </EbidsSidebarContextProvider>
        )}
        title={i18n.t('ebid_sidebar.headings.cost')}
        subTitle={i18n.t('ebid_sidebar.messages.add_costs')}
      />
    </SettingsPanelArea>
  );
}

EbidsSidebar.defaultProps = {
};

EbidsSidebar.propTypes = {
  baseURL: PropTypes.string.isRequired,
  linkURL: PropTypes.string.isRequired,
};

export default EbidsSidebar;
