/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import moment from 'moment';
import { i18n } from '../../../../src/custom/Internationalization';

export const Context = createContext({});

export function Provider({ baseURL, children }) {
  const token = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
  axios.defaults.headers.common['X-CSRF-Token'] = token;
  const axiosInstance = axios.create({
    baseURL,
    headers: { 'Content-Type': 'application/json' },
  });

  const [fields, setFields] = useState({});

  const STAGES = [
    { label: i18n.t('ebids.labels.cost_comparison.venue_offer'), value: 'venue_offer' },
    { label: i18n.t('ebids.labels.cost_comparison.initial_bid'), value: 'initial' },
    { label: i18n.t('ebids.labels.cost_comparison.negotiations'), value: 'negotiation' },
    { label: i18n.t('ebids.labels.cost_comparison.contracted'), value: 'contracted' },
    { label: i18n.t('ebids.labels.cost_comparison.final'), value: 'final' },
  ];

  useEffect(() => {
    const defaultBids = STAGES.map(({ value }) => ({ stage: value }));
    const defaultObj = { bid_contents: defaultBids };
    axiosInstance.get().then((res) => {
      setFields({ ...defaultObj, ...res.data });
    });
  }, []);

  const handleCopyStage = (fromStage, toStage) => {
    const bidContents = fields.bid_contents;
    const sourceBid = bidContents.find((i) => i.stage === fromStage);

    let newBidContents = bidContents;
    if (sourceBid) {
      newBidContents = bidContents.map((bid) => (
        (bid.stage === toStage)
          ? {
            ...sourceBid,
            id: bid.id,
            stage: toStage,
            bidFieldsUpdatedAt: new Date(),
            bidFieldsCopiedAt: new Date(),
          }
          : { ...bid }
      ));
    }

    if (newBidContents) {
      const newBidContent = newBidContents.filter((content) => content.stage === toStage).shift();
      const { id: bidId, bidFieldsUpdatedAt, ...fieldsToUpdate } = newBidContent;
      if (bidId) {
        axiosInstance.patch(`/${bidId}`, { bid_content: fieldsToUpdate });
      }
    }

    const newFormData = { bid_contents: newBidContents };
    setFields(newFormData);
  };

  const handleUpdateFields = (bidFields, onSuccess, onError) => {
    if (bidFields) {
      const { id: bidId, bidFieldsUpdatedAt, ...fieldsToUpdate } = bidFields;
      if (bidId) {
        axiosInstance.patch(`/${bidId}`, { bid_content: fieldsToUpdate })
          .then(() => {
            if (onSuccess && typeof (onSuccess) === 'function') {
              onSuccess();
            }
          })
          .catch((error) => {
            if (onError && typeof (onError) === 'function') {
              onError(error);
            }
          });
      }
    } else if (onError && typeof (onError) === 'function') {
      onError(i18n.t('errors.notices.generic_failure'));
    }
  };

  // eslint-disable-next-line no-self-compare
  const defNum = (x, d) => ((!x || (x !== x)) ? d : x);

  const getRoomsData = (data) => {
    const roomsData = {
      single: { rate: 0, num: 0, max: 0 },
      double: { rate: 0, num: 0, max: 0 },
      suite: { rate: 0, num: 0, max: 0 },
      totalRoomsNumber: 0,
      numberOfNights: 0,
      totalRoomCosts: 0,
      averageDailyRate: 0,
    };

    const getDates = (roomNights) => {
      const allDates = (roomNights || [])
        .map((i) => i.date)
        .sort((a, b) => (new Date(a) - new Date(b)));
      const startDate = allDates[0];
      const endDate = allDates[allDates.length - 1];
      return { startDate, endDate };
    };

    const rooms = data.bid_content_days || [];
    const { startDate, endDate } = getDates(rooms);

    const updateRoomTypeData = (rt) => (
      ['single', 'double', 'suite'].forEach((t) => {
        const numKey = `${t}_sleeping_room_amount`;
        const rateKey = `${t}_sleeping_room_cost_cents`;
        roomsData[t].rate += rt[rateKey] * rt[numKey];
        roomsData[t].num += rt[numKey];
        roomsData[t].max = (roomsData[t].max > rt[numKey]) ? roomsData[t].max : rt[numKey];
      })
    );

    rooms.forEach((rn) => { updateRoomTypeData(rn); });

    const getNumOfNights = () => {
      const nights = moment(endDate).diff(moment(startDate), 'days') + 1;
      return nights || 1;
    };

    roomsData.numberOfNights = getNumOfNights();

    roomsData.totalRoomsNumber = (
      roomsData.single.num
      + roomsData.double.num
      + roomsData.suite.num
    );

    const resortTaxes = defNum(data.sleeping_room_flat_tax_cents, 0)
      + defNum(data.sleeping_room_resort_fee_cents, 0);

    const roomTax = defNum(data.sleeping_room_tax, 0) / 100;

    const singleRoomAvgRate = roomsData.single.num > 0
      ? defNum(roomsData.single.rate, 0) / roomsData.single.num
      : 0;
    const doubleRoomAvgRate = roomsData.double.num > 0
      ? defNum(roomsData.double.rate, 0) / roomsData.double.num
      : 0;
    const suiteRoomAvgRate = roomsData.suite.num > 0
      ? defNum(roomsData.suite.rate, 0) / roomsData.suite.num
      : 0;

    roomsData.totalRoomCosts = Math.round(
      (
        (singleRoomAvgRate + singleRoomAvgRate * roomTax + resortTaxes)
        * defNum(roomsData.single.num, 0)
        + (doubleRoomAvgRate + doubleRoomAvgRate * roomTax + resortTaxes)
        * defNum(roomsData.double.num, 0)
        + (suiteRoomAvgRate + suiteRoomAvgRate * roomTax + resortTaxes)
        * defNum(roomsData.suite.num, 0)
      ),
    );

    const avgRate = Math.round(roomsData.totalRoomCosts / roomsData.totalRoomsNumber);
    roomsData.averageDailyRate = defNum(avgRate, 0);

    return roomsData;
  };

  const getCostsData = (data, roomCosts) => {
    const resultCosts = {
      meetingSpaceTotal: 0,
      audioVisualTotal: 0,
      totalCost: 0,
    };

    resultCosts.meetingSpaceTotal = Math.round(defNum(data.event_space_cost_cents, 0)
      + (defNum(data.food_and_beverage_cost_cents, 0))
      + ((defNum(data.food_and_beverage_tax, 0) / 100) * defNum(data.food_and_beverage_cost_cents, 0))
      + ((defNum(data.food_and_beverage_service_charge, 0) / 100) * defNum(data.food_and_beverage_cost_cents, 0)));

    resultCosts.audioVisualTotal = Math.round(
      (defNum(data.audio_visual_cost_cents, 0) + defNum(data.audio_visual_cost_cents, 0)
        * ((defNum(data.audio_visual_service_charge, 0) + defNum(data.audio_visual_service_tax, 0)) / 100)),
    );

    resultCosts.totalCost = Math.round(
      defNum(roomCosts.totalRoomCosts, 0)
      + defNum(resultCosts.meetingSpaceTotal, 0)
      + defNum(resultCosts.audioVisualTotal, 0)
      + defNum(data.other_fees_cents, 0),
    );

    return resultCosts;
  };

  const handleUpdateStageFields = (stage, newFields, callback) => {
    const newRoomsData = getRoomsData(newFields);
    const newTotalCosts = getCostsData(newFields, newRoomsData);

    const bidFieldsToSave = {
      ...newFields,
      sleeping_room_cost_cents: newRoomsData.totalRoomCosts,
      event_space_total_cost: newTotalCosts.meetingSpaceTotal,
      audio_visual_total_cost: newTotalCosts.audioVisualTotal,
      total_cost_cents: newTotalCosts.totalCost,
      total_sleeping_room_amount: newRoomsData.totalRoomsNumber,
      average_daily_rate_cents: newRoomsData.averageDailyRate,
    };

    const newBidContent = fields.bid_contents
      && fields.bid_contents.map((i) => (
        (i.stage === stage)
          ? { ...bidFieldsToSave, bidFieldsUpdatedAt: new Date() }
          : { ...i, bidFieldsUpdatedAt: new Date() }
      ));

    const newFormData = { bid_contents: newBidContent };
    setFields(newFormData);

    if (callback && typeof (callback) === 'function') {
      callback(bidFieldsToSave);
    }
  };

  const handleSaveStageFields = (stage, newFields, onSuccess, onError) => {
    handleUpdateStageFields(stage, newFields, (bidFieldsToSave) => {
      handleUpdateFields(bidFieldsToSave, onSuccess, onError);
    });
  };

  const contextData = {
    formData: fields,
    setFormData: setFields,
    stages: STAGES,
    handleCopyStage,
    handleUpdateStageFields,
    handleSaveStageFields,
    getRoomsData,
    getCostsData,
  };

  return (
    <Context.Provider value={contextData}>
      {children}
    </Context.Provider>
  );
}

export const { Consumer } = Context;

Provider.propTypes = {
  baseURL: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
