import React, { useState } from 'react';
import PropTypes from 'prop-types';
import TextArea from '../../../Common/Form/TextArea';
import ConfirmationDialog from '../../../Dialogs/ConfirmationDialog';
import { i18n } from '../../../../src/custom/Internationalization';

function DeclineForm({
  openModal,
  onDecline,
  onClose,
}) {
  const [declineMessage, setDeclineMessage] = useState('');

  return (
    <ConfirmationDialog
      open={openModal}
      title={i18n.t('hotel_card.headings.decline_reason')}
      maxWidth="md"
      cancelBtnCopy={i18n.t('actions.cancel')}
      confirmBtnCopy={i18n.t('actions.decline')}
      showActionBtns
      handleConfirm={() => onDecline(declineMessage)}
      handleCancel={onClose}
    >
      <TextArea
        id="decline_message"
        label={i18n.t('hotel_card.labels.decline_reason')}
        onChange={(value) => setDeclineMessage(value)}
        value={declineMessage}
        nomargin
      />
    </ConfirmationDialog>
  );
}

DeclineForm.propTypes = {
  openModal: PropTypes.bool.isRequired,
  onDecline: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default DeclineForm;
