/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useState,
  useContext,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import FormControl from '@mui/material/FormControl';
import cc from 'currency-codes';
import {
  FiPaperclip,
} from 'react-icons/fi';

import { TextField as MaterialTextField } from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import moment from 'moment';
import TextArea from '../../Common/Form/TextArea';
import FilePicker from '../../Common/Form/FilePicker/FilePicker';
import TextField from '../../Common/Form/TextField';
import SelectField from '../../Common/Form/SelectField/SelectField';
import { FlexFlow } from '../../Common/helpers/DisplayHelpers';
import DateField from '../../Common/Form/DateField/DateField';
import NumberField from '../../Common/Form/NumberField';
import colors from '../../Common/constants/colors';
import { validateEmail } from '../../../src/custom/Utils';
import {
  ModalWrapper,
  ModalTitle,
  ModalText,
  FormLabel,
  CellHeader,
  CellBody,
  TableRow,
  Required,
  StyledChip,
} from '../styles';

import { ModalContext } from '../context';
import { EventData } from './EventData';
import { i18n, i18nARAttr } from '../../../src/custom/Internationalization';

const currencies = cc.codes();
const filter = createFilterOptions();

function ApproveModalForm({
  approvers,
  approvalForms,
  filepickerOptions,
  eventData,
}) {
  const context = useContext(ModalContext);
  const {
    formData,
    setField,
    updateUsedFields,
    hasErrorMessage,
  } = context;

  const [sections, setSections] = useState([]);

  const loadSectionsForType = (typeId) => {
    const typeData = approvalForms.find((td) => td.id === typeId);
    const newSections = typeData ? typeData.sections : [];
    setField('formTypeName', typeData ? typeData.label : '', null);
    setSections(newSections);
  };

  useEffect(() => {
    if (formData.approvalType) {
      loadSectionsForType(formData.approvalType);
    }
    setField('approvalBy', moment(new Date()).format('YYYY-MM-DD'));
  }, []);

  const displayField = (id, type, name, required, options, sectionId, defaultValue) => {
    const sectionFields = formData.sections.find((s) => s.id === sectionId);

    let value = sectionFields && sectionFields[name] && sectionFields[name].value;
    if (value === undefined && defaultValue !== undefined) {
      value = defaultValue;
      setField(name, defaultValue, sectionId, id);
    }

    switch (type) {
      case 'textarea':
        return (
          <TextArea
            id={name}
            placeholder={i18n.t('placeholders.please_enter')}
            value={value}
            onChange={(v) => setField(name, v, sectionId, id)}
            onBlur={() => updateUsedFields(name)}
            bgColor={hasErrorMessage(name).length > 0 ? colors.red50 : colors.white}
            borderColor={hasErrorMessage(name).length > 0 ? colors.red600 : colors.grey200}
            nomargin
            minheight="50px"
            required={required}
            isRequired={required}
          />
        );
      case 'textfield':
        return (
          <TextField
            size="small"
            variant="outlined"
            id={name}
            value={value}
            onChange={(e) => setField(name, e.target.value, sectionId, id)}
            onBlur={() => updateUsedFields(name)}
            flexGrow
            nomargin
            error={hasErrorMessage(name).length > 0}
            helperText={hasErrorMessage(name).length > 0 ? hasErrorMessage(name).join(', ') : ''}
            bgColor={hasErrorMessage(name).length > 0 ? colors.red50 : colors.white}
            borderColor={hasErrorMessage(name).length > 0 ? colors.red600 : colors.grey200}
            minheight="30px"
          />
        );
      case 'select':
        return (
          <SelectField
            size="small"
            id={name}
            name={name}
            defaultValue={null}
            items={options}
            value={value}
            onChange={(val) => setField(name, val, sectionId, id)}
            onBlur={() => updateUsedFields(name)}
            bgColor={hasErrorMessage(name).length > 0 ? colors.red50 : colors.white}
            borderColor={hasErrorMessage(name).length > 0 ? colors.red600 : colors.grey200}
            flexGrow={1}
          />
        );
      case 'select_currency':
        return (
          <SelectField
            size="small"
            id={name}
            name={name}
            defaultValue={null}
            items={currencies.map((c) => ({ value: c, label: c }))}
            value={value}
            onChange={(val) => setField(name, val, sectionId, id)}
            onBlur={() => updateUsedFields(name)}
            bgColor={hasErrorMessage(name).length > 0 ? colors.red50 : colors.white}
            borderColor={hasErrorMessage(name).length > 0 ? colors.red600 : colors.grey200}
            flexGrow={1}
          />
        );
      case 'numberfield':
        return (
          <NumberField
            size="small"
            id={name}
            defaultNum={sectionFields && sectionFields[name] && sectionFields[name].value}
            onChanged={(v) => setField(name, v, sectionId, id)}
            onBlur={() => updateUsedFields(name)}
            flexGrow
            nomargin
            error={hasErrorMessage(name).length > 0}
            helperText={hasErrorMessage(name).length > 0 ? hasErrorMessage(name).join(', ') : ''}
            bgColor={hasErrorMessage(name).length > 0 ? colors.red50 : colors.white}
            borderColor={hasErrorMessage(name).length > 0 ? colors.red600 : colors.grey200}
            minheight="30px"
          />
        );
      case 'pricefield':
        return (
          <NumberField
            priceCent
            size="small"
            id={name}
            defaultNum={sectionFields && sectionFields[name] && sectionFields[name].value}
            onChanged={(v) => setField(name, v, sectionId, id)}
            onBlur={() => updateUsedFields(name)}
            flexGrow
            nomargin
            error={hasErrorMessage(name).length > 0}
            helperText={hasErrorMessage(name).length > 0 ? hasErrorMessage(name).join(', ') : ''}
            bgColor={hasErrorMessage(name).length > 0 ? colors.red50 : colors.white}
            borderColor={hasErrorMessage(name).length > 0 ? colors.red600 : colors.grey200}
            minheight="30px"
          />
        );
      case 'filepicker':
        return (
          <FilePicker
            {...filepickerOptions}
            maxFiles={5}
            id={name}
            isRequired={required}
            inputData={{
              label: i18n.t('approval_form.labels.filepicker_limit'),
            }}
            onSubmit={(v) => setField(name, v, sectionId, id)}
            variant="left"
            errorsText={hasErrorMessage(name).length > 0 ? hasErrorMessage(name).join(', ') : ''}
            btnAddName={(
              <FlexFlow align="center" splitSpace="1em">
                <FiPaperclip />
                <span>{i18n.t('headings.attachments')}</span>
              </FlexFlow>
            )}
          />
        );
      default:
        return <div />;
    }
  };

  return (
    <ModalWrapper>
      <FlexFlow column splitSpace="1em" padding="0 0 2em">
        <FlexFlow column grow>
          <FormLabel id="contacts">
            <Required />
            {i18nARAttr('approval_form.to_emails')}
            &nbsp;
            <small>
              (
              {i18n.t('approval_form.tooltips.email')}
              )
            </small>
          </FormLabel>
          <FormControl variant="outlined">
            <Autocomplete
              id="contacts"
              multiple
              options={approvers}
              getOptionLabel={(option) => {
                if (typeof option === 'string') {
                  return option;
                }
                const {
                  firstName,
                  lastName,
                  title,
                  email,
                } = option;
                if (firstName || lastName || title) {
                  const names = [];
                  const result = [];

                  if (firstName) names.push(firstName);
                  if (lastName) names.push(lastName);
                  names.push(`<${email}>`);

                  result.push(names.join(' '));
                  if (title) result.push(title);

                  return result.join(' - ');
                }
                return option.email;
              }}
              isOptionEqualToValue={(
                { email: optionEmail },
                { email: valueEmail },
              ) => (optionEmail === valueEmail)}
              freeSolo
              value={formData.contacts}
              renderTags={(value, getTagProps) => value.map(({ email }, index) => (
                <StyledChip size="small" key={email} label={email} {...getTagProps({ index })} />
              ))}
              renderInput={(params) => (
                <MaterialTextField variant="outlined" autoComplete="off" {...params} />
              )}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);
                // Suggest the creation of a new value
                if (params.inputValue !== '' && validateEmail(params.inputValue)) {
                  filtered.push({
                    email: params.inputValue,
                    title: i18n.t('actions.add_object', { name: params.inputValue }),
                  });
                }
                return filtered;
              }}
              onBlur={(event) => {
                event.preventDefault();
                const newValue = event.target.value;
                if (newValue.trim() === '') return;

                const newContacts = (formData && formData.contacts) || [];
                const newEmails = newValue.split(',');
                newEmails.forEach((v) => {
                  const email = v.trim();
                  if (email !== '' && validateEmail(email)) {
                    newContacts.push({ email });
                  }
                });
                setField('contacts', newContacts.map(({ email }) => ({ email })), null);
              }}
              onChange={(event, newValue) => {
                event.preventDefault();
                let newContacts = (formData && formData.contacts) || [];
                if (typeof newValue === 'string') {
                  newContacts.push({ email: newValue });
                } else if (newValue && newValue.inputValue) {
                  newContacts.push({ email: newValue.inputValue });
                } else {
                  newContacts = newValue;
                }

                const contacts = [];
                newContacts.forEach((contact) => {
                  if (typeof contact === 'string') {
                    const newEmails = contact.split(',');
                    newEmails.forEach((v) => {
                      const email = v.trim();
                      if (email !== '' && validateEmail(email)) {
                        contacts.push({ email });
                      }
                    });
                  } else if (contact.email) {
                    contacts.push({ email: contact.email });
                  }
                });

                setField('contacts', contacts.map(({ email }) => ({ email })), null);
              }}
            />
          </FormControl>
        </FlexFlow>

        <FlexFlow column grow>
          <FormLabel id="ccEmail">
            {i18nARAttr('approval_form.cc_emails')}
            &nbsp;
            <small>
              (
              {i18n.t('approval_form.tooltips.email')}
              )
            </small>
          </FormLabel>
          <FormControl variant="outlined">
            <Autocomplete
              id="ccEmails"
              multiple
              options={approvers}
              getOptionLabel={(option) => {
                if (typeof option === 'string') {
                  return option;
                }
                const {
                  firstName,
                  lastName,
                  title,
                  email,
                } = option;
                if (firstName || lastName || title) {
                  const names = [];
                  const result = [];
                  if (firstName) names.push(firstName);
                  if (lastName) names.push(lastName);
                  const name = names.join(' ');

                  result.push((name.trim() !== '') ? name : email);
                  if (title) result.push(title);

                  return result.join(', ');
                }
                return option.email;
              }}
              isOptionEqualToValue={(
                { email: optionEmail },
                { email: valueEmail },
              ) => (optionEmail === valueEmail)}
              freeSolo
              value={formData.ccEmails}
              renderTags={(value, getTagProps) => value.map(({ email }, index) => (
                <StyledChip size="small" key={email} label={email} {...getTagProps({ index })} />
              ))}
              renderInput={(params) => (
                <MaterialTextField variant="outlined" autoComplete="off" {...params} />
              )}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);
                // Suggest the creation of a new value
                if (params.inputValue !== '') {
                  filtered.push({
                    email: params.inputValue,
                    title: i18n.t('actions.add_object', { name: params.inputValue }),
                  });
                }
                return filtered;
              }}
              onBlur={(event) => {
                event.preventDefault();
                const newValue = event.target.value;

                if (newValue.trim() === '') return;

                const newContacts = (formData && formData.ccEmails) || [];
                const newEmails = newValue.split(',');
                newEmails.forEach((v) => {
                  const email = v.trim();
                  if (email !== '') {
                    newContacts.push({ email });
                  }
                });

                setField('ccEmails', newContacts.map(({ email }) => ({ email })), null);
              }}
              onChange={(event, newValue) => {
                event.preventDefault();
                let newContacts = (formData && formData.ccEmails) || [];
                if (typeof newValue === 'string') {
                  newContacts.push({ email: newValue });
                } else if (newValue && newValue.inputValue) {
                  newContacts.push({ email: newValue.inputValue });
                } else {
                  newContacts = newValue;
                }

                const ccEmails = [];
                newContacts.forEach((contact) => {
                  if (typeof contact === 'string') {
                    const newEmails = contact.split(',');
                    newEmails.forEach((v) => {
                      const email = v.trim();
                      if (email !== '') {
                        ccEmails.push({ email });
                      }
                    });
                  } else if (contact.email) {
                    ccEmails.push({ email: contact.email });
                  }
                });

                setField('ccEmails', ccEmails.map(({ email }) => ({ email })), null);
              }}
            />
          </FormControl>
        </FlexFlow>

        <FlexFlow splitSpace="1em">
          <FlexFlow column grow>
            <FormLabel>
              {i18nARAttr('approval_form.approve_by')}
            </FormLabel>
            <DateField
              id="approvalBy"
              value={moment(new Date()).format('YYYY-MM-DD')}
              onDateSelected={(v) => setField('approvalBy', v)}
              isDisabled={false}
              noMargin
              helpertext={hasErrorMessage('approvalBy').length > 0 ? hasErrorMessage('approvalBy').join(', ') : ''}
              error={hasErrorMessage('approvalBy').length > 0}
              bgColor={hasErrorMessage('approvalBy').length > 0 ? colors.red50 : colors.white}
              borderColor={hasErrorMessage('approvalBy').length > 0 ? colors.red600 : colors.grey200}
            />
          </FlexFlow>
        </FlexFlow>
      </FlexFlow>

      {sections.map(({
        id: sectionId,
        title,
        description,
        baseFields,
        tableFields,
      }) => (
        <FlexFlow key={sectionId} column splitSpace="1em" padding="0 0 2em">
          <FlexFlow column grow>
            {title && title.length > 0 && (
              <ModalTitle>
                {title}
              </ModalTitle>
            )}
            {description && description.length > 0 && (
              <ModalText>
                {description}
              </ModalText>
            )}
          </FlexFlow>

          {tableFields && tableFields.length > 0 && (
            <FlexFlow column>
              {tableFields.map(({
                id: tableFieldId,
                name,
                label,
                required,
                type,
                options,
                value: defaultValue,
              }) => (
                <TableRow key={tableFieldId}>
                  <CellHeader>
                    {required && <Required />}
                    {label}
                  </CellHeader>
                  <CellBody center>
                    {displayField(
                      tableFieldId,
                      type,
                      name,
                      required,
                      options,
                      sectionId,
                      defaultValue,
                    )}
                  </CellBody>
                </TableRow>
              ))}
            </FlexFlow>
          )}

          {baseFields.map(({
            id: baseFieldId,
            type,
            name,
            label,
            required,
            options,
            value: defaultValue,
          }) => (
            <FlexFlow column grow key={baseFieldId}>
              {label && type !== 'filepicker' && (
                <FormLabel id={name}>
                  {required && <Required />}
                  {label}
                </FormLabel>
              )}

              {displayField(
                baseFieldId,
                type,
                name,
                required,
                options,
                sectionId,
                defaultValue,
              )}

              {type === 'filepicker' && (
                <ModalText>
                  {label}
                </ModalText>
              )}
            </FlexFlow>
          ))}
        </FlexFlow>
      ))}

      {eventData && (
        <EventData {...eventData} />
      )}
    </ModalWrapper>
  );
}

ApproveModalForm.defaultProps = {
  eventData: null,
  approvalForms: [],
};

ApproveModalForm.propTypes = {
  approvers: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  filepickerOptions: PropTypes.shape().isRequired,
  eventData: PropTypes.shape(),
  approvalForms: PropTypes.arrayOf(PropTypes.shape()),
};

export default ApproveModalForm;
