/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useState,
  useContext,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { grey } from '@mui/material/colors';
import PriceText from '../../../Common/PriceText/PriceText';
import Divider from '../../../Common/Divider/Divider';
import { FlexFlow, FormLabel, FormValueNumber } from '../../../Common/helpers/DisplayHelpers';
import NumberField from '../../../Common/Form/NumberField';
import EditableCard from './EditableCard';
import { EbidsSidebarContext } from '../context';
import { i18n, i18nARAttr } from '../../../../src/custom/Internationalization';

function FoodBeverageCard({
  stage,
  allowEdit,
  handleSubmitCard,
}) {
  const sidebarDataContext = useContext(EbidsSidebarContext);
  const { formData: { bid_contents: bidContents } } = sidebarDataContext;

  const getFieldsByStage = () => {
    const item = bidContents && bidContents.filter((i) => i.stage === stage);
    return (item && item.length > 0) ? item[0] : {};
  };

  const stageFields = getFieldsByStage(stage);
  const stageFieldsStr = JSON.stringify(stageFields);

  const [bidFields, updateBidFields] = useState({});

  useEffect(() => {
    updateBidFields(stageFields);
  }, []);

  useEffect(() => {
    updateBidFields({ ...stageFields });
  }, [stageFieldsStr]);

  const handleUpdateFieldByName = (name, value) => {
    const newData = {};
    newData[name] = value;
    updateBidFields(() => ({ ...bidFields, ...newData }));
  };

  const handleCancelChanges = () => {
    updateBidFields({ ...stageFields });
  };

  const {
    event_space_cost_cents: eventSpaceCostCents,
    food_and_beverage_service_charge: foodBeverageServiceCharge,
    food_and_beverage_tax: foodBeverageTax,
    food_and_beverage_per_person_cost_cents: foodBeveragePerPersonCostCents,
    food_and_beverage_cost_cents: foodBeverageCostCents,
    audio_visual_cost_cents: audioVisualCostCents,
    audio_visual_service_tax: audioVisualServiceTax,
    audio_visual_service_charge: audioVisualServiceCharge,
  } = bidFields;

  const handleSaveCard = () => {
    handleSubmitCard({
      event_space_cost_cents: eventSpaceCostCents,
      food_and_beverage_service_charge: foodBeverageServiceCharge,
      food_and_beverage_tax: foodBeverageTax,
      food_and_beverage_per_person_cost_cents: foodBeveragePerPersonCostCents,
      food_and_beverage_cost_cents: foodBeverageCostCents,
      audio_visual_cost_cents: audioVisualCostCents,
      audio_visual_service_tax: audioVisualServiceTax,
      audio_visual_service_charge: audioVisualServiceCharge,
    });
  };

  const editContent = (
    <FlexFlow column nowrap>

      <FlexFlow row nowrap splitSpace="1em" margin="0 0 1em">
        <NumberField
          nomargin
          priceCent
          id="event_space_cost_cents"
          label={i18nARAttr('bid_content.event_space_cost_cents')}
          defaultNum={eventSpaceCostCents}
          onChanged={(value) => handleUpdateFieldByName('event_space_cost_cents', value)}
        />
      </FlexFlow>

      <Divider label="Food &amp; Beverage" />

      <FlexFlow row nowrap splitSpace="1em" margin="0 0 1em">
        <NumberField
          nomargin
          id="service"
          label={i18nARAttr('bid_content.food_and_beverage_service_charge')}
          defaultNum={foodBeverageServiceCharge}
          min={0}
          max={100}
          onChanged={(value) => handleUpdateFieldByName('food_and_beverage_service_charge', value)}
        />
        <NumberField
          nomargin
          id="tax"
          label={i18nARAttr('bid_content.food_and_beverage_tax')}
          defaultNum={foodBeverageTax}
          min={0}
          max={100}
          onChanged={(value) => handleUpdateFieldByName('food_and_beverage_tax', value)}
        />
      </FlexFlow>

      <FlexFlow row nowrap splitSpace="1em">
        <NumberField
          nomargin
          priceCent
          id="food_and_beverage_per_person_cost_cents"
          label={i18nARAttr('bid_content.food_and_beverage_per_person_cost')}
          defaultNum={foodBeveragePerPersonCostCents}
          onChanged={(value) => handleUpdateFieldByName('food_and_beverage_per_person_cost_cents', value)}
        />
        <NumberField
          nomargin
          priceCent
          id="total"
          label={i18nARAttr('bid_content.food_and_beverage_cost_cents')}
          defaultNum={foodBeverageCostCents}
          onChanged={(value) => handleUpdateFieldByName('food_and_beverage_cost_cents', value)}
        />
      </FlexFlow>

      <Divider label="Audio / Visual" />

      <FlexFlow row nowrap splitSpace="1em" margin="0 0 1em">
        <NumberField
          nomargin
          priceCent
          id="audio_visual_cost_cents"
          label={i18nARAttr('bid_content.audio_visual_cost_cents')}
          defaultNum={audioVisualCostCents}
          onChanged={(value) => handleUpdateFieldByName('audio_visual_cost_cents', value)}
        />
        <NumberField
          nomargin
          id="audio_visual_service_tax"
          label={i18nARAttr('bid_content.audio_visual_service_tax')}
          min={0}
          max={100}
          defaultNum={audioVisualServiceTax}
          onChanged={(value) => handleUpdateFieldByName('audio_visual_service_tax', value)}
        />
        <NumberField
          nomargin
          id="audio_visual_service_charge"
          label={i18nARAttr('bid_content.audio_visual_service_charge')}
          min={0}
          max={100}
          defaultNum={audioVisualServiceCharge}
          onChanged={(value) => handleUpdateFieldByName('audio_visual_service_charge', value)}
        />
      </FlexFlow>
    </FlexFlow>
  );

  const viewContent = (
    <FlexFlow column nowrap>
      <FlexFlow>
        <FlexFlow column>
          <FormLabel>
            {i18nARAttr('bid_content.event_space_cost_cents')}
          </FormLabel>
          <FormValueNumber>
            <PriceText
              sum={eventSpaceCostCents}
              currency={bidFields.event_space_cost_currency}
            />
          </FormValueNumber>
        </FlexFlow>
      </FlexFlow>

      <Divider label={i18n.t('ebid_sidebar.headings.food_beverage')} />

      <FlexFlow margin="0 0 1em" nowrap>
        <FlexFlow column margin="0 2em 0 0">
          <FormLabel>
            {i18nARAttr('bid_content.food_and_beverage_per_person_cost')}
          </FormLabel>
          <FormValueNumber>
            <PriceText
              sum={foodBeveragePerPersonCostCents}
              currency={bidFields.food_and_beverage_per_person_cost_currency}
            />
          </FormValueNumber>
        </FlexFlow>

        <FlexFlow column margin="0 2em 0 0">
          <FormLabel>
            {i18nARAttr('bid_content.audio_visual_service_charge')}
          </FormLabel>
          <FormValueNumber>
            {foodBeverageServiceCharge || 0}
            %
          </FormValueNumber>
        </FlexFlow>

        <FlexFlow column margin="0 2em 0 0">
          <FormLabel>
            {i18nARAttr('bid_content.food_and_beverage_tax')}
          </FormLabel>
          <FormValueNumber>
            {foodBeverageTax || 0}
            %
          </FormValueNumber>
        </FlexFlow>
      </FlexFlow>

      <FlexFlow column margin="0 2em 0 0">
        <FormLabel>
          {i18nARAttr('bid_content.food_and_beverage_cost_cents')}
        </FormLabel>
        <FormValueNumber>
          <PriceText
            sum={foodBeverageCostCents}
            currency={bidFields.food_and_beverage_cost_currency}
          />
        </FormValueNumber>
      </FlexFlow>

      <Divider label={i18n.t('ebid_sidebar.headings.audio_visual')} />

      <FlexFlow margin="0 0 1em">
        <FlexFlow column margin="0 2em 0 0">
          <FormLabel>
            {i18nARAttr('bid_content.audio_visual_cost_cents')}
          </FormLabel>
          <FormValueNumber>
            <PriceText
              sum={audioVisualCostCents}
              currency={bidFields.audio_visual_cost_currency}
            />
          </FormValueNumber>
        </FlexFlow>
        <FlexFlow column margin="0 2em 0 0">
          <FormLabel>
            {i18nARAttr('bid_content.audio_visual_service_charge')}
          </FormLabel>
          <FormValueNumber>
            {audioVisualServiceCharge || 0}
            %
          </FormValueNumber>
        </FlexFlow>

        <FlexFlow column margin="0 2em 0 0">
          <FormLabel>
            {i18nARAttr('bid_content.audio_visual_service_tax')}
          </FormLabel>
          <FormValueNumber>
            {audioVisualServiceTax || 0}
            %
          </FormValueNumber>
        </FlexFlow>
      </FlexFlow>
    </FlexFlow>
  );

  return (
    <EditableCard
      titleText={i18n.t('ebid_sidebar.headings.event_space_cost')}
      bgColor="#079992"
      textColor={grey[50]}
      editContent={editContent}
      viewContent={viewContent}
      allowEdit={allowEdit}
      onUpdate={handleSaveCard}
      onCancel={handleCancelChanges}
    />
  );
}

FoodBeverageCard.defaultProps = {
  allowEdit: true,
};

FoodBeverageCard.propTypes = {
  stage: PropTypes.string.isRequired,
  allowEdit: PropTypes.bool,
  handleSubmitCard: PropTypes.func.isRequired,
};

export default FoodBeverageCard;
