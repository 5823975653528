import styled, { css } from 'styled-components';
import colors from '../../../../Common/constants/colors';

export const CardToggle = styled.a`
  color: inherit;
  cursor: pointer;
  font-size: 0.8em;
  text-transform: uppercase;
  margin: ${(props) => props.margin || '0'};
  opacity: 0.9;
  transition: opacity 0.3s ease;

  &:hover, &:focus {
    color: inherit;
    opacity: 1;
  }
`;

export const CardWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  flex: 1 0 auto;

  border: 1px solid ${(props) => props.bgColor || colors.grey200};
  border-bottom-width: 3px;
  border-radius: 3px;
  margin: 0 0 1rem;
  padding: 0;
  transition: all 0.3s ease;

  background-color: ${(props) => props.bgColor || colors.white};
  color: ${(props) => props.textColor || colors.grey800};

  ${(props) => props.isEditable && css`
    background-color: ${colors.white};
    color: ${colors.grey800};
    border-color: ${colors.grey200};
  `}

  .editable-card-enter {
    opacity: 0;
    transform: scale(0.95);
  }
  .editable-card-enter-active {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 300ms, transform 300ms;
  }
  .editable-card-exit {
    opacity: 1;
  }
  .editable-card-exit-active {
    opacity: 0;
    transform: scale(0.95);
    transition: opacity 300ms, transform 300ms;
  }
`;

export const CardTitleWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  flex: 1 0 auto;
  align-items: center;

  border-bottom: 1px solid ${colors.grey200};
  padding: 0.5em 1em;

  @media only screen and (max-width: 769px) {
    flex-flow: column nowrap;
    align-items: flex-start;
  }
`;

export const CardTitle = styled.p`
  display: flex;
  align-items: center;

  font-size: 1.1em;
  font-weight: 700;
  line-height: 1.4;
  margin: 0 !important;

  > i, > svg {
    margin-right: 0.5em;
  }
`;

export const CardTitleContent = styled.div`
  padding: 0;
  margin-left: auto;
`;

export const CardContent = styled.div`
  padding: 0;

  ${(props) => props.isPaddedContent && css`
    padding: 1em;
  `}
`;
