import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { StylesProvider, createGenerateClassName } from '@mui/styles';
import {
  CardTitle,
  CardToggle,
  CardWrapper,
  CardTitleContent,
  CardTitleWrapper,
  CardContent,
} from './styles';
import { i18n } from '../../../../../src/custom/Internationalization';

const generateClassName = createGenerateClassName({
  productionPrefix: 'grc-dialogs',
});

function EditableCard({
  bgColor,
  textColor,
  titleIcon,
  titleText,
  titleContent,
  isPaddedContent,
  viewContent,
  editContent,
  onUpdate,
  onCancel,
  allowEdit,
}) {
  const [isEditable, setEditableState] = useState(false);
  const [showContent, toggleContent] = useState(true);
  const [showBtn, toggleShowBtn] = useState(true);

  const updateState = (value) => {
    if (!value) {
      onUpdate();
    } else {
      toggleContent(true);
    }

    setEditableState(value);
  };

  const handleCancel = () => {
    setEditableState(false);
    onCancel();
  };

  return (
    <StylesProvider generateClassName={generateClassName}>
      <CardWrapper
        bgColor={bgColor}
        textColor={textColor}
        isEditable={isEditable}
      >
        <CardTitleWrapper>
          <CardToggle
            margin="0 0.5rem 0 0"
            onClick={() => toggleContent(!showContent)}
          >
            {showBtn && (
              <ExpandMoreIcon />
            )}

            {!showBtn && (
              <ExpandLessIcon />
            )}
          </CardToggle>

          <CardTitle>
            {titleIcon}
            {titleText}
          </CardTitle>
          <CardTitleContent>
            {titleContent}

            {allowEdit && (
              <CardToggle margin="0 0 0 1em" onClick={() => updateState(!isEditable)}>
                {isEditable ? i18n.t('actions.save') : i18n.t('actions.edit')}
              </CardToggle>
            )}

            {isEditable && (
              <CardToggle margin="0 0 0 1em" onClick={() => handleCancel()}>
                {i18n.t('actions.cancel')}
              </CardToggle>
            )}
          </CardTitleContent>
        </CardTitleWrapper>

        <CSSTransition
          in={showContent}
          timeout={300}
          classNames="editable-card"
          unmountOnExit
          onEnter={() => toggleShowBtn(false)}
          onExited={() => toggleShowBtn(true)}
        >
          <CardContent isPaddedContent={isPaddedContent}>
            {isEditable ? editContent : viewContent}
          </CardContent>
        </CSSTransition>
      </CardWrapper>
    </StylesProvider>
  );
}

EditableCard.defaultProps = {
  titleIcon: '',
  titleText: '',
  titleContent: <div />,
  isPaddedContent: true,
  bgColor: '#ffffff',
  textColor: '#000000',
  allowEdit: true,
};

EditableCard.propTypes = {
  allowEdit: PropTypes.bool,
  bgColor: PropTypes.string,
  textColor: PropTypes.string,
  titleIcon: PropTypes.node,
  titleText: PropTypes.string,
  titleContent: PropTypes.node,
  isPaddedContent: PropTypes.bool,
  onUpdate: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  viewContent: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  editContent: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default EditableCard;
