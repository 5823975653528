import React from 'react';
import PropTypes from 'prop-types';
import {
  DialogSection,
  DialogSectionTitle,
} from '../styles';

import { FlexFlow } from '../../../../Common/helpers/DisplayHelpers';
import { i18n } from '../../../../../src/custom/Internationalization';

function GuestRooms({
  meetingRoomInfo: {
    totalGuestRooms,
    totalSingleBed,
    totalDoubleBed,
  },
}) {
  return (
    <DialogSection>
      <DialogSectionTitle dark>
        {i18n.t('hotel_card.headings.guest_rooms')}
      </DialogSectionTitle>

      <DialogSection padded>
        <FlexFlow splitSpace="1em">
          <div className="tw-mr-5">
            <p className="meeting_services__stat_text">
              {i18n.t('hotel_card.labels.total_guest_rooms')}
            </p>
            <p className="meeting_services__stat_value">
              {totalGuestRooms}
            </p>
          </div>
          <div className="tw-mr-5">
            <p className="meeting_services__stat_text">
              {i18n.t('hotel_card.labels.singles')}
            </p>
            <p className="meeting_services__stat_value">
              {totalSingleBed}
            </p>
          </div>
          <div className="tw-mr-5">
            <p className="meeting_services__stat_text">
              {i18n.t('hotel_card.labels.doubles')}
            </p>
            <p className="meeting_services__stat_value">
              {totalDoubleBed}
            </p>
          </div>
        </FlexFlow>
      </DialogSection>
    </DialogSection>
  );
}

GuestRooms.propTypes = {
  meetingRoomInfo: PropTypes.shape().isRequired,
};

export default GuestRooms;
