import React, { useState, useContext, Fragment } from 'react';
import PropTypes from 'prop-types';
import SimpleTabs from '../../../Common/StyledTabs/SimpleTabs';
import { DashboardContent } from '../../../Common/helpers/DisplayHelpers';

import SettingsMainForm from './SettingsMainForm';
import { EbidsSidebarContext } from '../context';

function EBidsCostsFormContent({ linkURL }) {
  const sidebarDataContext = useContext(EbidsSidebarContext);
  const { stages } = sidebarDataContext;

  const [tab, setTabValue] = useState(0);

  return (
    <>
      <DashboardContent bgColor="white">
        <SimpleTabs
          value={tab}
          onChange={(e, v) => setTabValue(v)}
          variant="fullWidth"
          items={stages.map(({ label }) => ({ label }))}
        />
      </DashboardContent>
      <DashboardContent padded>
        {stages.map(({ value }, index) => (
          <Fragment key={value}>
            {tab === index && (
              <SettingsMainForm
                stage={value}
                showCopyBtn={index > 0}
                allowEdit={index > 0}
                linkURL={linkURL}
              />
            )}
          </Fragment>
        ))}
      </DashboardContent>
    </>
  );
}

EBidsCostsFormContent.propTypes = {
  linkURL: PropTypes.string.isRequired,
};

export default EBidsCostsFormContent;
