import React, { useState } from 'react';
import PropTypes from 'prop-types';
import MuiMenu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

import { FlexFlow, ColorButton } from '../../../Common/helpers/DisplayHelpers';
import { i18n } from '../../../../src/custom/Internationalization';

import ChangeDatesForm from './ChangeDatesForm';
import ChangeRequirementsForm from './ChangeRequirementsForm';
import RequestContractForm from './RequestContractForm';
import FollowUpForm from './FollowUpForm';
import SendMessageForm from './SendMessageForm';

function MenuActions({
  bidSent,
  event,
  contactEmails,
  emailTemplates: {
    changeDatesTemplate,
    changeRequirementsTemplate,
    requestContractTemplate,
    hotelReminderTemplate,
    followUpTemplate,
  },
  onDatesChange,
  onChangeRequirements,
  onRequestContract,
  onHotelReminder,
  onFollowUp,
  onSendMessage,
  filepickerOptions,
}) {
  // Menus
  const [menuAnchorElement, setMenuAnchorElement] = useState(null);

  // Forms
  const [isChangeDatesFormOpened, setChangeDatesFormOpened] = useState(false);
  const [isChangeRequirementsOpened, setChangeRequirementsOpened] = useState(false);
  const [isRequestContractOpened, setRequestContractOpened] = useState(false);
  const [isFollowUpOpened, setFollowUpOpened] = useState(false);
  const [isSendMessageOpened, setSendMessageOpened] = useState(false);

  const closeAllMenus = () => {
    setMenuAnchorElement(null);
  };

  const handleActionsMenuOpen = (e) => {
    setMenuAnchorElement(e.currentTarget);
  };

  const handleActionsMenuClose = () => {
    setMenuAnchorElement(null);
  };

  const handleReminderClick = () => {
    handleActionsMenuClose();
    onHotelReminder(hotelReminderTemplate);
  };

  const handleNewMessageClick = () => {
    handleActionsMenuClose();
    setSendMessageOpened(true);
  };

  const handleChangeDatesClick = () => {
    handleActionsMenuClose();
    setChangeDatesFormOpened(true);
  };

  const handleChangeRequirementsClick = () => {
    handleActionsMenuClose();
    setChangeRequirementsOpened(true);
  };

  const handleRequestContractClick = () => {
    handleActionsMenuClose();
    setRequestContractOpened(true);
  };

  const handleFollowUpClick = () => {
    handleActionsMenuClose();
    setFollowUpOpened(true);
  };

  return (
    <>
      <ColorButton
        size="small"
        variant="outlined"
        onClick={(e) => handleActionsMenuOpen(e)}
      >
        <FlexFlow
          splitSpace="0.2em"
          nowrap
          padding="0 0 0 0.5em"
          align="center"
        >
          <span>{i18n.t('ebids.actions.communicate')}</span>
          <ArrowRightIcon />
        </FlexFlow>
      </ColorButton>

      <MuiMenu
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        open={Boolean(menuAnchorElement)}
        anchorEl={menuAnchorElement}
        onClose={handleActionsMenuClose}
      >
        {bidSent && (
          <MenuItem onClick={handleChangeDatesClick}>
            {i18n.t('ebids.actions.change_dates')}
          </MenuItem>
        )}
        {bidSent && (
          <MenuItem onClick={handleChangeRequirementsClick}>
            {i18n.t('ebids.actions.change_requirements')}
          </MenuItem>
        )}
        {bidSent && (
          <MenuItem onClick={handleRequestContractClick}>
            {i18n.t('ebids.actions.request_contract')}
          </MenuItem>
        )}
        {bidSent && (
          <MenuItem onClick={handleFollowUpClick}>
            {i18n.t('ebids.actions.follow_up')}
          </MenuItem>
        )}
        {bidSent && (
          <MenuItem onClick={handleReminderClick}>
            {i18n.t('ebids.actions.reminder')}
          </MenuItem>
        )}
        <MenuItem onClick={handleNewMessageClick}>
          {i18n.t('ebids.actions.new_message')}
        </MenuItem>
      </MuiMenu>

      <ChangeDatesForm
        openModal={isChangeDatesFormOpened}
        onClose={() => {
          setChangeDatesFormOpened(false);
          closeAllMenus();
        }}
        eventData={{ ...event, template: changeDatesTemplate }}
        onDatesChange={(data) => {
          onDatesChange(data);
          closeAllMenus();
          setChangeDatesFormOpened(false);
        }}
      />

      <ChangeRequirementsForm
        openModal={isChangeRequirementsOpened}
        eventData={{ ...event, template: changeRequirementsTemplate }}
        onClose={() => {
          setChangeRequirementsOpened(false);
          closeAllMenus();
        }}
        onChangeRequirements={(data) => {
          onChangeRequirements(data);
          closeAllMenus();
          setChangeRequirementsOpened(false);
        }}
      />

      <RequestContractForm
        openModal={isRequestContractOpened}
        onClose={() => {
          setRequestContractOpened(false);
          closeAllMenus();
        }}
        eventData={{ ...event, template: requestContractTemplate }}
        onRequestContract={(data) => {
          onRequestContract(data);
          closeAllMenus();
          setRequestContractOpened(false);
        }}
      />

      <FollowUpForm
        openModal={isFollowUpOpened}
        onClose={() => {
          setFollowUpOpened(false);
          closeAllMenus();
        }}
        eventData={{ ...event, template: followUpTemplate }}
        onFollowUp={(data) => {
          onFollowUp(data);
          closeAllMenus();
          setFollowUpOpened(false);
        }}
      />

      <SendMessageForm
        openModal={isSendMessageOpened}
        onClose={() => {
          setSendMessageOpened(false);
          closeAllMenus();
        }}
        onSendMessage={(data) => {
          onSendMessage(data);
          closeAllMenus();
          setSendMessageOpened(false);
        }}
        recipients={contactEmails.map((email) => ({ label: email, value: email }))}
        filepickerOptions={filepickerOptions}
      />

    </>
  );
}

MenuActions.defaultProps = {
  contactEmails: [],
  bidSent: false,
};

MenuActions.propTypes = {
  bidSent: PropTypes.bool,
  event: PropTypes.shape().isRequired,
  contactEmails: PropTypes.arrayOf(PropTypes.string),
  emailTemplates: PropTypes.shape().isRequired,
  onDatesChange: PropTypes.func.isRequired,
  onChangeRequirements: PropTypes.func.isRequired,
  onRequestContract: PropTypes.func.isRequired,
  onHotelReminder: PropTypes.func.isRequired,
  onFollowUp: PropTypes.func.isRequired,
  onSendMessage: PropTypes.func.isRequired,
  filepickerOptions: PropTypes.shape().isRequired,
};

export default MenuActions;
