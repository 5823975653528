import styled, { css } from 'styled-components';
import { grey, red, blue } from '@mui/material/colors';
import InputBase from '@mui/material/InputBase';
import Chip from '@mui/material/Chip';
import { styled as MuiStyled } from '@mui/material/styles';
import { ColorButton } from '../Common/helpers/DisplayHelpers';

export const ModalWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  padding-bottom: 2em;
  font-size: 16px;
`;

export const ModalActions = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-end;
`;

export const ModalText = styled.p`
  margin: 0.5em 0 1em;
  color: ${grey[800]};
  font-size: 0.85em;

  ${(props) => props.small && css`
    font-size: 0.7em;
    color: ${grey[600]};
    margin: 0.5em 0;
  `}
`;

export const ModalTitle = styled(ModalText)`
  font-size: 1em;
  font-weight: 400;
  margin: 0;
  text-transform: uppercase;
`;

export const TableRow = styled.div`
  display: flex;
  flex-flow: row nowrap;
  border: 1px solid ${grey[200]};
  border-bottom: none;

  &:last-child {
    border-bottom: 1px solid ${grey[200]};
    margin-bottom: 1em;
  }
`;

export const GridTemplate = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;

  ${(props) => props.space && css`
    grid-gap: ${props.space};
    gap: ${props.space};
  `}

  ${(props) => props.table && css`
    grid-template-columns: 2fr 1fr;

    /* IE 11 styles */
    -ms-grid-columns: 2fr 1fr;
    /* /IE 11 styles */
  `}

  &.bordered {
    border: 1px solid ${grey[200]};
    border-bottom: none;

    &:last-child {
      border-bottom: 1px solid ${grey[200]};
      margin-bottom: 1em;
    }
  }
`;

export const CellBody = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  padding: 0.35em 2em;
  flex: 1 1 35%;

  ${(props) => props.center && css`
    justify-content: center;
  `}
`;

export const CellHeader = styled(CellBody)`
  background-color: ${grey[50]};
  border-right: 1px solid ${grey[200]};
  padding: 1em;
  text-transform: capitalize;
  flex: 1 1 65%;
`;

export const FormLabel = styled.div`
  font-size: 0.8em;
  line-height: 1.4;
  margin: 0.5em 0;
  min-width: 5em;
  text-transform: capitalize;
`;

export const StyledBtn = styled(ColorButton)`
  text-transform: capitalize !important;
`;

export const Required = styled.span`
  color: ${red[600]};
  margin-right: 0.25em;
  margin-left: -0.5em;

  &:before {
    content: '*';
  }
`;

export const StyledChip = MuiStyled(Chip)({
  backgroundColor: blue[300],
  color: grey[900],
});

export const StyledSelectInput = MuiStyled(InputBase)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  border: `1px solid ${grey[200]}`,
  padding: '0.5em',
  minHeight: '30px',
  '&:focus': {
    backgroundColor: theme.palette.background.paper,
  },
  'label + &': {
    marginTop: '16px',
  },
}));
