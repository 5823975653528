import styled, { css } from 'styled-components';
import Button from '@mui/material/Button';
import { styled as MuiStyled } from '@mui/material/styles';
import { grey, red, green } from '@mui/material/colors';

export const RoomFormWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  flex: 1 0 auto;
`;

export const RoomFormContent = styled.div`
  padding: 1em;
  background-color: white;
`;

export const PageContent = styled.div`
  display: flex;
  flex-flow: column nowrap;
  flex: 1 0 auto;
  padding: 1em 0;
`;

export const RoomCardWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  flex: 0 0 auto;
  background-color: white;
  border: 1px solid ${grey[200]};
  border-radius: 3px;
  border-bottom-width: 3px;
  color: ${grey[800]};
  margin: 0 0 1em 0;
  padding: 0;
  position: relative;

  @media (max-width: 992px) {
    flex-flow: column nowrap;
  }

  ${(props) => props.isSelected && css`
    order: -1;
  `}
`;

export const RoomCardLogo = styled.div`
  display: flex;
  flex: 0 0 auto;
  background-color: ${grey[100]};
  border-right: 1px solid ${grey[200]};
  min-height: 150px;
  min-width: 180px;
  @media (max-width: 992px) {
    flex: 1 0 auto;
  }
  ${(props) => props.bgImage && css`
    background-image: url("${props.bgImage}");
    background-size: contain;
    background-position: top center;
    background-repeat: no-repeat;
  `}
`;

export const RoomCardContent = styled.div`
  display: flex;
  flex-flow: row nowrap;
  flex: 1 1 auto;
  @media (max-width: 768px) {
    flex-flow: column nowrap;
    flex: 1 0 auto;
  }
`;

export const RoomCardInfo = styled.div`
  display: flex;
  flex-flow: column nowrap;
  flex: 1 1 auto;
  justify-content: center;
  padding: 1em;
`;

export const RoomCardText = styled.p`
  color: ${grey[800]};
  font-size: 0.8em;
  line-height: 1.4;
  margin: 0 0 0.5em;
`;

export const Title = styled(RoomCardText)`
  font-size: 1.2em;
  font-weight: 600;
`;

export const BadgeList = styled.div`
  display: flex;
  flex-flow: row wrap;
`;

export const Badge = styled.span`
  display: inline;
  border: 1px solid ${(props) => props.textColor || grey[800]};
  border-radius: 0.3em;
  color: ${(props) => props.textColor || grey[800]};
  font-size: 0.8em;
  font-weight: 600;
  margin: 0 0.4em 1em 0;
  padding: 0.4em;
`;

export const SubTitle = styled(RoomCardText)`
  font-size: 1.1em;
`;

export const Description = styled(RoomCardInfo)`
  font-size: 1.1em;
`;

export const NotificationText = styled(RoomCardText)`
  color: ${red[800]};
  font-weight: 600;
`;

export const RoomCardPriceBlock = styled.div`
  display: flex;
  flex-flow: column nowrap;
  flex: 0 0 auto;
  align-items: center;
  justify-content: center;
  border-left: 1px solid ${grey[200]};
  min-height: 100px;
  min-width: 200px;
  padding: 1em;
  position: relative;
  @media (max-width: 768px) {
    flex: 1 0 auto;
    border-left: none;
    border-top: 1px solid ${grey[200]};
  }
`;

export const DisabledArea = styled.div`
  background: ${grey[50]};
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0.7;
`;

export const SelectedArea = styled.div`
  display: flex;
  flex-flow: column nowrap;
  flex: 0 0 auto;
  align-items: center;
  justify-content: center;
  color: ${grey[50]};
  background: ${green[300]};
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  ${Title} {
    color: ${grey[50]};
  }

  svg, i {
    font-size: 4em;
  }

  a {
    color: currentColor;
    font-weight: 400;
    text-decoration: none;
    border-bottom: 1px dashed ${grey[50]};
  }
`;

export const PriceTitle = styled(RoomCardText)`
  font-size: 1.6em;
  font-weight: 800;
  margin: 0;
`;

export const RoomCardPrice = styled(RoomCardText)`
  color: ${grey[400]};
  margin-bottom: 1em;
`;

export const StyledButton = styled(Button)`
  min-width: 120px !important;
`;

export const StartLine = styled.div`
  display: flex;
  flex: 1 1 auto;
  background-color: #eeeeee;
`;

export const EndLine = styled(StartLine)``;

export const Text = styled.p`
  display: flex;
  flex: 0 0 auto;
  margin: 0;

  color: inherit;
`;

export const DivideWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: stretch;
  flex: 1 1 auto;

  ${(props) => props.type === 'horizontal' && css`
    flex-flow: row nowrap;
    padding: 1em 0;

    ${StartLine} {
      margin: ${props.hasLabel ? '0 0.5em 0 0' : '0'};
    }

    ${EndLine} {
      margin: ${props.hasLabel ? '0 0 0 0.5em' : '0'};
    }

    ${StartLine}, ${EndLine} {
      height: 1px;
      width: 100%;
    }
  `}

  ${(props) => props.type === 'vertical' && css`
    flex-flow: column nowrap;
    padding: 0 1em;

    ${StartLine}, ${EndLine} {
      height: 100%;
      width: 2px;
      margin: 0.5em 0;
    }
  `}
`;

export const SelectButton = MuiStyled(Button)({
  color: 'white !important',

  backgroundColor: '#2b98f0 !important',
  '&:hover': {
    backgroundColor: '#1873b9 !important',
  },
});

export const TextButton = MuiStyled(Button)({
  color: 'white !important',
});
