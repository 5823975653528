import React, { useState } from 'react';
import PropTypes from 'prop-types';
import FilePicker from '../../../Common/Form/FilePicker/FilePicker';
import TextArea from '../../../Common/Form/TextArea';
import TextField from '../../../Common/Form/TextField';
import ConfirmationDialog from '../../../Dialogs/ConfirmationDialog';
import { FlexFlow } from '../../../Common/helpers/DisplayHelpers';
import { i18n } from '../../../../src/custom/Internationalization';

function InternalSignatureForm({
  filepickerConfigs: {
    apiKey,
    policy,
    signature,
    cname,
  },
  openModal,
  eventData: { name },
  initialBody,
  initialSubject,
  onChangeStage,
  onClose,
}) {
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState(initialSubject);
  const [message, setMessage] = useState(initialBody);
  const [fileData, setFileData] = useState([]);

  const data = {
    open: openModal,
    title: i18n.t('bid_request.headings.request_internal_signature', { name }),
    maxWidth: 'md',
    cancelBtnCopy: i18n.t('actions.cancel'),
    confirmBtnCopy: i18n.t('actions.send'),
    showActionBtns: true,
  };

  const handleSubmit = () => {
    const requestData = {
      attachments: fileData,
      email,
      subject,
      message,
    };
    onChangeStage(requestData);
  };

  return (
    <ConfirmationDialog
      {...data}
      handleConfirm={handleSubmit}
      handleCancel={onClose}
    >
      <FlexFlow column splitSpace="1em">
        <TextField
          id="internal_signature_email"
          label={i18n.t('bid_request.labels.who_would_you_like_to_send_to')}
          onChange={(e) => setEmail(e.target.value)}
          value={email}
          nomargin
        />
        <TextField
          id="internal_signature_subject"
          label={i18n.t('bid_request.labels.subject')}
          onChange={(e) => setSubject(e.target.value)}
          value={subject}
          nomargin
        />

        <TextArea
          id="internal_signature_message"
          label={i18n.t('bid_request.labels.your_message')}
          onChange={(value) => setMessage(value)}
          value={message}
          nomargin
        />

        <FilePicker
          apiKey={apiKey}
          signature={signature}
          policy={policy}
          cname={cname}
          inputData={{
            id: 'mediaImageUrl',
            value: fileData,
          }}
          maxFiles={3}
          btnAddName={i18n.t('actions.attach_files')}
          onSubmit={(files) => setFileData(files)}
        />
      </FlexFlow>
    </ConfirmationDialog>
  );
}

InternalSignatureForm.propTypes = {
  filepickerConfigs: PropTypes.shape().isRequired,
  openModal: PropTypes.bool.isRequired,
  onChangeStage: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  eventData: PropTypes.shape().isRequired,
  initialBody: PropTypes.string.isRequired,
  initialSubject: PropTypes.string.isRequired,
};

export default InternalSignatureForm;
