import React, { useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import * as ls from 'local-storage';
import Grid from '@mui/material/Grid';
import $ from 'jquery';
import TextField from '../../Common/Form/TextField';
import Divider from '../../Common/Divider/Divider';
import { i18n, i18nARAttr } from '../../../src/custom/Internationalization';
import RoomCard from './RoomCard';
import {
  PageContent,
  RoomFormWrapper,
  RoomFormContent,
} from './styles';

function RoomCards({
  roomTypes,
  registrationId,
  roomRequestBaseUrl,
  selectedRoomId,
  name,
  number,
  requirements,
  showLoyaltyFields,
}) {
  const [selectedId, setselectedId] = useState(selectedRoomId);
  const [roomRequestId, setRoomRequestId] = useState(null);
  const [fields, setFields] = useState({
    loyaltyName: name,
    loyaltyNumber: number,
    additionalRequirements: requirements,
  });

  const isSelected = Boolean(selectedId);

  const token = document.querySelector('meta[name="csrf-token"]')
    .getAttribute('content');
  const axiosInstance = axios.create({
    roomRequestBaseUrl,
    headers: {
      'Content-Type': 'application/json',
    },
  });
  axiosInstance.defaults.headers.common['X-CSRF-Token'] = token;

  const handleFormChange = (event, fieldName) => {
    const newData = {};
    newData[fieldName] = event.target.value;
    setFields({ ...fields, ...newData });
    ls.set(fieldName, event.target.value);
  };

  const toggleSelectedCard = (newSelectedId) => {
    // TODO: Move these into props
    const checkin = $('#checkin')[0].value;
    const checkout = $('#checkout')[0].value;

    const data = {
      room_type_id: newSelectedId,
      checkin,
      checkout,
      room_count: 1,
      guest_count: 1,
      registration_id: registrationId,
    };

    if (newSelectedId === null) {
      axiosInstance.delete(`${roomRequestBaseUrl}/${roomRequestId}`, {
        data: {
          registration_id: registrationId,
        },
      })
        .then((res) => {
          ls.set(roomRequestId, null);
          setRoomRequestId(null);

          $('#room-details')
            .replaceWith(res.data.room_type_cart_partial);
          $('#next-button')
            .removeClass('green')
            .addClass('disabled');
        });
    } else {
      axiosInstance.post(`${roomRequestBaseUrl}`, data)
        .then((res) => {
          ls.set('roomRequestId', res.data.room_request_ids[0]);
          setRoomRequestId(res.data.room_request_ids[0]);

          $('#next-button')
            .removeClass('disabled')
            .addClass('green');
          $('#room-details')
            .replaceWith(res.data.room_type_cart_partial);
        });
    }

    setselectedId(newSelectedId);
  };

  const {
    loyaltyName,
    loyaltyNumber,
    additionalRequirements,
  } = fields;

  return (
    <React.Suspense fallback={i18n.t('actions.loading')}>
      <PageContent>
        {roomTypes && roomTypes.map((roomData) => (
          <RoomCard
            key={roomData.id}
            {...roomData}
            toggleSelectedCard={toggleSelectedCard}
            isDisabled={isSelected && selectedId !== roomData.id}
            isSelected={selectedId === roomData.id}
          />
        ))}

        {isSelected && (
          <>
            <Divider label={i18n.t('room_request.headers.additional_details')} />

            <RoomFormWrapper>
              <RoomFormContent>
                <Grid container spacing={3}>
                  {showLoyaltyFields && (
                    <Grid item xs={6}>
                      <TextField
                        variant="outlined"
                        id="fname"
                        label={i18nARAttr('room_request.loyalty_name')}
                        fullWidth
                        margin="none"
                        onChange={(e) => handleFormChange(e, 'loyaltyName')}
                        value={loyaltyName}
                      />
                    </Grid>
                  )}
                  {showLoyaltyFields && (
                    <Grid item xs={6}>
                      <TextField
                        variant="outlined"
                        id="lname"
                        label={i18nARAttr('room_request.loyalty_number')}
                        fullWidth
                        margin="none"
                        onChange={(e) => handleFormChange(e, 'loyaltyNumber')}
                        value={loyaltyNumber}
                      />
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      id="outlined-textarea"
                      label={i18nARAttr('room_request.additional_request')}
                      placeholder=""
                      margin="none"
                      fullWidth
                      multiline
                      onChange={(e) => handleFormChange(e, 'additionalRequirements')}
                      value={additionalRequirements}
                    />
                  </Grid>
                </Grid>
              </RoomFormContent>
            </RoomFormWrapper>
          </>
        )}
      </PageContent>
    </React.Suspense>
  );
}

RoomCards.defaultProps = {
  roomTypes: [],
  name: '',
  number: '',
  requirements: '',
  selectedRoomId: '',
  showLoyaltyFields: true,
};

RoomCards.propTypes = {
  roomTypes: PropTypes.arrayOf(
    PropTypes.shape(),
  ),
  registrationId: PropTypes.number.isRequired,
  roomRequestBaseUrl: PropTypes.string.isRequired,
  name: PropTypes.string,
  number: PropTypes.string,
  requirements: PropTypes.string,
  selectedRoomId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  showLoyaltyFields: PropTypes.bool,
};

export default RoomCards;
