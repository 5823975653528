import styled, { css } from 'styled-components';
import { grey } from '@mui/material/colors';
import colors from '../../../Common/constants/colors';

export const DialogSection = styled.div`
  display: flex;
  flex-flow: column nowrap;
  position: relative;

  background-color: ${(props) => props.bgColor || 'white'};

  ${(props) => props.padded && css`
    padding: 2rem;
  `}

  .meeting_services {
    // .meeting_services
    &__stat_text {
      color: ${grey[600]};
      font-weight: 600;
      font-size: 1em;
    }

    // .meeting_services
    &__stat_value {
      color: ${grey[600]};
      font-size: 2em;
    }

    // .meeting_services
    &__title {
      color: material-color('grey', '800');
      font-size: 3em;
      font-weight: 900;
      margin: 0;
    }

    // .meeting_services
    &__title-text {
      color: material-color('grey', '800');
      font-size: 1.4em;
    }

    // .meeting_services
    &__title-area {
      background-color: rgba(255, 255, 255, 0.8);
      padding: 1rem 3rem;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 1;
    }
  }
`;

export const MeetingIcon = styled.div`
  background-color: ${(props) => props.bgColor || 'transparent'};
  height: ${(props) => props.height || '3em'};
  opacity: 0.5;
  margin: ${(props) => props.margin || '0'};

  ${(props) => props.iconImg && css`
    background-image: url(${props.iconImg});
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  `}
`;

export const DialogContent = styled.div`
  padding: 1em 2em;
`;

export const DialogSectionTitle = styled.p`
  color: ${colors.groupizeBrand};
  font-size: 1.6em;
  margin: 0 0 0.5em;

  ${(props) => props.dark && css`
    background-color: ${grey[100]};
    border: 1px solid ${grey[300]};
    border-width: 1px 0;
    color: ${grey[800]};
    padding: 1rem 2rem;
    margin: 0;
  `}
`;

export const DialogSectionText = styled.p`
  color: ${grey[600]};
  font-size: 0.9em;
  margin: 0 0 0.5em;
`;

export const DialogShowAllLink = styled.a`
  display: block;
  color: ${grey[400]};
  cursor: pointer;
  font-size: 1.2em;
  font-weight: 300;
  line-height: 1.4;
  margin: 0.5em 0;

  &:hover,
  &:focus {
    color: inherit;
    text-decoration: none;
  }
`;

export const DialogCloseBtn = styled.div`
  color: ${grey[50]};
  cursor: pointer;

  position: absolute;
  top: 1em;
  right: 1em;

  z-index: 1;

  svg {
    fill: ${grey[50]};
    -webkit-filter: drop-shadow( 3px 3px 2px rgba(66, 66, 66, 0.7));
    filter: drop-shadow( 3px 3px 2px rgba(66, 66, 66, 0.7));
  }
`;

export const AmenityList = styled.div`
  margin-bottom: 1rem;

  ul {
    display: flex;
    flex-flow: column nowrap;
    list-style: none;
    margin: 0;
    padding: 0;

    &.show_all {
      li:nth-child(n + 6) {
        display: block;
      }
    }

    li {
      font-size: 1.2em;
      line-height: 1.4;

      &:nth-child(n + 6) {
        display: none;
      }
    }
  }
`;

export const PropertyRooms = styled.div`
  display: flex;
  flex-flow: row wrap;
  flex: 0 1 auto;

  margin: 0 -0.5rem 2rem;
  text-align: center;

  @media only screen and (max-width: 468px) {
    flex: 1 1 auto;
    flex-flow: column nowrap;
  }

  .property_rooms {
    &__title {
      color: ${grey[600]};
      font-weight: 600;
      margin-bottom: 0.5em;
    }

    &__value {
      color: ${colors.groupizeBrand};
      font-weight: 600;
      margin: 0;
    }

    &__item {
      display: flex;
      flex-flow: column nowrap;
      flex: 1 1 auto;
      justify-content: space-between;

      background-color: #ffffff;
      border: 1px solid ${colors.groupizeBrand};
      border-radius: 6px;
      padding: 1em 0.5em;
      margin: 0 0.5em;
      width: 0;

      @media only screen and (max-width: 468px) {
        flex-flow: row nowrap;
        padding: 1em;
        width: auto;
      }

      &:first-child {
        border-left-width: 1px;
      }
    }
  }
`;

export const CapacityChart = styled.div`
  display: flex;
  flex-flow: column nowrap;
`;

export const CapacityChartRow = styled.div`
  display: flex;
  flex-flow: row nowrap;
`;

export const CapacityChartCell = styled.div`
  flex: 1 0 auto;
  width: 0;
  padding: 1rem;
  border: 1px solid ${grey[200]};
  text-align: center;
  border-width: 0 1px 1px 0;

  ${(props) => props.name && css`
    background-color: ${grey[100]};
    font-weight: 600;
    text-transform: capitalize;
  `}

  ${(props) => props.top && css`
    border-top-width: 1px;
  `}

  ${(props) => props.asTitle && css`
    background-color: ${colors.groupizeBrand};
    color: ${grey[50]};
  `}

  &:first-child {
    border-width: 0 0 1px 0;
  }
`;
