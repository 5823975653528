import React from 'react';
import { PropTypes } from 'prop-types';
import Divider from '../../../../Common/Divider/Divider';

import {
  DialogSection,
  DialogSectionTitle,
  PropertyRooms,
  CapacityChart,
  CapacityChartRow,
  CapacityChartCell,
  MeetingIcon,
} from '../styles';

import { i18n } from '../../../../../src/custom/Internationalization';

function MeetingSpace({
  meetingRoomInfo: {
    roomDetails,
    meetingRoomCount,
    sleepingRoomCount,
    largestRoomSize,
    maxSeatingCapacity,
    breakoutRoomsCount,
    totalMeetingArea,
    formats,
    roomSetups,
  },
}) {
  return (
    <DialogSection padded>
      <DialogSectionTitle dark>
        {i18n.t('hotel_card.headings.room_dimensions')}
      </DialogSectionTitle>

      <PropertyRooms>
        <div className="property_rooms__item">
          <MeetingIcon
            iconImg="https://groupizereg-production.s3.amazonaws.com/images/meeting_types/dimensions-meeting_rooms.png"
          />

          <p className="property_rooms__title">{i18n.t('hotel_card.labels.num_meeting_rooms')}</p>
          <p className="property_rooms__value">
            {meetingRoomCount}
          </p>
        </div>
        <div className="property_rooms__item">
          <MeetingIcon
            iconImg="https://groupizereg-production.s3.amazonaws.com/images/meeting_types/dimensions-sleeping_rooms.png"
            height="1.8em"
            margin="0.6em 0"
          />
          <p className="property_rooms__title">{i18n.t('hotel_card.labels.num_sleeping_rooms')}</p>
          <p className="property_rooms__value">
            {sleepingRoomCount || 'n/a'}
          </p>
        </div>
        <div className="property_rooms__item">
          <MeetingIcon
            iconImg="https://groupizereg-production.s3.amazonaws.com/images/meeting_types/dimensions-largest_room_size.png"
            height="2.6em"
            margin="0.2em 0"
          />
          <p className="property_rooms__title">{i18n.t('hotel_card.labels.largest_room_size')}</p>
          <p className="property_rooms__value">
            <span>{largestRoomSize}</span>
          </p>
        </div>
        <div className="property_rooms__item">
          <MeetingIcon
            iconImg="https://groupizereg-production.s3.amazonaws.com/images/meeting_types/dimensions-seating_capacity.png"
            height="2.6em"
            margin="0.2em 0"
          />
          <p className="property_rooms__title">{i18n.t('hotel_card.labels.max_seating_capacity')}</p>
          <p className="property_rooms__value">
            <span>{maxSeatingCapacity || 'n/a'}</span>
          </p>
        </div>
        <div className="property_rooms__item">
          <MeetingIcon
            iconImg="https://groupizereg-production.s3.amazonaws.com/images/meeting_types/dimensions-breakout_rooms.png"
            height="2.4em"
            margin="0.3em 0"
          />
          <p className="property_rooms__title">{i18n.t('hotel_card.labels.num_breakout_rooms')}</p>
          <p className="property_rooms__value">
            {breakoutRoomsCount}
          </p>
        </div>
        <div className="property_rooms__item">
          <MeetingIcon
            iconImg="https://groupizereg-production.s3.amazonaws.com/images/meeting_types/dimensions-meeting_area.png"
          />
          <p className="property_rooms__title">{i18n.t('hotel_card.labels.total_meeting_area')}</p>
          <p className="property_rooms__value">
            <span>{totalMeetingArea}</span>
          </p>
        </div>
      </PropertyRooms>

      <Divider />

      <DialogSectionTitle>
        {i18n.t('hotel_card.headings.capacity_chart')}
      </DialogSectionTitle>

      <CapacityChart>
        <CapacityChartRow>
          <CapacityChartCell />
          <CapacityChartCell top />
          <CapacityChartCell top />
          <CapacityChartCell top />
          {
            roomSetups && roomSetups.map((roomSetup) => (
              <CapacityChartCell key={roomSetup} top>
                <div className="room_type_img">
                  <img
                    src={`https://groupizereg-production.s3.amazonaws.com/images/meeting_types/style-diagram-${roomSetup}.jpg`}
                    alt="meeting room"
                  />
                </div>
              </CapacityChartCell>
            ))
          }
        </CapacityChartRow>

        <CapacityChartRow>
          <CapacityChartCell asTitle />
          <CapacityChartCell asTitle>
            {i18n.t('hotel_card.labels.space')}
          </CapacityChartCell>
          <CapacityChartCell asTitle>
            {i18n.t('hotel_card.labels.ceiling_height')}
          </CapacityChartCell>
          <CapacityChartCell asTitle>
            {i18n.t('hotel_card.labels.size')}
          </CapacityChartCell>
          {formats
            && Object.keys(formats).map((formatName) => (
              <CapacityChartCell key={formatName} asTitle>
                {formatName}
              </CapacityChartCell>
            ))}
        </CapacityChartRow>

        {roomDetails
          && roomDetails.map(({
            roomName,
            meetingRoomArea,
            roomDimension,
            roomSize,
            capacities,
          }, i) => (
            <CapacityChartRow key={i}>
              <CapacityChartCell name>
                {roomName}
              </CapacityChartCell>
              <CapacityChartCell>
                {meetingRoomArea}
              </CapacityChartCell>
              <CapacityChartCell>
                {roomDimension}
              </CapacityChartCell>
              <CapacityChartCell>
                {roomSize}
              </CapacityChartCell>
              {formats
                && Object.keys(formats).map((format) => (
                  <CapacityChartCell key={format}>
                    {capacities[format]}
                  </CapacityChartCell>
                ))}
            </CapacityChartRow>
          ))}
      </CapacityChart>
    </DialogSection>
  );
}

MeetingSpace.propTypes = {
  meetingRoomInfo: PropTypes.shape().isRequired,
};

export default MeetingSpace;
