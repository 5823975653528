import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import * as yup from 'yup';
import MessageSendForm from '../../../Common/Messages/SendForm';
import ConfirmationDialog from '../../../Dialogs/ConfirmationDialog';
import { i18n } from '../../../../src/custom/Internationalization';

function SendMessageForm({
  openModal,
  onSendMessage,
  onClose,
  recipients,
  filepickerOptions,
}) {
  const [sendMessageData, setSendMessageData] = useState({
    subject: '',
    message: '',
    label: '',
    attachment: '',
    recipients: [],
  });
  const [validationError, setValidationError] = useState([]);
  const [showErrors, setShowErrors] = useState(false);

  const messageSchema = yup.object().shape({
    subject: yup.string(),
    message: yup.string(),
    label: yup.string(),
    recipients: yup.array().min(1, i18n.t('ebids.errors.one_recipient')),
  });

  const handleFormValidation = (formFields) => {
    messageSchema
      .validate(formFields, { abortEarly: false })
      .then(
        () => {
          setValidationError([]);
        },
        (response) => {
          setValidationError(response.inner);
        },
      );
  };

  const onUpdateData = (newData) => {
    setSendMessageData(newData);
    handleFormValidation(newData);
  };

  useEffect(() => {
    handleFormValidation(sendMessageData);
  }, []);

  const handleConfirm = () => {
    setShowErrors(true);
    if (validationError.length === 0) {
      onSendMessage(sendMessageData);
    }
  };

  return (
    <ConfirmationDialog
      disableEnforceFocus
      open={openModal}
      title={i18n.t('bid_request.external_message.header')}
      maxWidth="md"
      showActionBtns
      cancelBtnCopy={i18n.t('actions.cancel')}
      confirmBtnCopy={i18n.t('actions.send')}
      handleCancel={onClose}
      handleConfirm={handleConfirm}
    >
      <MessageSendForm
        allRecipients={recipients}
        messageData={sendMessageData}
        handleChangeFields={onUpdateData}
        filepickerOptions={filepickerOptions}
        formErrors={validationError}
        showErrors={showErrors}
      />
    </ConfirmationDialog>
  );
}

SendMessageForm.defaultProps = {
  recipients: [],
};

SendMessageForm.propTypes = {
  openModal: PropTypes.bool.isRequired,
  onSendMessage: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  filepickerOptions: PropTypes.shape().isRequired,
  recipients: PropTypes.arrayOf(PropTypes.shape()),
};

export default SendMessageForm;
