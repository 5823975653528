/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useState,
  useContext,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  ModalWrapper,
  ModalTitle,
  ModalText,
  TableRow as StyledTableRow,
  FormLabel,
  CellHeader,
  CellBody,
} from '../styles';
import FilePicker from '../../Common/Form/FilePicker/FilePicker';
import PriceText from '../../Common/PriceText/PriceText';
import { FlexFlow } from '../../Common/helpers/DisplayHelpers';
import { ModalContext } from '../context';
import { EventData } from './EventData';
import { i18n, i18nARAttr } from '../../../src/custom/Internationalization';

function ApproveModalFormData({
  approvalForms,
  eventData,
  filepickerOptions,
}) {
  const context = useContext(ModalContext);
  const {
    formData,
    setField,
  } = context;

  const [sections, setSections] = useState([]);

  const loadSectionsForType = (typeId) => {
    const typeData = approvalForms.find((t) => t.id === typeId);
    const newSections = typeData ? typeData.sections : [];
    setField('formTypeName', typeData ? typeData.label : '', null);
    setSections(newSections);
  };

  useEffect(() => {
    if (formData.approvalType) {
      loadSectionsForType(formData.approvalType);
    }
  }, [approvalForms]);

  const displayValue = (type, name, sectionId) => {
    const sectionFields = formData.sections.find((s) => s.id === sectionId);
    const value = sectionFields && sectionFields[name] && sectionFields[name].value;
    if (type === 'pricefield') {
      return (
        <PriceText
          sum={value}
          currency={sectionFields.budgetCurrency && sectionFields.budgetCurrency.value}
        />
      );
    }
    if (type === 'filepicker') {
      return (
        <FilePicker
          {...filepickerOptions}
          isDisabled
          label={i18n.t('labels.selected_files')}
          variant="left"
          inputData={{
            value,
          }}
        />
      );
    }
    return value || '-';
  };

  return (
    <ModalWrapper>
      <FlexFlow column splitSpace="1em" padding="0 0 2em">
        <FlexFlow column grow>
          <FormLabel id="contacts">
            {i18nARAttr('approval_form.to_emails')}
            &nbsp;
            <small>
              (
              {i18n.t('approval_form.tooltips.email')}
              )
            </small>
          </FormLabel>
          <ModalText>
            {(formData.contacts && formData.contacts.map(({ email }) => (email)).join(', ')) || '-'}
          </ModalText>
        </FlexFlow>

        <FlexFlow column grow>
          <FormLabel id="contacts">
            {i18nARAttr('approval_form.cc_emails')}
            &nbsp;
            <small>
              (
              {i18n.t('approval_form.tooltips.email')}
              )
            </small>
          </FormLabel>
          <ModalText>
            {(formData.ccEmails && formData.ccEmails.map(({ email }) => (email)).join(', ')) || '-'}
          </ModalText>
        </FlexFlow>

        <FlexFlow splitSpace="1em">
          <FlexFlow column grow>
            <FormLabel>
              {i18nARAttr('approval_form.approve_by')}
            </FormLabel>
            <ModalText>
              {moment(formData.approvalBy).format('LL')}
            </ModalText>
          </FlexFlow>
        </FlexFlow>
      </FlexFlow>

      {sections.map(({
        id: sectionId,
        title,
        description,
        baseFields,
        tableFields,
      }) => (
        <FlexFlow key={sectionId} column splitSpace="1em" padding="0 0 2em">
          <FlexFlow column grow>
            {title && title.length > 0 && (
              <ModalTitle>
                {title}
              </ModalTitle>
            )}
            {description && description.length > 0 && (
              <ModalText>
                {description}
              </ModalText>
            )}
          </FlexFlow>

          {tableFields && tableFields.length > 0 && (
            <FlexFlow column>
              {tableFields.map(({
                id: tableFieldId,
                name,
                label,
                type,
              }) => (
                <StyledTableRow key={tableFieldId}>
                  <CellHeader>
                    {label}
                  </CellHeader>
                  <CellBody center>
                    {displayValue(type, name, sectionId)}
                  </CellBody>
                </StyledTableRow>
              ))}
            </FlexFlow>
          )}

          {baseFields.map(({
            id: baseFieldId,
            name,
            label,
            type,
          }) => (
            <FlexFlow column grow key={baseFieldId}>
              <FormLabel id={name}>
                {label}
              </FormLabel>

              <ModalText>
                {displayValue(type, name, sectionId)}
              </ModalText>
            </FlexFlow>
          ))}
        </FlexFlow>
      ))}

      {eventData && (
        <EventData {...eventData} />
      )}
    </ModalWrapper>
  );
}

ApproveModalFormData.defaultProps = {
  eventData: null,
  approvalForms: [],
};

ApproveModalFormData.propTypes = {
  eventData: PropTypes.shape(),
  approvalForms: PropTypes.arrayOf(PropTypes.shape()),
  filepickerOptions: PropTypes.shape().isRequired,
};

export default ApproveModalFormData;
