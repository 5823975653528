/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { grey } from '@mui/material/colors';
import { i18n } from '../../../../../src/custom/Internationalization';

import { EbidsSidebarContext } from '../../context';

import EditableCard from '../EditableCard';
import EditSleepingRooms from './edit';
import ViewSleepingRooms from './view';

const defaultSleepingRooms = {
  single_sleeping_room_amount: 0,
  single_sleeping_room_cost_cents: 0,
  single_sleeping_room_cost_currency: 'USD',
  double_sleeping_room_amount: 0,
  double_sleeping_room_cost_cents: 0,
  double_sleeping_room_cost_currency: 'USD',
  suite_sleeping_room_amount: 0,
  suite_sleeping_room_cost_cents: 0,
  suite_sleeping_room_cost_currency: 'USD',
};

function SleepingRoomsCard({
  stage,
  roomsData,
  allowEdit,
  handleSubmitCard,
}) {
  const [bidFields, updateBidFields] = useState({});
  const [defaultRoomsData, setDefaultRoomsData] = useState(defaultSleepingRooms);
  const [roomNights, updateRooms] = useState([]);

  const sidebarDataContext = useContext(EbidsSidebarContext);
  const { formData: { bid_contents: bidContents } } = sidebarDataContext;

  const getFieldsByStage = () => {
    const item = bidContents && bidContents.filter((i) => i.stage === stage);
    return (item && item.length > 0) ? item[0] : {};
  };

  const stageFields = getFieldsByStage(stage);
  const { bid_content_days: bidContentDays, bidFieldsUpdatedAt, bidFieldsCopiedAt } = stageFields;

  const {
    start_date: startDate,
    end_date: endDate,
    sleeping_room_tax: sleepingRoomTax,
    sleeping_room_flat_tax_cents: sleepingRoomFlatTaxCents,
    sleeping_room_resort_fee_cents: sleepingRoomResortFeeCents,
  } = bidFields;

  useEffect(() => {
    updateBidFields(stageFields);
    const newRoomNights = (roomNights.length > 0)
      ? roomNights
      : bidContentDays || [];

    updateRooms(newRoomNights);
  }, [stageFields]);

  useEffect(() => {
    const newRoomNights = (roomNights.length > 0)
      ? roomNights
      : bidContentDays || [];

    updateRooms(newRoomNights);
    updateBidFields(stageFields);
  }, [bidFieldsUpdatedAt]);

  useEffect(() => {
    updateRooms(bidContentDays || []);
    updateBidFields(stageFields);
  }, [bidFieldsCopiedAt]);

  const handleSaveCard = () => {
    handleSubmitCard({
      start_date: startDate,
      end_date: endDate,
      sleeping_room_tax: sleepingRoomTax,
      sleeping_room_flat_tax_cents: sleepingRoomFlatTaxCents || 0,
      sleeping_room_resort_fee_cents: sleepingRoomResortFeeCents || 0,
      bid_content_days: roomNights,
    });
  };

  const handleCancelChanges = () => {
    updateBidFields(stageFields);
    updateRooms(bidContentDays || []);

    if (bidContentDays && bidContentDays.length > 0) {
      setDefaultRoomsData(bidContentDays[0]);
    }
  };

  const editRoomsData = {
    bidFields,
    updateBidFields,
    roomNights,
    updateRooms,
    defaultSleepingRooms,
    defaultRoomsData,
    setDefaultRoomsData,
  };

  const viewRoomsData = {
    roomsData,
    bidFields,
  };

  return (
    <EditableCard
      titleText={i18n.t('ebid_sidebar.headings.sleeping_room_cost')}
      bgColor="#3c6382"
      textColor={grey[50]}
      editContent={<EditSleepingRooms editRoomsData={editRoomsData} />}
      viewContent={<ViewSleepingRooms viewRoomsData={viewRoomsData} />}
      allowEdit={allowEdit}
      onUpdate={() => handleSaveCard()}
      onCancel={() => handleCancelChanges()}
    />
  );
}

SleepingRoomsCard.defaultProps = {
  roomsData: {},
  allowEdit: true,
};

SleepingRoomsCard.propTypes = {
  stage: PropTypes.string.isRequired,
  roomsData: PropTypes.shape(),
  handleSubmitCard: PropTypes.func.isRequired,
  allowEdit: PropTypes.bool,
};

export default SleepingRoomsCard;
