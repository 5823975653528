import React, { useState } from 'react';
import PropTypes from 'prop-types';
import TextArea from '../../../Common/Form/TextArea';
import ConfirmationDialog from '../../../Dialogs/ConfirmationDialog';
import { i18n } from '../../../../src/custom/Internationalization';

function RequestContractForm({
  openModal,
  eventData: { name, template },
  onRequestContract,
  onClose,
}) {
  const [contractRequestData, setContractRequestData] = useState({
    message: template,
  });

  const { message } = contractRequestData;

  return (
    <ConfirmationDialog
      open={openModal}
      title={i18n.t('ebids.headings.request_contract', { name })}
      maxWidth="md"
      cancelBtnCopy={i18n.t('actions.cancel')}
      confirmBtnCopy={i18n.t('actions.send')}
      showActionBtns
      handleConfirm={() => onRequestContract(contractRequestData)}
      handleCancel={onClose}
    >
      <TextArea
        id="message"
        label={i18n.t('ebids.labels.edit_message')}
        onChange={(value) => setContractRequestData({ ...contractRequestData, message: value })}
        value={message}
        nomargin
      />
    </ConfirmationDialog>
  );
}

RequestContractForm.propTypes = {
  openModal: PropTypes.bool.isRequired,
  onRequestContract: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  eventData: PropTypes.shape().isRequired,
};

export default RequestContractForm;
