import React from 'react';
import PropTypes from 'prop-types';

import {
  DialogSection,
  DialogSectionTitle,
  DialogContent,
} from '../styles';
import PhotoSlider from '../../../../Common/PhotoSlider/PhotoSlider';
import { i18n } from '../../../../../src/custom/Internationalization';

function Gallery({ propertyImages }) {
  return (
    <DialogSection>
      <DialogSectionTitle dark>
        {i18n.t('hotel_card.headings.image_gallery')}
      </DialogSectionTitle>

      <DialogContent>
        <PhotoSlider images={propertyImages} showTitles />
      </DialogContent>
    </DialogSection>
  );
}

Gallery.propTypes = {
  propertyImages: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string,
      caption: PropTypes.string,
    }),
  ).isRequired,
};

export default Gallery;
