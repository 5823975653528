/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import cc from 'currency-codes';
import InfoIcon from '@mui/icons-material/Info';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Popup from '../../../Common/Popup/Popup';
import PriceText from '../../../Common/PriceText/PriceText';
import SelectField from '../../../Common/Form/SelectField/SelectField';
import Divider from '../../../Common/Divider/Divider';
import AlertMessage from '../../../Common/AlertMessage/AlertMessage';
import SleepingRoomsCard from './SleepingRoomsCard';
import FoodBeverageCard from './FoodBeverageCard';
import OtherCard from './OtherCard';
import { EbidsSidebarContext } from '../context';
import {
  FlexFlow, FormLabel, StyledButton, FormValueNumber,
} from '../../../Common/helpers/DisplayHelpers';
import { i18n } from '../../../../src/custom/Internationalization';

const currencies = cc.codes();

function SettingsMainForm({
  stage,
  showCopyBtn,
  allowEdit,
  linkURL,
}) {
  const sidebarDataContext = useContext(EbidsSidebarContext);
  const {
    stages,
    formData,
    handleUpdateStageFields,
    handleSaveStageFields,
    handleCopyStage,
    getRoomsData,
    getCostsData,
  } = sidebarDataContext;

  const [currency, setCurrency] = useState('');
  const [cardOrder, setCardOrder] = useState(['sleepingRooms', 'eventSpace', 'otherCosts']);
  const [snackbarVariant, setSnackbarVariant] = useState('info');
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [isSnackarOpen, setSnackarOpen] = useState(false);

  const getFieldsByStage = () => {
    const item = formData.bid_contents
      && formData.bid_contents.filter((i) => i.stage === stage);
    return (item && item.length > 0) ? item[0] : {};
  };

  const stageFields = getFieldsByStage(stage);
  const { bidFieldsUpdatedAt, total_cost_currency: stageCurrency } = stageFields;

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') return;
    setSnackarOpen(false);
  };

  const handleSnackbarOpen = (message, variant) => {
    setSnackbarVariant(variant);
    setSnackbarMessage(message);
    setSnackarOpen(true);
  };

  const handleOnCopyClick = () => {
    const id = stages.findIndex((i) => i.value === stage);
    handleCopyStage(stages[id - 1].value, stage);
  };

  const handleOnUpdate = (newFields) => handleUpdateStageFields(
    stage,
    newFields,
  );

  const handleOnSave = (newFields) => handleSaveStageFields(
    stage,
    newFields,
    () => handleSnackbarOpen('Saved successfully!', 'success'),
    (error) => handleSnackbarOpen(`There is an error: ${error}`, 'error'),
  );

  useEffect(() => {
    const bidCurrency = stageFields.total_cost_currency;
    const newCurrency = (bidCurrency && bidCurrency.length > 0) ? bidCurrency : 'USD';

    if (stageFields.card_positions && stageFields.card_positions.length > 1) {
      setCardOrder(stageFields.card_positions);
    }

    setCurrency(newCurrency);
  }, []);

  useEffect(() => {
    const bidCurrency = stageCurrency;
    const newCurrency = (bidCurrency && bidCurrency.length > 0) ? bidCurrency : 'USD';

    if (stageFields.card_positions && stageFields.card_positions.length > 1) {
      setCardOrder(stageFields.card_positions);
    }

    if (newCurrency !== currency) {
      setCurrency(newCurrency);
    }
  }, [bidFieldsUpdatedAt]);

  const onCopyClick = () => {
    handleOnCopyClick();
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const changeCurrency = (newCurrency) => {
    const updatedFields = {
      ...stageFields,
      sleeping_room_cost_currency: newCurrency,
      sleeping_room_flat_tax_currency: newCurrency,
      sleeping_room_resort_fee_currency: newCurrency,
      average_daily_rate_currency: newCurrency,
      total_cost_currency: newCurrency,
      audio_visual_cost_currency: newCurrency,
      event_space_cost_currency: newCurrency,
      food_and_beverage_cost_currency: newCurrency,
      food_and_beverage_per_person_cost_currency: newCurrency,
      comp_rooms_cost_currency: newCurrency,
      concessions_currency: newCurrency,
      collected_currency: newCurrency,
      invoice_currency: newCurrency,
      other_fees_currency: newCurrency,
    };

    handleOnUpdate(updatedFields);
  };

  const onDragEnd = (result) => {
    const { source, destination } = result;

    // dropped outside the list
    if (!destination) return;

    const items = reorder(
      cardOrder,
      source.index,
      destination.index,
    );

    const updatedFields = {
      ...stageFields,
      card_positions: items,
    };

    setCardOrder(items);
    handleOnSave(updatedFields);
  };

  const roomsData = getRoomsData(stageFields);
  const totalCosts = getCostsData(stageFields, roomsData);

  const handleSaveCard = (data) => {
    const updatedFields = {
      ...stageFields,
      ...data,
    };

    handleOnSave(updatedFields);
  };

  const sleepingRooms = (
    <SleepingRoomsCard
      stage={stage}
      roomsData={roomsData}
      allowEdit={allowEdit}
      handleSubmitCard={handleSaveCard}
    />
  );

  const eventSpace = (
    <FoodBeverageCard
      stage={stage}
      allowEdit={allowEdit}
      handleSubmitCard={handleSaveCard}
    />
  );

  const otherCosts = (
    <OtherCard
      stage={stage}
      allowEdit={allowEdit}
      handleSubmitCard={handleSaveCard}
    />
  );

  const cards = { sleepingRooms, eventSpace, otherCosts };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <FlexFlow column nowrap>
        <FormLabel>
          <FlexFlow nowrap align="center" splitSpace="0.5em">
            <span>
              {i18n.t('ebid_sidebar.headings.total_cost')}
            </span>
            <Popup
              title={i18n.t('ebid_sidebar.messages.total_description')}
              placement="right"
            >
              <InfoIcon />
            </Popup>
          </FlexFlow>
        </FormLabel>
        <FlexFlow>
          <FlexFlow nowrap align="center" splitSpace="1em" shrink grow>
            <FormValueNumber>
              <PriceText sum={totalCosts.totalCost} currency={currency} />
            </FormValueNumber>

            <div className="tw-flex tw-max-w-40 tw-mr-4">
              <SelectField
                id="select"
                name="currency"
                defaultValue={null}
                disabled={!allowEdit}
                nomargin
                value={currency}
                items={currencies.map((c) => ({ value: c, label: c }))}
                onChange={(val) => changeCurrency(val)}
                className="tw-min-w-40"
              />
            </div>

            {showCopyBtn && (
              <StyledButton
                variant="contained"
                onClick={onCopyClick}
              >
                {i18n.t('ebid_sidebar.actions.copy_costs')}
              </StyledButton>
            )}

            {!allowEdit && (
              <CopyToClipboard
                text={linkURL}
                onCopy={() => handleSnackbarOpen('Link copied!', 'success')}
              >
                <StyledButton
                  variant="contained"
                  className="tw-whitespace-break-spaces tw-flex-auto"
                >
                  {i18n.t('ebid_sidebar.actions.copy_venue_offer')}
                </StyledButton>
              </CopyToClipboard>
            )}

          </FlexFlow>
        </FlexFlow>

        <Divider />

        <Droppable droppableId="cards">
          {(dropProvided, dropSnapshot) => (
            <FlexFlow
              column
              ref={dropProvided.innerRef}
              isDragging={dropSnapshot.isDraggingOver}
            >
              {cardOrder.map((i, index) => (
                <Draggable
                  key={i}
                  draggableId={`Card_${i}`}
                  index={index}
                >
                  {(dragProvided, dragSnapshot) => (
                    <FlexFlow
                      column
                      ref={dragProvided.innerRef}
                      {...dragProvided.draggableProps}
                      {...dragProvided.dragHandleProps}
                      isDragging={dragSnapshot.isDragging}
                    >
                      {cards[i]}
                    </FlexFlow>
                  )}
                </Draggable>
              ))}
              {dropProvided.placeholder}
            </FlexFlow>
          )}
        </Droppable>
      </FlexFlow>

      <AlertMessage
        open={isSnackarOpen}
        handleClose={handleSnackbarClose}
        variant={snackbarVariant}
        message={snackbarMessage}
        duration={3000}
      />
    </DragDropContext>
  );
}

SettingsMainForm.defaultProps = {
  showCopyBtn: false,
  allowEdit: true,
};

SettingsMainForm.propTypes = {
  showCopyBtn: PropTypes.bool,
  allowEdit: PropTypes.bool,
  linkURL: PropTypes.string.isRequired,
  stage: PropTypes.string.isRequired,
};

export default SettingsMainForm;
