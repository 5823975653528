import React from 'react';
import PropTypes from 'prop-types';
import Moment from 'react-moment';
import ForwardIcon from '@mui/icons-material/ArrowForwardIos';
import InfoIcon from '@mui/icons-material/Info';
import pluralize from 'pluralize';
import Popup from '../../../../Common/Popup/Popup';
import PriceText from '../../../../Common/PriceText/PriceText';
import Divider from '../../../../Common/Divider/Divider';
import {
  FlexFlow, MiniIconBtn, FormLabel, FormValueNumber,
} from '../../../../Common/helpers/DisplayHelpers';
import { i18n, i18nARAttr } from '../../../../../src/custom/Internationalization';

function ViewSleepingRooms({ viewRoomsData }) {
  const { roomsData, bidFields } = viewRoomsData;

  const {
    start_date: startDate,
    end_date: endDate,
    sleeping_room_tax: sleepingRoomTax,
    sleeping_room_flat_tax_cents: sleepingRoomFlatTaxCents,
    sleeping_room_resort_fee_cents: sleepingRoomResortFeeCents,
  } = bidFields;

  const hasDateRange = startDate && endDate;

  return (
    <FlexFlow column nowrap>
      <FlexFlow splitSpace="1em" align="center">
        {startDate && (
          <Moment format="ddd, MMM DD, YYYY" locale={i18n.locale}>
            {startDate}
          </Moment>
        )}
        {startDate
          && endDate
          && (
            <MiniIconBtn aria-label={i18n.t('ebid_sidebar.messages.date_to')} disabled color="primary" size="small">
              <ForwardIcon />
            </MiniIconBtn>
          )}
        {endDate && (
          <Moment format="ddd, MMM DD, YYYY" locale={i18n.locale}>
            {endDate}
          </Moment>
        )}
      </FlexFlow>

      {hasDateRange && (
        <FlexFlow padding="0.5em 0">
          <small>
            {`(${roomsData.numberOfNights} ${pluralize(i18n.t('ebid_sidebar.labels.night'), roomsData.numberOfNights)})`}
          </small>
        </FlexFlow>
      )}

      <FlexFlow padding="1em 0" splitSpace="1em">
        <FlexFlow column nowrap>
          <FormLabel>
            {i18nARAttr('bid_content_day.single_sleeping_room_amount')}
          </FormLabel>
          <FormValueNumber>
            {roomsData.single.num}
          </FormValueNumber>
        </FlexFlow>
        <FlexFlow column nowrap>
          <FormLabel>
            {i18nARAttr('bid_content_day.double_sleeping_room_amount')}
          </FormLabel>
          <FormValueNumber>
            {roomsData.double.num}
          </FormValueNumber>
        </FlexFlow>
        <FlexFlow column nowrap>
          <FormLabel>
            {i18nARAttr('bid_content_day.suite_sleeping_room_amount')}
          </FormLabel>
          <FormValueNumber>
            {roomsData.suite.num}
          </FormValueNumber>
        </FlexFlow>
        <FlexFlow column nowrap>
          <FormLabel>
            {i18n.t('ebid_sidebar.labels.total_room_nights')}
          </FormLabel>
          <FormValueNumber>
            {roomsData.totalRoomsNumber}
          </FormValueNumber>
        </FlexFlow>
      </FlexFlow>

      <FlexFlow padding="0 0 0.5em" splitSpace="1em">
        <FlexFlow column nowrap>
          <FormLabel>
            <FlexFlow nowrap align="center" splitSpace="0.5em">
              <span>
                {i18n.t('ebid_sidebar.labels.average_daily_rate')}
              </span>
              <Popup
                title={i18n.t('ebid_sidebar.messages.average_daily_rate_description')}
                placement="right"
              >
                <InfoIcon />
              </Popup>
            </FlexFlow>
          </FormLabel>
          <FormValueNumber>
            <PriceText
              sum={roomsData.averageDailyRate}
              currency={bidFields.average_daily_rate_currency}
            />
          </FormValueNumber>
        </FlexFlow>
      </FlexFlow>

      <Divider />

      <FlexFlow splitSpace="1em">
        <FlexFlow column nowrap>
          <FormLabel>
            {i18nARAttr('bid_content.sleeping_room_tax')}
          </FormLabel>
          <FormValueNumber>
            {sleepingRoomTax || 0}
            %
          </FormValueNumber>
        </FlexFlow>

        <FlexFlow column nowrap>
          <FormLabel>
            {i18nARAttr('bid_content.sleeping_room_flat_tax_currency')}
          </FormLabel>
          <FormValueNumber>
            <PriceText
              sum={sleepingRoomFlatTaxCents}
              currency={bidFields.sleeping_room_flat_tax_currency}
            />
          </FormValueNumber>
        </FlexFlow>

        <FlexFlow column nowrap>
          <FormLabel>
            {i18nARAttr('bid_content.sleeping_room_resort_fee')}
          </FormLabel>
          <FormValueNumber>
            <PriceText
              sum={sleepingRoomResortFeeCents}
              currency={bidFields.sleeping_room_resort_fee_currency}
            />
          </FormValueNumber>
        </FlexFlow>
      </FlexFlow>

      <Divider />

      <FlexFlow column nowrap>
        <FormLabel>
          {i18n.t('ebid_sidebar.labels.total_sleeping_room_cost')}
        </FormLabel>
        <FormValueNumber>
          <PriceText
            sum={roomsData.totalRoomCosts}
            currency={bidFields.sleeping_room_resort_fee_currency}
          />
        </FormValueNumber>
      </FlexFlow>
    </FlexFlow>
  );
}

ViewSleepingRooms.propTypes = {
  viewRoomsData: PropTypes.shape().isRequired,
};

export default ViewSleepingRooms;
