import React, { useState, useEffect, Fragment } from 'react';
import { PropTypes } from 'prop-types';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { grey } from '@mui/material/colors';
import _ from 'lodash';
import { Link, Element, Events } from 'react-scroll';

import {
  DialogSection,
  DialogCloseBtn,
} from './styles';
import {
  HotelImages,
  HotelImageCaption,
  HotelImageTitle,
  HotelImageText,
  HotelLink,
} from '../styles';
import {
  FlexFlow, ColorButton,
} from '../../../Common/helpers/DisplayHelpers';
import { i18n } from '../../../../src/custom/Internationalization';

import MeetingSpace from './sections/MeetingSpace';
import Location from './sections/Location';
import GuestRooms from './sections/GuestRooms';
import Amenities from './sections/Amenities';
import HotelDescription from './sections/HotelDescription';
import Gallery from './sections/Gallery';

function ModalContent({
  handleClose,
  event,
  property,
  property: {
    name,
    address,
    image,
  },
  meetingInfo,
  meetingInfo: {
    canEbid,
    isAdded,
  },
}) {
  const [isHotelAdded, toggleHotel] = useState(isAdded);

  useEffect(() => {
    Events.scrollEvent.register('begin');
    Events.scrollEvent.register('end');

    return () => {
      Events.scrollEvent.remove('begin');
      Events.scrollEvent.remove('end');
    };
  });

  return (
    <>
      <DialogSection>
        <DialogCloseBtn onClick={handleClose}>
          <IconButton>
            <CloseIcon />
          </IconButton>
        </DialogCloseBtn>
        <HotelImages
          bgImage={image}
          height="500px"
        >
          <HotelImageCaption>
            <HotelImageTitle>
              {name}
            </HotelImageTitle>
            <HotelImageText>
              {address}
            </HotelImageText>
          </HotelImageCaption>
        </HotelImages>
      </DialogSection>

      <DialogSection bgColor={grey[50]}>
        <FlexFlow padding="1em">
          <FlexFlow splitSpace="1em">
            {(!_.isEmpty(property.amenities) || !_.isEmpty(property.description)) && (
              <Link activeClass="active" to="hotel_description" spy smooth duration={500} containerId="modalContent">
                <HotelLink>{i18n.t('navigation.hotel_card.hotel_info')}</HotelLink>
              </Link>
            )}
            {Object.keys(meetingInfo).length > 0 && (
              <>
                <Link activeClass="active" to="meeting_space" spy smooth duration={500} containerId="modalContent">
                  <HotelLink>{i18n.t('navigation.hotel_card.meeting_space')}</HotelLink>
                </Link>
                <Link activeClass="active" to="amenities" spy smooth duration={500} containerId="modalContent">
                  <HotelLink>{i18n.t('navigation.hotel_card.amenities')}</HotelLink>
                </Link>
                {(!_.isEmpty(property.propertyImages)) && (
                  <Link activeClass="active" to="gallery" spy smooth duration={500} containerId="modalContent">
                    <HotelLink>{i18n.t('navigation.hotel_card.gallery')}</HotelLink>
                  </Link>
                )}
                <Link activeClass="active" to="guest_rooms" spy smooth duration={500} containerId="modalContent">
                  <HotelLink>{i18n.t('navigation.hotel_card.guest_rooms')}</HotelLink>
                </Link>
              </>
            )}

            {property && (
              <Link activeClass="active" to="location" spy smooth duration={500} containerId="modalContent">
                <HotelLink>{i18n.t('navigation.hotel_card.location')}</HotelLink>
              </Link>
            )}
          </FlexFlow>

          {canEbid && (
            <FlexFlow margin="0 0 0 auto">
              <ColorButton variant="outlined" onClick={() => toggleHotel(!isHotelAdded)}>
                {!isHotelAdded && (
                  <span>{i18n.t('hotel_card.actions.add_to_list')}</span>
                )}
                {isHotelAdded && (
                  <span>{i18n.t('hotel_card.actions.remove_from_list')}</span>
                )}
              </ColorButton>
            </FlexFlow>
          )}
        </FlexFlow>
      </DialogSection>

      {(!_.isEmpty(property.amenities) || !_.isEmpty(property.description)) && (
        <Element name="hotel_description">
          <HotelDescription
            amenities={property.amenities}
            description={property.description}
          />
        </Element>
      )}

      {Object.keys(meetingInfo).length > 0 && (
        <>
          <Element name="meeting_space">
            <MeetingSpace meetingRoomInfo={meetingInfo} />
          </Element>
          <Element name="amenities">
            <Amenities meetingRoomInfo={meetingInfo} />
          </Element>
          {(!_.isEmpty(property.propertyImages)) && (
            <Element name="gallery">
              <Gallery propertyImages={meetingInfo.propertyImages} />
            </Element>
          )}
          <Element name="guest_rooms">
            <GuestRooms meetingRoomInfo={meetingInfo} />
          </Element>
        </>
      )}

      {property && (
        <Element name="location">
          <Location event={event} property={property} />
        </Element>
      )}
    </>
  );
}

ModalContent.defaultProps = {};

ModalContent.propTypes = {
  meetingInfo: PropTypes.shape().isRequired,
  event: PropTypes.shape().isRequired,
  property: PropTypes.shape().isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default ModalContent;
