/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Divider from '../../../../Common/Divider/Divider';
import {
  FlexFlow, FormLabel,
} from '../../../../Common/helpers/DisplayHelpers';
import NumberField from '../../../../Common/Form/NumberField';

import { i18n, i18nARAttr } from '../../../../../src/custom/Internationalization';

function EditRoomNight({
  roomNight,
  onSaveRoomNight,
}) {
  const [fields, updateFields] = useState({ ...roomNight });

  const handleUpdateFieldByName = (name, value) => {
    const newData = {};
    newData[name] = value;
    const newFields = { ...fields, ...newData };
    updateFields(newFields);
    onSaveRoomNight(newFields);
  };

  useEffect(() => {
    updateFields({ ...roomNight, ...fields });
  }, [roomNight]);

  return (
    <FlexFlow column>
      <Divider />

      {['single', 'double', 'suite'].map((t) => {
        const itemValue = roomNight;
        const numKey = `${t}_sleeping_room_amount`;
        const rateKey = `${t}_sleeping_room_cost_cents`;
        const label = i18nARAttr(`bid_content_day.${t}_sleeping_room_amount`);
        return (
          <FlexFlow key={t} align="center" padding="0 0 1em" splitSpace="1em" nowrap>
            <FormLabel>
              {label}
            </FormLabel>
            <NumberField
              small
              nomargin
              id="single_num"
              label={i18n.t('ebid_sidebar.labels.num_rooms')}
              defaultNum={itemValue[numKey] || ''}
              onChanged={(value) => (
                handleUpdateFieldByName(numKey, +value)
              )}
            />
            <NumberField
              small
              nomargin
              priceCent
              id="single_rate"
              label={i18n.t('ebid_sidebar.labels.rate')}
              defaultNum={itemValue[rateKey] || ''}
              onChanged={(value) => (
                handleUpdateFieldByName(rateKey, +value)
              )}
            />
          </FlexFlow>
        );
      })}
    </FlexFlow>
  );
}

EditRoomNight.defaultProps = {
  roomNight: {
    double_sleeping_room_amount: 0,
    double_sleeping_room_cost_cents: 0,
    double_sleeping_room_cost_currency: 'USD',
    single_sleeping_room_amount: 0,
    single_sleeping_room_cost_cents: 0,
    single_sleeping_room_cost_currency: 'USD',
    suite_sleeping_room_amount: 0,
    suite_sleeping_room_cost_cents: 0,
    suite_sleeping_room_cost_currency: 'USD',
  },
};

EditRoomNight.propTypes = {
  roomNight: PropTypes.shape({
    double_sleeping_room_amount: PropTypes.number,
    double_sleeping_room_cost_cents: PropTypes.number,
    double_sleeping_room_cost_currency: PropTypes.string,
    single_sleeping_room_amount: PropTypes.number,
    single_sleeping_room_cost_cents: PropTypes.number,
    single_sleeping_room_cost_currency: PropTypes.string,
    suite_sleeping_room_amount: PropTypes.number,
    suite_sleeping_room_cost_cents: PropTypes.number,
    suite_sleeping_room_cost_currency: PropTypes.string,
  }),
  onSaveRoomNight: PropTypes.func.isRequired,
};

export default EditRoomNight;
