import React from 'react';
import PropTypes from 'prop-types';
import CheckIcon from '@mui/icons-material/Check';
import { i18n } from '../../../src/custom/Internationalization';

import {
  RoomCardWrapper,
  RoomCardLogo,
  RoomCardContent,
  RoomCardInfo,
  RoomCardPriceBlock,
  PriceTitle,
  RoomCardPrice,
  Title,
  Description,
  NotificationText,
  RoomCardText,
  BadgeList,
  SelectedArea,
  DisabledArea,
} from './styles';

function RoomCard({
  id,
  title,
  description,
  notification,
  roomImage,
  labels,
  children,
  priceBlock: {
    priceString,
    currency,
    price,
    rateText,
  },
  isDisabled,
  isSelected,
  available,
  toggleSelectedCard,
}) {
  return (
    <RoomCardWrapper className="CardWrapper" isSelected={isSelected}>
      <RoomCardLogo bgImage={roomImage} />
      <RoomCardContent>
        <RoomCardInfo>
          <BadgeList>
            {labels.map((l) => (
              <span key={l.text} className={`tag ${available ? 'tag--info' : 'tag--error'}`}>
                {l.text}
              </span>
            ))}
          </BadgeList>

          {title && (
            <Title className="tw-text-xl tw-text-primary tw-font-bold tw-font-header">{title}</Title>
          )}

          {description && (
            <Description
              className="tw-text-base tw-font-sans tw-mt-5 tw-p-0"
              dangerouslySetInnerHTML={{ __html: description }}
            />
          )}

          {notification && (
            <NotificationText>{notification}</NotificationText>
          )}

          {children && (
            <RoomCardText>
              {children}
            </RoomCardText>
          )}
        </RoomCardInfo>
        <RoomCardPriceBlock>
          {priceString ? (
            <PriceTitle className="tw-text-3xl tw-font-bold tw-text-primary tw-font-header">
              {priceString}
            </PriceTitle>
          ) : (
            <PriceTitle className="tw-text-3xl tw-font-bold tw-text-primary tw-font-header">
              {currency}
              {price}
            </PriceTitle>
          )}
          {rateText && (
            <RoomCardPrice className="tw-text-blue-grey-600 tw-text-sm tw-font-base tw-font-sans">
              {rateText}
            </RoomCardPrice>
          )}
          {available && (
            <button
              type="button"
              className="bttn bttn--secondary tw-mt-6"
              color={isDisabled ? 'secondary' : 'primary'}
              onClick={() => toggleSelectedCard(id)}
            >
              {i18n.t('actions.select')}
            </button>
          )}
          {isSelected && (
            <SelectedArea>
              <CheckIcon />
              <Title>{i18n.t('labels.selected')}</Title>
              <button
                type="button"
                className="bttn bttn--secondary bttn--link"
                onClick={() => toggleSelectedCard(null)}>
                {i18n.t('actions.unselect')}
              </button>

            </SelectedArea>
          )}
        </RoomCardPriceBlock>
      </RoomCardContent>

      {isDisabled && (
        <DisabledArea />
      )}
    </RoomCardWrapper>
  );
}

RoomCard.defaultProps = {
  roomImage: '',
  title: '',
  description: '',
  notification: '',
  labels: [],
  priceBlock: {
    priceString: '',
    currency: '',
    price: '',
    rateText: '',
  },
  isDisabled: false,
  isSelected: false,
  available: true,
  children: [],
};

RoomCard.propTypes = {
  id: PropTypes.number.isRequired,
  title: PropTypes.string,
  roomImage: PropTypes.string,
  description: PropTypes.string,
  notification: PropTypes.string,
  labels: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    color: PropTypes.string,
  })),
  priceBlock: PropTypes.shape({
    priceString: PropTypes.string,
    currency: PropTypes.string,
    price: PropTypes.string,
    rateText: PropTypes.string,
  }),
  isDisabled: PropTypes.bool,
  isSelected: PropTypes.bool,
  available: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  toggleSelectedCard: PropTypes.func.isRequired,
};

export default RoomCard;
