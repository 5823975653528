/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { grey } from '@mui/material/colors';

import moment from 'moment';

import Accordion from '../../Common/Accordion/Accordion';
import { FlexFlow } from '../../Common/helpers/DisplayHelpers';
import { i18n, i18nARAttr } from '../../../src/custom/Internationalization';

import {
  CellHeader,
  CellBody,
  TableRow,
} from '../styles';

export function EventData(eventData) {
  return (
    <FlexFlow column grow splitSpace="1em" padding="0 0 2em">
      <Accordion
        config={{
          titleIcon: <KeyboardArrowDownIcon />,
          titleText: i18n.t('approval_form.headers.event_information'),
          titleColor: grey[800],
          titleIconColor: grey[800],
          titleBGColor: grey[100],
          titleBorderColor: grey[100],
          contentBGColor: grey[50],
          showIconDivider: true,
        }}
      >
        <FlexFlow column grow>
          <TableRow>
            <CellHeader>
              {i18nARAttr('event.name')}
            </CellHeader>
            <CellBody>
              {eventData.name}
            </CellBody>
          </TableRow>
          <TableRow>
            <CellHeader>
              {i18nARAttr('event.date')}
            </CellHeader>
            <CellBody>
              {moment(eventData.startDate).format('ddd, MMM DD, YYYY')}
            </CellBody>
          </TableRow>
          <TableRow>
            <CellHeader>
              {i18nARAttr('event.event_type')}
            </CellHeader>
            <CellBody>
              {eventData.eventType}
            </CellBody>
          </TableRow>
          <TableRow>
            <CellHeader>
              {i18nARAttr('event.location')}
            </CellHeader>
            <CellBody>
              {eventData.location}
            </CellBody>
          </TableRow>
          <TableRow>
            <CellHeader>
              {i18nARAttr('event.address')}
            </CellHeader>
            <CellBody>
              {eventData.address}
            </CellBody>
          </TableRow>
        </FlexFlow>
      </Accordion>
    </FlexFlow>
  );
}

EventData.defaultProps = {
  eventData: {},
};
EventData.propTypes = {
  eventData: PropTypes.shape().isRequired,
};

export default EventData;
