/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  createContext,
  useState,
  useEffect,
  useRef,
} from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import * as yup from 'yup';
import { createYupSchema } from '../../Common/helpers/yupSchemaCreator';

export const Context = createContext({});

export function Provider({
  eventId,
  organizationId,
  submitUrl,
  typesUrl,
  approvalData,
  children,
}) {
  const token = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
  axios.defaults.headers.common['X-CSRF-Token'] = token;

  const axiosInstance = axios.create({
    baseURL: submitUrl,
    headers: { 'Content-Type': 'application/json' },
  });

  const getAxiosInstance = axios.create({
    baseURL: typesUrl,
    headers: { 'Content-Type': 'application/json' },
  });

  const defaultFields = {
    contacts: yup.array().required().min(1),
  };
  const defaultSchema = yup.object().shape(defaultFields);

  const plainFields = useRef({});

  const [fields, setFields] = useState({
    sections: [],
    contacts: [],
    ccEmails: [],
    ...approvalData,
  });
  const [validationError, setValidationError] = useState([]);
  const [usedFields, setUsedFields] = useState([]);
  const [approvalTypes, setApprovalTypes] = useState([]);
  const [approvalForms, setApprovalForms] = useState([]);
  const [alertOpen, setAlertOpen] = useState(false);
  const [validateSchema, setValidateSchema] = useState(defaultSchema);
  const [showErrors, setShowErrors] = useState(false);

  const { approvalType } = fields;

  useEffect(() => {
    const typeData = approvalType ? approvalForms.find((t) => t.id === approvalType) : false;
    const sections = typeData ? typeData.sections : [];

    let allFields = [];

    sections.forEach((s) => {
      const { baseFields, tableFields } = s;
      allFields = allFields.concat(baseFields).concat(tableFields);
    });

    allFields = allFields.map((f) => {
      let vType = '';
      switch (f.type) {
        case 'numeric':
        case 'pricefield':
          vType = 'number';
          break;
        case 'select_currency':
        case 'date':
        case 'currency':
        case 'text':
        case 'textarea':
        case 'textfield':
        default:
          vType = 'string';
          break;
      }
      return ({ ...f, validationType: vType });
    });

    const yepSchema = allFields.reduce(createYupSchema, defaultFields);
    const vSchema = yup.object().shape(yepSchema);
    setValidateSchema(vSchema);
  }, [approvalType, approvalForms]);

  const getApprovalTypes = (response) => {
    if (response && response.types && response.types.length > 0) {
      setApprovalTypes(response.types.map((i) => ({
        value: i.id,
        label: i.label,
      })));
      setApprovalForms(response.types.map((i) => ({
        id: i.id,
        label: i.label,
        description: i.description,
        sections: i.sections,
      })));
    } else {
      setApprovalTypes([]);
      setApprovalForms([]);
    }
  };

  // ====================================
  // Validation

  const handleFormValidation = (formFields) => {
    validateSchema
      .validate(formFields, { abortEarly: false })
      .then(
        () => {
          setValidationError([]);
        },
        (response) => {
          setValidationError(response.inner);
        },
      );
  };

  const updateUsedFields = (name) => {
    if (!usedFields.includes(name)) {
      usedFields.push(name);
      setUsedFields(usedFields);
      handleFormValidation(plainFields.current);
    }
  };

  const hasErrorMessage = (name) => {
    const messages = [];
    validationError.forEach((e) => {
      if ((e.path === name) && (usedFields.includes(name) || showErrors)) {
        messages.push(e.message);
      }
    });
    return messages;
  };

  // ====================================
  // Init App

  useEffect(() => {
    handleFormValidation(plainFields.current);
  }, []);

  // ====================================
  // General Actions

  const loadApprovalTypes = () => {
    getAxiosInstance.get()
      .then((res) => {
        getApprovalTypes(res.data);
      });
  };

  const handleUpdateValue = (name, data, sectionId, fieldId) => {
    const newData = {};
    newData[name] = data;
    plainFields.current = Object.assign(plainFields.current, newData);

    if (sectionId) {
      const { sections } = fields;

      // Update existing section
      const newSections = sections.map((s) => {
        const newSData = {};
        if (s.id === sectionId) {
          newSData[name] = { value: data, id: fieldId };
        }
        return Object.assign(s, newSData);
      });

      const sId = sections.findIndex((s) => (s.id === sectionId));
      if (sId === -1) { // Add new section
        const newItem = { id: sectionId };
        newItem[name] = { value: data, id: fieldId };
        newSections.push(newItem);
      }

      setFields({ ...fields, sections: newSections });
    } else { // General fields
      const newFields = fields;
      newFields[name] = data;
      setFields(newFields);
    }

    handleFormValidation(plainFields.current);
  };

  const sendApprovalRequest = () => {
    // TODO: Send approval request
  };

  const handleSubmitForm = () => {
    setShowErrors(true);

    const {
      approvalType: approvalTypeId,
      contacts,
      ccEmail,
      ...rest
    } = fields;

    axiosInstance.post('/', {
      event_id: eventId,
      organization_id: organizationId,
      id: approvalTypeId,
      contacts,
      cc_email: ccEmail,
      fields: rest,
    }).then(() => {
      setAlertOpen(true);
    });
  };

  const contextData = {
    data: {
      contacts: [],
      response: {},
    },
    approvalTypes,
    approvalForms,
    formData: fields,
    loadApprovalTypes,
    setField: handleUpdateValue,
    submitForm: handleSubmitForm,
    isFormValid: validationError.length === 0,
    updateUsedFields,
    hasErrorMessage,
    alertOpen,
    setAlertOpen,
    showErrors,
    setShowErrors,
    sendApprovalRequest,
    plainFields,
    validationError,
  };

  return (
    <Context.Provider value={contextData}>
      {children}
    </Context.Provider>
  );
}

export const { Consumer } = Context;

Provider.defaultProps = {
  approvalData: null,
};

Provider.propTypes = {
  eventId: PropTypes.number.isRequired,
  organizationId: PropTypes.number.isRequired,
  submitUrl: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  approvalData: PropTypes.shape(),
};
