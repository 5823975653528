/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import {
  MdFlag,
  MdPlace,
  MdFavorite,
  MdFavoriteBorder,
  MdAccessTime,
  MdCheckCircle,
  MdDirectionsCar,
} from 'react-icons/md';
import {
  HotelCardWrapper,
  HotelLabels,
  HotelImages,
  HotelContent,
  HotelContentData,
  HotelContentPromoText,
  HotelContentTitle,
  HotelContentAddress,
  HotelContentDistance,
  HotelFavLink,
  StageLabel,
} from './styles';
import Rate from '../../Common/Rate/Rate';
import PhotoSlider from '../../Common/PhotoSlider/PhotoSlider';
import FullscreenDialog from '../../Dialogs/FullScreenDialog';
import AlertMessage from '../../Common/AlertMessage/AlertMessage';
import {
  FlexFlow, ColorButton,
} from '../../Common/helpers/DisplayHelpers';
import { i18n } from '../../../src/custom/Internationalization';
import ModalContent from './content/ModalContent';
import MenuActions from './MenuActions';

function HotelCard({
  filepickerConfigs,
  contractSignatureData,
  contractSignatureRequestUrl,
  declineUrl,
  setFavoriteUrl,
  updateStageUrl,
  changeDatesUrl,
  emailTemplates,
  bidId,
  stage,
  getMeetingInfoURL,
  event,
  property,
  property: {
    name,
    address,
    rating,
    isFavorite,
    isNegotiated,
    isPreferred,
    image: hotelImage,
    distanceText,
    distanceDurationText,
    promoText,
  },
}) {
  const [bidStage, setBidStage] = useState(stage);
  const [markAsFavorite, toogleFavorite] = useState(isFavorite);
  const [isDetailsModalOpen, toggleDetailsModal] = useState(false);
  const [meetingsRoomInfo, setMeetingsRoomInfo] = useState({});

  const [alertData, setAlertData] = useState({
    open: false,
    handleClose: () => setAlertData({ ...alertData, open: false }),
    variant: 'success',
    message: '',
    duration: 2000,
  });

  const stages = {
    tentative: 'Tentative',
    internal_signature_requested: 'Internal Signature Requested',
    contract_signed: 'Contract Signed',
    definite: 'Definite',
    declined: 'Declined',
    new: 'New',
  };

  const showSuccessAlert = (message) => {
    setAlertData({
      open: true,
      variant: 'success',
      message,
      handleClose: () => setAlertData({ ...alertData, open: false }),
      duration: 2000,
    });
  };

  const showErrorAlert = (message, error) => {
    window.Rollbar.error(error);

    setAlertData({
      open: true,
      variant: 'error',
      message,
      handleClose: () => setAlertData({ ...alertData, open: false }),
      duration: 2000,
    });
  };

  const axiosInstance = axios.create({
    baseUrl: '/',
    headers: {
      'Content-Type': 'application/json',
    },
  });

  useEffect(() => {
    axiosInstance.get(getMeetingInfoURL).then((res) => {
      const formData = res.data;
      setMeetingsRoomInfo(formData);
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleContractSignatureRequest = (data) => {
    const {
      email, subject, message, attachments,
    } = data;
    axiosInstance.post(contractSignatureRequestUrl, {
      bid_id: bidId,
      message_body: message,
      subject,
      request_email_field: email,
      attachments,
    }).then((res) => {
      if (res.status === 200) {
        showSuccessAlert(i18n.t('bid_request.notices.request_sent'));
        setBidStage(res.data);
      }
    }).catch((error) => {
      showErrorAlert(i18n.t('errors.notices.generic_failure'), error);
    });
  };

  const handleDeclineAction = (reason) => {
    axiosInstance.patch(declineUrl, {
      bid: { decline_reason: reason },
    }).then((res) => {
      if (res.status === 200) {
        showSuccessAlert(i18n.t('bid_request.notices.stage_changed'));
        setBidStage(res.data);
      }
    }).catch((error) => {
      showErrorAlert(i18n.t('errors.notices.generic_failure'), error);
    });
  };

  const handleToggleFavorite = (newValue) => {
    axiosInstance.post(setFavoriteUrl).then((res) => {
      if (res.status === 204) {
        showSuccessAlert(i18n.t('bid_request.notices.bid_updated'));
        toogleFavorite(newValue);
      }
    }).catch((error) => {
      showErrorAlert(i18n.t('errors.notices.generic_failure'), error);
    });
  };

  const handleChangeStageAction = (newStage) => {
    axiosInstance.post(updateStageUrl, {
      stage: newStage,
    }).then((res) => {
      if (res.status === 200) {
        showSuccessAlert(i18n.t('bid_request.notices.stage_changed'));
        setBidStage(res.data);
      }
    }).catch((error) => {
      showErrorAlert(i18n.t('errors.notices.generic_failure'), error);
    });
  };

  const { propertyImages } = meetingsRoomInfo;
  return (
    <>
      <HotelCardWrapper>
        <HotelImages bgImage={(!propertyImages || propertyImages.length < 1) ? hotelImage : ''}>
          {propertyImages && propertyImages.length > 0 && (
            <PhotoSlider
              images={propertyImages}
              height="250px"
              slidesPerView={3}
              gapSize={5}
            />
          )}
        </HotelImages>

        <HotelContent>
          <HotelContentData>
            <FlexFlow nowrap splitSpace="1em" justify="flex-start">
              {isPreferred && (
                <HotelLabels>
                  <MdCheckCircle />
                  {i18n.t('hotel_card.labels.preferred')}
                </HotelLabels>
              )}

              {isNegotiated && (
                <HotelLabels>
                  <MdFlag />
                  {i18n.t('hotel_card.labels.negotiated')}
                </HotelLabels>
              )}

              {rating > 0 && (
                <Rate max={5} value={rating} />
              )}
            </FlexFlow>

            {name && name.length > 0 && (
              <HotelContentTitle>
                <HotelFavLink
                  favorite={markAsFavorite}
                  onClick={() => handleToggleFavorite(!markAsFavorite)}
                >
                  {markAsFavorite ? <MdFavorite /> : <MdFavoriteBorder />}
                </HotelFavLink>

                <span>
                  {name}
                </span>
              </HotelContentTitle>
            )}

            {address && address.length > 0 && (
              <>
                <HotelContentAddress>
                  <MdPlace />
                  {address}
                </HotelContentAddress>
                <FlexFlow splitSpace="1em" nowrap padding="0 0.2em">
                  <HotelContentDistance>
                    <MdDirectionsCar />
                    {distanceText}
                  </HotelContentDistance>
                  {distanceDurationText?.length > 0 && (
                    <HotelContentDistance>
                      <MdAccessTime />
                      {i18n.t('hotel_card.labels.duration_text', { duration_text: distanceDurationText, event_name: event.name })}
                    </HotelContentDistance>
                  )}
                </FlexFlow>
              </>
            )}

            {promoText && promoText.length > 0 && (
              <HotelContentPromoText>
                {promoText}
              </HotelContentPromoText>
            )}
          </HotelContentData>

          <HotelContentData actions>
            <FlexFlow splitSpace="1em" column nowrap>
              <StageLabel stage={bidStage}>
                {i18n.t(`bid_request.stages.${bidStage}`)}
              </StageLabel>

              {getMeetingInfoURL && getMeetingInfoURL.length > 0 && (
                <ColorButton
                  size="small"
                  variant="outlined"
                  onClick={() => toggleDetailsModal(true)}
                >
                  {i18n.t('hotel_card.actions.hotel_details')}
                </ColorButton>
              )}

              <MenuActions
                filepickerConfigs={filepickerConfigs}
                bidId={bidId}
                changeDatesUrl={changeDatesUrl}
                emailTemplates={emailTemplates}
                event={event}
                stage={bidStage}
                stages={stages}
                contractSignatureData={contractSignatureData}
                onDecline={(data) => handleDeclineAction(data)}
                onChangeStage={handleChangeStageAction}
                onContractSignatureRequest={handleContractSignatureRequest}
              />
            </FlexFlow>
          </HotelContentData>
        </HotelContent>
      </HotelCardWrapper>

      <FullscreenDialog
        open={isDetailsModalOpen}
        handleClose={() => toggleDetailsModal(false)}
      >
        <ModalContent
          event={event}
          property={property}
          meetingInfo={meetingsRoomInfo}
          handleClose={() => toggleDetailsModal(false)}
        />
      </FullscreenDialog>

      <AlertMessage {...alertData} />
    </>
  );
}

HotelCard.defaultProps = {};

HotelCard.propTypes = {
  event: PropTypes.shape().isRequired,
  filepickerConfigs: PropTypes.shape().isRequired,
  contractSignatureData: PropTypes.shape().isRequired,
  contractSignatureRequestUrl: PropTypes.string.isRequired,
  declineUrl: PropTypes.string.isRequired,
  setFavoriteUrl: PropTypes.string.isRequired,
  property: PropTypes.shape().isRequired,
  getMeetingInfoURL: PropTypes.string.isRequired,
  updateStageUrl: PropTypes.string.isRequired,
  changeDatesUrl: PropTypes.string.isRequired,
  changeRequirementsUrl: PropTypes.string.isRequired,
  requestContractUrl: PropTypes.string.isRequired,
  hotelReminderUrl: PropTypes.string.isRequired,
  followUpUrl: PropTypes.string.isRequired,
  emailTemplates: PropTypes.shape().isRequired,
  bidId: PropTypes.number.isRequired,
  stage: PropTypes.string.isRequired,
};

export default HotelCard;
