/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useState,
  useEffect,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import SwitchField from '../../../../Common/Form/SwitchField/SwitchField';
import Divider from '../../../../Common/Divider/Divider';
import {
  FlexFlow, ScrollableArea,
} from '../../../../Common/helpers/DisplayHelpers';
import DateField from '../../../../Common/Form/DateField/DateField';
import NumberField from '../../../../Common/Form/NumberField';
import EditRoomNight from './EditRoomNight';
import { i18n, i18nARAttr } from '../../../../../src/custom/Internationalization';

function EditSleepingRooms({ editRoomsData }) {
  const {
    bidFields,
    updateBidFields,
    roomNights,
    updateRooms,
    defaultRoomsData,
    setDefaultRoomsData,
  } = editRoomsData;

  const {
    start_date: startDate,
    end_date: endDate,
    sleeping_room_tax: sleepingRoomTax,
    sleeping_room_flat_tax_cents: sleepingRoomFlatTaxCents,
    sleeping_room_resort_fee_cents: sleepingRoomResortFeeCents,
  } = bidFields;

  const [showPerNight, togglePerNight] = useState(false);
  const getNumOfNights = (start, end) => {
    const momentStartDate = moment(start).utc();
    const momentEndDate = moment(end).utc();
    return momentEndDate.diff(momentStartDate, 'days');
  };

  const updateRoomNights = useCallback(({ startDate: startDay, endDate: endDay }) => {
    const newRoomNights = [];

    if (startDay && endDay) {
      const nightsNum = getNumOfNights(startDay, endDay) - 1;
      const steps = (nightsNum > 0) ? Array.from(Array(nightsNum).keys()) : [];

      const firstNight = roomNights.find((r) => r.date === startDay);
      newRoomNights.push(
        firstNight
          ? { ...firstNight }
          : { ...defaultRoomsData, date: moment(startDay).format('YYYY-MM-DD') },
      );

      steps.forEach((i) => {
        const currentNight = moment(startDay).add(i + 1, 'd').format('YYYY-MM-DD');
        const roomNight = roomNights.find((r) => r.date === currentNight);
        newRoomNights.push(
          roomNight
            ? { ...roomNight, date: currentNight }
            : { ...newRoomNights[0], date: currentNight },
        );
      });

      if (newRoomNights.length > 0) {
        setDefaultRoomsData(newRoomNights[0]);
      }
      updateRooms(newRoomNights);
    }
  }, [defaultRoomsData, roomNights, setDefaultRoomsData, updateRooms]);

  useEffect(() => {
    updateRoomNights({
      startDate,
      endDate,
    });
  }, [showPerNight]);

  const handleUpdateFieldByName = (name, value) => {
    const newData = {};
    newData[name] = value;
    updateBidFields(() => ({ ...bidFields, ...newData }));
  };

  const handleDateChange = (dateField) => {
    const newFieldValues = { ...bidFields, ...dateField };
    const { start_date: startDay, end_date: endDay } = newFieldValues;
    updateBidFields(() => ({ ...newFieldValues }));
    updateRoomNights({ startDate: startDay, endDate: endDay });
  };

  const handleUpdateRoomNights = (date, value) => {
    const { start_date: startDay, end_date: endDay } = bidFields;
    let updatedItems = [];

    if (date === 'all') {
      const nightsNum = getNumOfNights(startDay, endDay) - 1;
      const steps = (nightsNum > 0) ? Array.from(Array(nightsNum).keys()) : [];

      const firstNight = roomNights.find((r) => r.date === startDay);
      updatedItems.push(
        firstNight
          ? { ...firstNight, ...value }
          : { ...defaultRoomsData, ...value, date: moment(startDay).format('YYYY-MM-DD') },
      );

      steps.forEach((i) => {
        const currentNight = moment(startDay).add(i + 1, 'd').format('YYYY-MM-DD');
        const roomNight = roomNights.find((r) => r.date === currentNight);
        updatedItems.push(
          roomNight
            ? { ...roomNight, ...value, date: currentNight }
            : { ...updatedItems[0], ...value, date: currentNight },
        );
      });
    } else {
      updatedItems = roomNights.map((rn) => (
        (rn.date === date)
          ? { ...rn, ...value }
          : { ...rn }
      ));
    }

    updateRooms(updatedItems);
  };

  useEffect(() => {
    const currentDay = moment(startDate);
    if (currentDay.isAfter(endDate) || !moment(endDate).isValid()) {
      currentDay.add(1, 'd');
      handleDateChange({
        end_date: currentDay.format('YYYY-MM-DD'),
      });
    }
  }, [startDate]);

  return (
    <FlexFlow column nowrap>
      <FlexFlow padding="0 0 1em" splitSpace="1em" nowrap>
        <DateField
          id="start_date"
          label={i18nARAttr('event.start_date')}
          value={startDate || null}
          minDate={moment().format()}
          onDateSelected={(date) => handleDateChange({
            start_date: moment(date, 'YYYY-MM-DD').format('YYYY-MM-DD'),
          })}
        />
        <DateField
          id="end_date"
          label={i18nARAttr('event.end_date')}
          value={endDate || null}
          minDate={startDate || moment().format()}
          onDateSelected={(date) => handleDateChange({
            end_date: moment(date, 'YYYY-MM-DD').format('YYYY-MM-DD'),
          })}
        />
      </FlexFlow>

      <Divider />

      <SwitchField
        label={i18n.t('ebid_sidebar.actions.rates_different')}
        value={showPerNight}
        onChange={(value) => (togglePerNight(value))}
      />

      {!showPerNight && (
        <EditRoomNight
          roomNight={roomNights[0]}
          onSaveRoomNight={(value) => handleUpdateRoomNights('all', value)}
        />
      )}

      {showPerNight && (
        <ScrollableArea height="250px">
          {roomNights.sort((a, b) => new Date(a.date) - new Date(b.date)).map((rn, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <FlexFlow key={index} column>
              <Divider label={moment(rn.date).format('ddd, MMM DD, YYYY')} />

              <EditRoomNight
                roomNight={rn}
                onSaveRoomNight={(value) => handleUpdateRoomNights(rn.date, value)}
              />
            </FlexFlow>
          ))}
        </ScrollableArea>
      )}

      <Divider />

      <FlexFlow align="center" splitSpace="1em" nowrap>
        <NumberField
          small
          nomargin
          id="tax_percent"
          label={i18nARAttr('bid_content.sleeping_room_tax')}
          defaultNum={sleepingRoomTax || ''}
          min={0}
          max={100}
          onChanged={(value) => handleUpdateFieldByName('sleeping_room_tax', value)}
        />

        <NumberField
          small
          nomargin
          priceCent
          id="flat_tax"
          label={i18nARAttr('bid_content.sleeping_room_flat_tax')}
          defaultNum={sleepingRoomFlatTaxCents || ''}
          onChanged={(value) => handleUpdateFieldByName('sleeping_room_flat_tax_cents', value)}
        />

        <NumberField
          small
          nomargin
          priceCent
          id="resort_fee"
          label={i18nARAttr('bid_content.sleeping_room_resort_fee')}
          defaultNum={sleepingRoomResortFeeCents || ''}
          onChanged={(value) => handleUpdateFieldByName('sleeping_room_resort_fee_cents', value)}
        />
      </FlexFlow>
    </FlexFlow>
  );
}

EditSleepingRooms.propTypes = {
  editRoomsData: PropTypes.shape().isRequired,
};

export default EditSleepingRooms;
