import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

import {
  FaCaretDown,
} from 'react-icons/fa';
import ConfirmationDialog from '../../Dialogs/ConfirmationDialog';
import { ModalContext } from '../context';
import ApproveModalForm from './modalForm';
import ApproveModalFormData from './modalFormData';

import { i18n } from '../../../src/custom/Internationalization';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function ApprovalContent({
  approvers,
  filepickerOptions,
  eventData,
  isApprovalData,
  canApprove,
}) {
  const context = useContext(ModalContext);
  const {
    approvalTypes,
    approvalForms,
    setField,
    alertOpen,
    setAlertOpen,
    submitForm,
    isFormValid,
    setShowErrors,
    sendApprovalRequest,
    loadApprovalTypes,
  } = context;

  const [modalOpened, setModalOpened] = useState(isApprovalData || false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [typeDescription, setTypeDescription] = useState('');

  const handleOpenMenu = (event) => {
    loadApprovalTypes();
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleClose = () => {
    setModalOpened(false);
  };

  const handleCloseAlert = () => setAlertOpen(false);

  const loadTypeDescription = (typeId) => {
    const typeData = approvalForms.find((t) => t.id === typeId);
    setTypeDescription(typeData && typeData.description);
  };

  const openSelectedModal = (type) => {
    loadTypeDescription(type);
    setField('approvalType', type, null);
    setModalOpened(true);
    handleCloseMenu();
  };

  const handleSubmitForm = () => {
    if (isApprovalData) {
      sendApprovalRequest();
    } else {
      setShowErrors(false);

      if (isFormValid) {
        submitForm();
        handleClose();
      } else {
        setShowErrors(true);
      }
    }
  };

  const getConfirmBtnCopy = () => {
    if (isApprovalData) {
      return canApprove ? i18n.t('actions.approve') : false;
    }
    return i18n.t('actions.send');
  };

  const getCancelBtnCopy = () => ((isApprovalData && !canApprove) ? i18n.t('actions.close') : false);

  return (
    <>
      {!isApprovalData && (
        <>
          <button
            className="gr-button navigation capitalized icon right hover:tw-bg-slate-50"
            onClick={handleOpenMenu}
          >
            <span className="tw-uppercase tw-font-sans tw-font-bold tw-text-blue-grey-700 tw-text-sm tw-leading-6">
              {i18n.t("approval_form.headers.requests")}
            </span>
            <span className="g-icon-chevron-down tw-w-6 tw-h-6 tw-text-blue-grey-700 tw-ml-2 tw-text-2xl tw-leading-6"></span>
          </button>

          <Menu
            id="request-approval"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleCloseMenu}
          >
            {approvalTypes.length == 0 && (
              <MenuItem key="">{i18n.t('actions.loading')}</MenuItem>
            )}
            {approvalTypes.length > 0 && (approvalTypes.map(({ value, label }) => (
              <MenuItem key={value} onClick={() => openSelectedModal(value)}>{label}</MenuItem>
            )))}
          </Menu>
        </>
      )}

      <ConfirmationDialog
        open={modalOpened}
        handleCancel={handleClose}
        showTitle
        showTitleCloseBtn
        title={i18n.t('approval_form.headers.approval_action_request')}
        subtitle={typeDescription}
        fullWidth
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="md"
        showActionBtns
        confirmBtnCopy={getConfirmBtnCopy()}
        cancelBtnCopy={getCancelBtnCopy()}
        handleConfirm={handleSubmitForm}
      >
        {!isApprovalData && (
          <ApproveModalForm
            approvers={approvers}
            approvalForms={approvalForms}
            handleCloseModal={handleClose}
            filepickerOptions={filepickerOptions}
            eventData={eventData}
          />
        )}
        {isApprovalData && (
          <ApproveModalFormData
            approvers={approvers}
            approvalForms={approvalForms}
            handleCloseModal={handleClose}
            filepickerOptions={filepickerOptions}
            eventData={eventData}
          />
        )}
      </ConfirmationDialog>

      <Snackbar
        open={alertOpen}
        autoHideDuration={5000}
        onClose={handleCloseAlert}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert onClose={handleCloseAlert} severity="success">
          {i18n.t('approval_form.notices.sent')}
        </Alert>
      </Snackbar>
    </>
  );
}

ApprovalContent.defaultProps = {
  eventData: null,
  isApprovalData: false,
  canApprove: false,
};

ApprovalContent.propTypes = {
  approvers: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  filepickerOptions: PropTypes.shape().isRequired,
  eventData: PropTypes.shape(),
  isApprovalData: PropTypes.bool,
  canApprove: PropTypes.bool,
};

export default ApprovalContent;
