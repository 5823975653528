import React, { useState } from 'react';
import PropTypes from 'prop-types';
import TextArea from '../../../Common/Form/TextArea';
import ConfirmationDialog from '../../../Dialogs/ConfirmationDialog';
import { FlexFlow } from '../../../Common/helpers/DisplayHelpers';
import { i18n } from '../../../../src/custom/Internationalization';

function ChangeRequirementsForm({
  eventData: {
    name,
    template,
  },
  openModal,
  onChangeRequirements,
  onClose,
}) {
  const [requirementChangeData, setRequirementChangeData] = useState({
    requirements: '',
    message: template,
  });

  const { requirements, message } = requirementChangeData;

  return (
    <ConfirmationDialog
      open={openModal}
      title={i18n.t('ebids.headings.change_requirements', { name })}
      maxWidth="md"
      cancelBtnCopy={i18n.t('actions.cancel')}
      confirmBtnCopy={i18n.t('actions.send')}
      showActionBtns
      handleConfirm={() => onChangeRequirements(requirementChangeData)}
      handleCancel={onClose}
    >
      <FlexFlow splitSpace="1em" nowrap column>
        <TextArea
          nomargin
          id="requirement"
          label={i18n.t('ebids.labels.change_add_requirements')}
          value={requirements}
          onChange={(value) => setRequirementChangeData({ ...requirementChangeData, requirements: value })}
        />

        <TextArea
          id="message"
          label={i18n.t('ebids.labels.edit_message')}
          onChange={(value) => setRequirementChangeData({ ...requirementChangeData, message: value })}
          value={message}
          nomargin
        />
      </FlexFlow>
    </ConfirmationDialog>
  );
}

ChangeRequirementsForm.propTypes = {
  openModal: PropTypes.bool.isRequired,
  onChangeRequirements: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  eventData: PropTypes.shape().isRequired,
};

export default ChangeRequirementsForm;
