import styled, { css } from 'styled-components';
import {
  grey,
  red,
  lightBlue,
  green,
  orange,
  purple,
} from '@mui/material/colors';
import colors from '../../Common/constants/colors';

export const HotelCardWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  flex: 0 0 auto;

  background-color: white;
  border: 1px solid ${grey[200]};
  border-radius: 3px;
  border-bottom-width: 3px;
  color: ${grey[800]};

  margin: 0 0 1em 0;
  padding: 0;
  position: relative;
  width: 100%;

  @media (max-width: 992px) {
    flex-flow: column nowrap;
  }
`;

export const HotelLabels = styled.div`
  display: flex;
  flex-flow: row nowrap;
  flex: 0 0 auto;
  align-items: center;

  color: ${colors.groupizeBrand};
  font-size: 0.8em;
  font-weight: 600;
  text-transform: uppercase;

  > svg {
    font-size: 1.2em;
    margin-right: 0.2em;
  }
`;

export const HotelImages = styled.div`
  display: flex;
  flex-flow: row nowrap;
  flex: 1 1 auto;
  height: ${(props) => props.height || '250px'};
  position: relative;

  background-color: black;

  ${(props) => props.bgImage && css`
    background-image: url('${props.bgImage}');
    background-position: center;
    background-size: cover;
  `}
`;

export const HotelImageCaption = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 1em 2em;
  background-color: rgba(250, 250, 250, 0.8);
`;

export const HotelImageTitle = styled.p`
  font-weight: 800;
  font-size: 3em;
  margin: 0;
`;

export const HotelLink = styled.p`
  display: flex;
  flex: 1 0 auto;
  align-items: center;

  border-bottom: 3px solid transparent;
  cursor: pointer;
  height: 100%;
  margin: 0;
  padding: 0 0.5em;

  font-size: 1.2em;
  font-weight: 300;
  line-height: 1.4;
  text-transform: uppercase;
  vertical-align: middle;

  transition: border-color 0.3s ease;

  .active,
  &:hover,
  &:focus {
    border-color: ${grey[600]};
  }
`;

export const HotelImageText = styled.p`
  font-weight: 300;
  font-size: 1.3em;
  margin: 0;
`;

export const HotelContent = styled.div`
  display: flex;
  flex-flow: row nowrap;
  flex: 1 1 auto;
`;

export const HotelContentData = styled.div`
  display: flex;
  flex-flow: column nowrap;
  flex: 1 1 auto;
  padding: 1rem;

  ${(props) => props.actions && css`
    max-width: 200px;
  `}
`;

export const HotelContentPromoText = styled.p`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  background: ${lightBlue[50]};
  border-radius: 5px;
  max-width: 350px;
  margin: 0.5em 0;
  padding: 0.6rem;

  color: ${grey[800]};
  font-size: 0.9em;
  font-weight: 300;
  line-height: 1.4;
`;

export const HotelContentTitle = styled.p`
  display: flex;
  align-items: center;
  flex-flow: row nowrap;

  color: ${grey[800]};
  font-size: 1.4em;
  font-weight: 600;
  line-height: 1.2;

  margin: 0.5em 0;

  > svg {
    color: ${red[800]};
    margin-right: 0.5em;
  }
`;

export const HotelFavLink = styled.a`
  color: inherit;
  cursor: pointer;
  line-height: 1;
  margin-right: 0.5rem;
  text-decoration: none;

  &:hover,
  &:focus {
    color: inherit;
    text-decoration: none;
  }

  ${(props) => props.favorite && css`
    color: ${red[600]};

    &:hover,
    &:focus {
      color: ${red[800]};
    }
  `}
`;

export const HotelContentAddress = styled.div`
  display: flex;
  align-items: center;

  color: ${grey[600]};
  font-size: 1em;
  font-weight: 300;
  line-height: 1;
  margin: 0 0 0.5em;

  > svg {
    font-size: 1.2em;
    margin-right: 0.2em;
  }
`;

export const HotelContentDistance = styled(HotelContentAddress)`
  font-size: 0.7em;
  font-weight: 600;
`;

export const StageLabel = styled.span`
  border-radius: 0.25em;
  padding: 0.25em 0.5em;
  background-color: ${grey[50]};
  color: ${grey[600]};
  text-align: center;

  ${(props) => (props.stage === 'tentative') && css`
    color: ${orange[600]};
  `}

  ${(props) => (props.stage === 'internal_signature_requested') && css`
    color: ${purple[600]};
  `}

  ${(props) => (props.stage === 'contract_signed') && css`
    color: ${green[800]};
  `}

  ${(props) => (props.stage === 'definite') && css`
    color: ${green[600]};
  `}
`;
