/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useState,
  useContext,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { grey } from '@mui/material/colors';
import TextArea from '../../../Common/Form/TextArea';
import PriceText from '../../../Common/PriceText/PriceText';
import Divider from '../../../Common/Divider/Divider';
import {
  FlexFlow, FormLabel, FormText, FormValueNumber,
} from '../../../Common/helpers/DisplayHelpers';

import NumberField from '../../../Common/Form/NumberField';

import EditableCard from './EditableCard';
import { EbidsSidebarContext } from '../context';
import { i18n, i18nARAttr } from '../../../../src/custom/Internationalization';

function OtherCard({
  stage,
  allowEdit,
  handleSubmitCard,
}) {
  const sidebarDataContext = useContext(EbidsSidebarContext);
  const { formData: { bid_contents: bidContents } } = sidebarDataContext;

  const getFieldsByStage = () => {
    const item = bidContents && bidContents.filter((i) => i.stage === stage);
    return (item && item.length > 0) ? item[0] : {};
  };

  const stageFields = getFieldsByStage(stage);
  const stageFieldsStr = JSON.stringify(stageFields);

  const [bidFields, updateBidFields] = useState({});

  useEffect(() => {
    updateBidFields(stageFields);
  }, []);

  useEffect(() => {
    updateBidFields(stageFields);
  }, [stageFieldsStr]);

  const handleUpdateFieldByName = (name, value) => {
    const newData = {};
    newData[name] = value;
    updateBidFields(() => ({ ...bidFields, ...newData }));
  };

  const handleCancelChanges = () => {
    updateBidFields(stageFields);
  };

  const {
    comp_rooms_amount: compRoomsAmount,
    comp_rooms_cost_cents: compRoomsCostCents,
    invoice_cents: invoiceCents,
    collected_cents: collectedCents,
    concessions_cents: concessionsCents,
    other_fees_cents: otherFeesCents,
    concessions_notes: concessionsNotes,
    notes,
  } = bidFields;

  const handleSaveCard = () => {
    handleSubmitCard({
      comp_rooms_amount: compRoomsAmount,
      comp_rooms_cost_cents: compRoomsCostCents,
      invoice_cents: invoiceCents,
      collected_cents: collectedCents,
      concessions_cents: concessionsCents,
      other_fees_cents: otherFeesCents,
      concessions_notes: concessionsNotes,
      notes,
    });
  };

  const editContent = (
    <FlexFlow column nowrap>
      <FlexFlow row nowrap splitSpace="1em" margin="0 0 1em">
        <NumberField
          nomargin
          min={0}
          id="comp_rooms_amount"
          label={i18nARAttr('bid_content.comp_rooms_amount')}
          defaultNum={compRoomsAmount || ''}
          onChanged={(value) => handleUpdateFieldByName('comp_rooms_amount', value)}
        />

        <NumberField
          nomargin
          priceCent
          min={0}
          id="comp_rooms_cost_cents"
          label={i18nARAttr('bid_content.comp_rooms_cost')}
          defaultNum={compRoomsCostCents || ''}
          onChanged={(value) => handleUpdateFieldByName('comp_rooms_cost_cents', value)}
        />
      </FlexFlow>

      <FlexFlow row nowrap splitSpace="1em" margin="0 0 1em">
        <NumberField
          nomargin
          priceCent
          id="invoice_cents"
          label={i18nARAttr('bid_content.invoice_cents')}
          defaultNum={invoiceCents || ''}
          onChanged={(value) => handleUpdateFieldByName('invoice_cents', value)}
        />

        <NumberField
          nomargin
          priceCent
          id="collected_cents"
          label={i18nARAttr('bid_content.collected_cents')}
          defaultNum={collectedCents || ''}
          onChanged={(value) => handleUpdateFieldByName('collected_cents', value)}
        />
      </FlexFlow>

      <FlexFlow row nowrap splitSpace="1em" margin="0 0 1em">
        <NumberField
          nomargin
          priceCent
          id="concessions_cents"
          label={i18nARAttr('bid_content.concessions')}
          defaultNum={concessionsCents || ''}
          onChanged={(value) => handleUpdateFieldByName('concessions_cents', value)}
        />
        <NumberField
          nomargin
          priceCent
          id="other_fees_cents"
          label={i18nARAttr('bid_content.other_fees')}
          defaultNum={otherFeesCents}
          onChanged={(value) => handleUpdateFieldByName('other_fees_cents', value)}
        />
      </FlexFlow>

      <FlexFlow column margin="0 0 1em">
        <TextArea
          id="concessions_notes"
          label={i18nARAttr('bid_content.concessions_notes')}
          value={concessionsNotes}
          onChange={(value) => handleUpdateFieldByName('concessions_notes', value)}
          nomargin
          flexGrow
        />
      </FlexFlow>

      <TextArea
        id="notes"
        label="Notes"
        value={notes}
        onChange={(value) => handleUpdateFieldByName('notes', value)}
        nomargin
        flexGrow
      />
    </FlexFlow>
  );

  const viewContent = (
    <FlexFlow column>
      <FlexFlow row margin="0 0 1em 0">
        <FlexFlow column margin="0 2em 0 0">
          <FormLabel>
            {i18n.t('ebid_sidebar.headings.comp_rooms')}
          </FormLabel>
          <FormValueNumber>
            {compRoomsAmount || 0}
            {' '}
            (
            <PriceText
              sum={compRoomsCostCents}
              currency={bidFields.comp_rooms_cost_currency}
            />
            )
          </FormValueNumber>
        </FlexFlow>

        <FlexFlow column margin="0 2em 0 0">
          <FormLabel>
            {i18n.t('ebid_sidebar.headings.other_cost')}
          </FormLabel>
          <FormValueNumber>
            <PriceText
              sum={otherFeesCents}
              currency={bidFields.other_fees_currency}
            />
          </FormValueNumber>
        </FlexFlow>
      </FlexFlow>

      <FlexFlow column margin="0 0 1em 0">
        <FormLabel>
          {i18n.t('ebid_sidebar.headings.concessions')}
          {' '}
          (
          <PriceText
            sum={concessionsCents}
            currency={bidFields.concessions_currency}
          />
          )
        </FormLabel>
        <FormText>
          {concessionsNotes || ' - '}
        </FormText>
      </FlexFlow>

      <FlexFlow column>
        <FormLabel>
          {i18n.t('ebid_sidebar.headings.notes')}
        </FormLabel>
        <FormText>
          {notes || ' - '}
        </FormText>
      </FlexFlow>

      <Divider />

      <FlexFlow row margin="0 0 1em 0">
        <FlexFlow column margin="0 2em 0 0">
          <FormLabel>
            {i18n.t('ebid_sidebar.headings.invoice')}
          </FormLabel>
          <FormValueNumber>
            <PriceText
              sum={invoiceCents}
              currency={bidFields.invoice_currency}
            />
          </FormValueNumber>
        </FlexFlow>

        <FlexFlow column margin="0 2em 0 0">
          <FormLabel>
            {i18n.t('ebid_sidebar.headings.collected')}
          </FormLabel>
          <FormValueNumber>
            <PriceText
              sum={collectedCents}
              currency={bidFields.collected_currency}
            />
          </FormValueNumber>
        </FlexFlow>
      </FlexFlow>
    </FlexFlow>
  );

  return (
    <EditableCard
      titleText={i18n.t('ebid_sidebar.headings.other_cost')}
      bgColor="#182c61"
      textColor={grey[50]}
      editContent={editContent}
      viewContent={viewContent}
      allowEdit={allowEdit}
      onUpdate={handleSaveCard}
      onCancel={handleCancelChanges}
    />
  );
}

OtherCard.defaultProps = {
  allowEdit: true,
};

OtherCard.propTypes = {
  stage: PropTypes.string.isRequired,
  allowEdit: PropTypes.bool,
  handleSubmitCard: PropTypes.func.isRequired,
};

export default OtherCard;
