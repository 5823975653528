import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import TextArea from '../../../Common/Form/TextArea';
import ConfirmationDialog from '../../../Dialogs/ConfirmationDialog';
import { FlexFlow } from '../../../Common/helpers/DisplayHelpers';
import { i18n, i18nARAttr } from '../../../../src/custom/Internationalization';
import DateField from '../../../Common/Form/DateField/DateField';

function ChangeDatesForm({
  eventData: {
    name,
    start,
    end,
    template,
  },
  openModal,
  onDatesChange,
  onClose,
}) {
  const [dateChangeData, setDateChangeData] = useState({
    startDate: start,
    endDate: end,
    message: template,
  });

  const updateDatesField = (fieldName, value) => {
    const newData = dateChangeData;
    newData[fieldName] = value;
    setDateChangeData(newData);
  };

  useEffect(() => {
    const currentDay = moment(start);
    if (currentDay.isAfter(end) || !moment(end).isValid()) {
      currentDay.add(1, 'd');
      updateDatesField('endDate', currentDay.format('YYYY-MM-DD'));
    }
  }, [start]);

  const { startDate, endDate, message } = dateChangeData;

  return (
    <ConfirmationDialog
      open={openModal}
      title={i18n.t('ebids.headings.date_change', { name })}
      maxWidth="md"
      cancelBtnCopy={i18n.t('actions.cancel')}
      confirmBtnCopy={i18n.t('actions.send')}
      showActionBtns
      handleConfirm={() => onDatesChange(dateChangeData)}
      handleCancel={onClose}
    >
      <>
        <FlexFlow splitSpace="1em" margin="0 0 1em" nowrap>
          <DateField
            id="eventStartDate"
            label={i18nARAttr('event.start_date')}
            nomargin
            minDate={moment().format()}
            value={startDate}
            onDateSelected={(value) => updateDatesField('startDate', value)}
          />
          <DateField
            id="eventEndDate"
            label={i18nARAttr('event.end_date')}
            nomargin
            minDate={startDate || moment().format()}
            value={endDate}
            onDateSelected={(value) => updateDatesField('endDate', value)}
          />
        </FlexFlow>
        <TextArea
          id="message"
          label={i18n.t('ebids.labels.edit_message')}
          onChange={(value) => updateDatesField('message', value)}
          value={message}
          nomargin
        />
      </>
    </ConfirmationDialog>
  );
}

ChangeDatesForm.propTypes = {
  openModal: PropTypes.bool.isRequired,
  onDatesChange: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  eventData: PropTypes.shape().isRequired,
};

export default ChangeDatesForm;
