/* eslint-disable func-names */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable camelcase */

import React, {
  createContext,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import moment from 'moment';
import * as yup from 'yup';

import { i18n, i18nARError } from '../../../src/custom/Internationalization';

const token = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
axios.defaults.headers.common['X-CSRF-Token'] = token;

export const Context = createContext({});

export function Provider({
  baseURL,
  portalURL,
  portalEditURL,
  createBidUrl,
  orgId,
  isBudgetMandatory,
  defaultEventTypeId,
  existingEventId,
  eventData: {
    name: eventName,
    location,
    startDate,
    endDate,
    budget,
    estimated_sleeping_rooms_number,
    target_attendance,
    useEventSpace,
    useRooms,
    meetingType,
    currency,
  },
  children,
}) {
  const registerEventSchema = yup.object().shape({
    meetingType: yup.string(),
    name: yup
      .string()
      .required(),
    location: yup
      .object()
      .test('isEmpty', i18nARError('event.location', 'blank'), function (currentValue) {
        const { meetingType: isVirtual } = this.parent;
        return (isVirtual === 'virtual') || (isVirtual !== 'virtual' && currentValue && currentValue.l);
      })
      .required(),
    isBudgetMandatory: yup
      .boolean(),
    budget: yup
      .string()
      .when('isBudgetMandatory', (mandatory, schema) => (mandatory === true ? schema.required() : schema.notRequired())),
    start_date_field: yup
      .string()
      .required(),
    end_date_field: yup
      .string()
      .test('isAfter', i18n.t('activerecord.errors.event.attributes.end_date.can_not_be_before_start_date'), function (currentValue) {
        const { start_date_field } = this.parent;
        return !start_date_field
          || !currentValue
          || (
            start_date_field
            && currentValue
            && moment(start_date_field) <= moment(currentValue)
          );
      })
      .required(),
    target_attendance: yup.number().when('estimated_sleeping_rooms_number', {
      is: (val) => !val,
      then: (schema) => schema.min(1, 'Rooms or Attendees are required.'),
      otherwise: (schema) => schema.optional(),
    }),
    estimated_sleeping_rooms_number: yup.number().test('Required', 'Rooms or Attendees are required.', function (val) {
      const { target_attendance } = this.parent;
      if (!target_attendance) return val > 0;
      return true;
    }),
  });

  const bookingSchema = yup.object().shape({
    meetingType: yup.string(),
    name: yup
      .string()
      .required(),
    location: yup
      .object()
      .test('isEmpty', i18nARError('event.location', 'blank'), (currentValue) => currentValue && currentValue.l),
    budget: yup
      .string()
      .when('isBudgetMandatory', (mandatory, schema) => (mandatory === true ? schema.required() : schema.notRequired())),
    start_date_field: yup
      .string()
      .required(),
    end_date_field: yup
      .string()
      .test('isAfter', i18n.t('activerecord.errors.event.attributes.end_date.can_not_be_before_start_date'), function (currentValue) {
        const { start_date_field } = this.parent;
        return !start_date_field
          || !currentValue
          || (
            start_date_field
            && currentValue
            && moment(start_date_field) <= moment(currentValue)
          );
      })
      .required(),
    useEventSpace: yup.boolean().test('Required', 'Event space or sleeping rooms are required.', function (val) {
      const { useRooms } = this.parent;
      if (!useRooms) return val;
      return true;
    }),
    useRooms: yup.boolean().test('Required', 'Event space or sleeping rooms are required.', function (val) {
      const { useEventSpace } = this.parent;
      if (!useEventSpace) return val;
      return true;
    }),
    target_attendance: yup.number().when('useEventSpace', {
      is: true,
      then: (schema) => schema.min(1, 'Attendees are required.'),
      otherwise: (schema) => schema.optional(),
    }),
    estimated_sleeping_rooms_number: yup.number().when('useRooms', {
      is: true,
      then: (schema) => schema.min(1, 'Rooms are required.'),
      otherwise: (schema) => schema.optional(),
    }),
  });

  const eventRequestFormSchema = yup.object().shape({
    meetingType: yup.string(),
    name: yup
      .string()
      .required(),
    location: yup
      .object()
      .test('isEmpty', i18nARError('event.location', 'blank'), function (currentValue) {
        const { meetingType: isVirtual } = this.parent;
        return isVirtual === 'virtual' || (isVirtual !== 'virtual' && currentValue && currentValue.l);
      })
      .required(),
    budget: yup
      .string()
      .when('isBudgetMandatory', (mandatory, schema) => (mandatory === true ? schema.required() : schema.notRequired())),
    start_date_field: yup
      .string()
      .required(),
    end_date_field: yup
      .string()
      .test('isAfter', i18n.t('activerecord.errors.event.attributes.end_date.can_not_be_before_start_date'), function (currentValue) {
        const { start_date_field } = this.parent;
        return !start_date_field
          || !currentValue
          || (
            start_date_field
            && currentValue
            && moment(start_date_field) <= moment(currentValue)
          );
      })
      .required(),
    useEventSpace: yup.boolean().test('Required', 'Event space or sleeping rooms are required.', function (val) {
      const { useRooms } = this.parent;
      if (!useRooms) return val;
      return true;
    }),
    useRooms: yup.boolean().test('Required', 'Event space or sleeping rooms are required.', function (val) {
      const { useEventSpace } = this.parent;
      if (!useEventSpace) return val;
      return true;
    }),
    target_attendance: yup.number().when('useEventSpace', {
      is: true,
      then: (schema) => schema.min(1, 'Attendees are required.'),
      otherwise: (schema) => schema.optional(),
    }),
    estimated_sleeping_rooms_number: yup.number().when('useRooms', {
      is: true,
      then: (schema) => schema.min(1, 'Rooms are required.'),
      otherwise: (schema) => schema.optional(),
    }),
  });

  const websiteSchema = yup.object().shape({
    meetingType: yup.string(),
    name: yup
      .string()
      .required(),
    location: yup
      .object()
      .test('isEmpty', i18nARError('event.location', 'blank'), function (currentValue) {
        const { meetingType: isVirtual } = this.parent;
        return isVirtual === 'virtual' || (isVirtual !== 'virtual' && currentValue && currentValue.l);
      })
      .required(),
    start_date_field: yup
      .string()
      .required(),
    end_date_field: yup
      .string()
      .test('isAfter', i18n.t('activerecord.errors.event.attributes.end_date.can_not_be_before_start_date'), function (currentValue) {
        const { start_date_field } = this.parent;
        return !start_date_field
          || !currentValue
          || (
            start_date_field
            && currentValue
            && moment(start_date_field) <= moment(currentValue)
          );
      })
      .required(),
  });

  const axiosInstance = axios.create({
    baseURL,
    headers: { 'Content-Type': 'application/json' },
  });

  const [formData, setFormData] = useState({
    name: eventName || '',
    location: location || {},
    start_date_field: (startDate && moment.utc(startDate).format('YYYY-MM-DD')) || moment().format('YYYY-MM-DD'),
    end_date_field: (endDate && moment.utc(endDate).format('YYYY-MM-DD')) || moment().add(1, 'days').format('YYYY-MM-DD'),
    event_type_id: defaultEventTypeId,
    budget: budget || '',
    estimated_sleeping_rooms_number: estimated_sleeping_rooms_number || 0,
    target_attendance: target_attendance || 0,
    useEventSpace,
    useRooms,
    meetingType: 'in_person',
    currency,
  });

  const [systemResponseErrors, setSystemResponseErrors] = useState([]);
  const [requestedEventId, setRequestedEventId] = useState(existingEventId);

  const [isRegisterEventFormLoading, setRegisterEventFormLoading] = useState(false);
  const [registerEventFormErrors, setRegisterEventFormErrors] = useState([]);

  const [isBookingFormLoading, setBookingFormLoading] = useState(false);
  const [bookingFormErrors, setBookingFormErrors] = useState([]);
  const [bookingResponseErrors, setBookingResponseErrors] = useState([]);

  const [isEventRequestFormLoading, setEventRequestFormLoading] = useState(false);
  const [eventRequestFormErrors, setEventRequestFormErrors] = useState([]);

  const [isWebsiteFormLoading, setWebsiteFormLoading] = useState(false);
  const [websiteFormErrors, setWebsiteFormErrors] = useState([]);

  // ====================================
  // Validation

  const handleRegisterEventFormValidation = (formFields, onSuccess, onError) => {
    registerEventSchema
      .validate({ ...formFields, isBudgetMandatory }, { abortEarly: false })
      .then(
        () => {
          setRegisterEventFormErrors([]);
          if (onSuccess && typeof (onSuccess) === 'function') {
            onSuccess();
          }
        },
        (response) => {
          setRegisterEventFormErrors(response.inner);
          if (onError && typeof (onError) === 'function') {
            onError();
          }
        },
      );
  };

  const handleBookingFormValidation = (formFields, onSuccess, onError) => {
    bookingSchema
      .validate({ ...formFields, isBudgetMandatory }, { abortEarly: false })
      .then(
        () => {
          setBookingFormErrors([]);
          if (onSuccess && typeof (onSuccess) === 'function') {
            onSuccess();
          }
        },
        (response) => {
          setBookingFormErrors(response.inner);
          if (onError && typeof (onError) === 'function') {
            onError();
          }
        },
      );
  };

  const handleEventRequestFormValidation = (formFields, onSuccess, onError) => {
    eventRequestFormSchema
      .validate({ ...formFields, isBudgetMandatory }, { abortEarly: false })
      .then(
        () => {
          setEventRequestFormErrors([]);
          if (onSuccess && typeof (onSuccess) === 'function') {
            onSuccess();
          }
        },
        (response) => {
          setEventRequestFormErrors(response.inner);
          if (onError && typeof (onError) === 'function') {
            onError();
          }
        },
      );
  };

  const handleWebsiteFormValidation = (formFields, onSuccess, onError) => {
    websiteSchema
      .validate({ ...formFields, isBudgetMandatory }, { abortEarly: false })
      .then(
        () => {
          setWebsiteFormErrors([]);
          if (onSuccess && typeof (onSuccess) === 'function') {
            onSuccess();
          }
        },
        (response) => {
          setWebsiteFormErrors(response.inner);
          if (onError && typeof (onError) === 'function') {
            onError();
          }
        },
      );
  };
  // ====================================
  // Meeting Form Data

  const handleUpdateRegisterEventFormValue = (name, value) => {
    const newData = {};
    newData[name] = value;
    setFormData({ ...formData, ...newData });
  };

  const submitRegisterEventForm = () => {
    handleRegisterEventFormValidation(
      formData,
      () => {
        setRegisterEventFormLoading(true);
        const {
          location: loc,
          budget,
          name,
          start_date_field,
          end_date_field,
          event_type_id,
          target_attendance,
          estimated_sleeping_rooms_number,
          meetingType,
          currency,
        } = formData;

        const location_address = loc ? loc.l : '';
        const latitude = (loc && loc.latLng) ? loc.latLng.lat : '';
        const longitude = (loc && loc.latLng) ? loc.latLng.lng : '';

        const formToSubmit = {
          name,
          start_date_field,
          end_date_field,
          event_type_id,
          target_attendance,
          estimated_sleeping_rooms_number,
          organization_id: orgId,
          location_address,
          latitude,
          longitude,
          currency,
          budget: budget / 100,
          event_format: meetingType,
        };

        axiosInstance.post(portalURL, { event: formToSubmit, flow: 'allow_event' })
          .then((res) => {
            if (res.status === 200 && res.data.status === 'success') {
              window.location.pathname = res.data.target_path;
            }

            if (res.status === 200 && res.data.status === 'error') {
              setSystemResponseErrors(res.data.errors);
            }

            setRegisterEventFormLoading(false);
          })
          .catch((error) => {
            console.log(error);
            setSystemResponseErrors([i18n.t('errors.notices.generic_failure')]);
            setRegisterEventFormLoading(false);
          });
      },
    );
  };
  // ====================================
  // Website Form Data

  const handleUpdateWebsiteFormValue = (name, value) => {
    const newData = {};
    newData[name] = value;
    setFormData({ ...formData, ...newData });
  };

  const submitWebsiteForm = () => {
    handleWebsiteFormValidation(
      formData,
      () => {
        setWebsiteFormLoading(true);
        const {
          location: loc,
          budget,
          name,
          start_date_field,
          end_date_field,
          event_type_id,
          target_attendance,
          estimated_sleeping_rooms_number,
          currency,
          meetingType,
        } = formData;

        const location_address = loc ? loc.l : '';
        const latitude = (loc && loc.latLng) ? loc.latLng.lat : '';
        const longitude = (loc && loc.latLng) ? loc.latLng.lng : '';

        const formToSubmit = {
          name,
          start_date_field,
          end_date_field,
          event_type_id,
          target_attendance,
          estimated_sleeping_rooms_number,
          organization_id: orgId,
          location_address,
          latitude,
          longitude,
          currency,
          budget: budget / 100,
          event_format: meetingType,
        };

        axiosInstance.post(portalURL, { event: formToSubmit, flow: 'allow_website' })
          .then((res) => {
            if (res.status === 200 && res.data.status === 'success') {
              window.location.pathname = res.data.target_path;
            }

            if (res.status === 200 && res.data.status === 'error') {
              setSystemResponseErrors(res.data.errors);
            }

            setWebsiteFormLoading(false);
          })
          .catch((error) => {
            console.log(error);
            setSystemResponseErrors([i18n.t('errors.notices.generic_failure')]);
            setWebsiteFormLoading(false);
          });
      },
    );
  };

  // ====================================
  // Event Request Form Data

  const handleUpdateEventRequestFormValue = (name, value) => {
    const newData = {};
    newData[name] = value;
    setFormData({ ...formData, ...newData });
  };

  const submitEventRequestForm = () => {
    handleEventRequestFormValidation(
      formData,
      () => {
        setEventRequestFormLoading(true);
        const {
          location: loc,
          budget,
          name,
          start_date_field,
          end_date_field,
          event_type_id,
          target_attendance,
          estimated_sleeping_rooms_number,
          meetingType,
          currency,
        } = formData;

        const location_address = loc ? loc.l : '';
        const latitude = (loc && loc.latLng) ? loc.latLng.lat : '';
        const longitude = (loc && loc.latLng) ? loc.latLng.lng : '';

        const formToSubmit = {
          name,
          start_date_field,
          end_date_field,
          event_type_id,
          target_attendance,
          estimated_sleeping_rooms_number,
          organization_id: orgId,
          location_address,
          latitude,
          longitude,
          currency,
          budget: budget / 100,
          event_format: meetingType,
        };

        axiosInstance.post(portalURL, { event: formToSubmit, flow: 'allow_erf' })
          .then((res) => {
            if (res.status === 200 && res.data.status === 'success') {
              const pattern = /^((http|https):\/\/)/;
              if (pattern.test(res.data.target_path)) {
                window.location.href = res.data.target_path;
              } else {
                window.location.pathname = res.data.target_path;
              }
            }

            if (res.status === 200 && res.data.status === 'error') {
              setSystemResponseErrors(res.data.errors);
            }

            setEventRequestFormLoading(false);
          })
          .catch((error) => {
            console.log(error);
            setSystemResponseErrors([i18n.t('errors.notices.generic_failure')]);
            setEventRequestFormLoading(false);
          });
      },
    );
  };

  // ====================================
  // Booking Form Data

  const handleUpdateBookingFormValue = (name, value) => {
    const newData = {};
    newData[name] = value;
    setFormData({ ...formData, ...newData });
  };

  const handleAxiosResponse = (res) => {
    if (res.status === 200 && res.data.status === 'success') {
      window.location.pathname = res.data.target_path;
    }

    if (res.status === 200 && res.data.status === 'error') {
      let errors;
      if (res.data.permission_errors) {
        errors = res.data.permission_errors.map((message) => ({
          path: 'rules_error',
          message,
        }));
        setBookingResponseErrors(errors);
        if (res.data.event_id) {
          setRequestedEventId(res.data.event_id);
        }
      } else {
        errors = res.data.errors.map((message) => ({
          path: 'failed_api_request',
          message,
        }));
        setSystemResponseErrors(errors);
      }
    }
    setBookingFormLoading(false);
  };

  const setErrorState = (error) => {
    console.log(error);
    setSystemResponseErrors([i18n.t('errors.notices.generic_failure')]);
    setBookingFormLoading(false);
  };

  const updateBid = (formToSubmit, isEbidsWithERF) => {
    axiosInstance.patch(portalEditURL, {
      event: formToSubmit,
      flow: isEbidsWithERF === true ? 'bid_request_with_erf' : 'allow_shop',
    }).then(handleAxiosResponse).catch(setErrorState);
  };

  const createBid = (formToSubmit, isEbidsWithERF) => {
    axiosInstance.post(createBidUrl, {
      event: formToSubmit,
      flow: isEbidsWithERF === true ? 'bid_request_with_erf' : 'allow_shop',
    }).then(handleAxiosResponse).catch(setErrorState);
  };

  const submitBookingForm = (eventId, isEbidsWithERF = false) => {
    handleBookingFormValidation(
      formData,
      () => {
        setBookingFormLoading(true);
        const {
          target_attendance,
          estimated_sleeping_rooms_number,
          meetingType,
          event_type_id,
          budget,
          location: loc,
          name,
          start_date_field,
          end_date_field,
          currency,
        } = formData;

        const location_address = loc ? loc.l : '';
        const latitude = (loc && loc.latLng) ? loc.latLng.lat : '';
        const longitude = (loc && loc.latLng) ? loc.latLng.lng : '';

        const transformationsFuncs = [
          (obj) => ({
            ...obj,
            location_address,
            latitude,
            longitude,
            event_type_id,
            budget: budget / 100,
            estimated_sleeping_rooms_number,
            target_attendance,
            meetingType,
            currency,
            event_format: meetingType,
          }),
        ];

        const formToSubmit = transformationsFuncs.reduce((obj, fn) => fn(obj), {
          name,
          start_date_field,
          end_date_field,
        });

        if (portalEditURL) {
          updateBid(formToSubmit, isEbidsWithERF);
        } else if (createBidUrl) {
          createBid(formToSubmit, isEbidsWithERF);
        } else {
          axiosInstance.post(portalURL, {
            event: formToSubmit,
            eventId,
            flow: eventId ? 'create_event_request_form_from_shop' : 'allow_shop',
          }).then(handleAxiosResponse).catch(setErrorState);
        }
      },
    );
  };

  const contextData = {
    formData,
    systemResponseErrors,
    setFormData,
    requestedEventId,

    isRegisterEventFormLoading,
    registerEventFormErrors,
    updateRegisterEventFormField: handleUpdateRegisterEventFormValue,
    submitRegisterEventForm,

    isBookingFormLoading,
    bookingFormErrors,
    bookingResponseErrors,
    updateBookingFormField: handleUpdateBookingFormValue,
    submitBookingForm,

    isEventRequestFormLoading,
    eventRequestFormErrors,
    updateEventRequestFormField: handleUpdateEventRequestFormValue,
    submitEventRequestForm,

    isWebsiteFormLoading,
    websiteFormErrors,
    updateWebsiteFormField: handleUpdateWebsiteFormValue,
    submitWebsiteForm,
  };

  return (
    <Context.Provider value={contextData}>
      {children}
    </Context.Provider>
  );
}

export const { Consumer } = Context;

Provider.defaultProps = {
  baseURL: null,
  portalEditURL: null,
  createBidUrl: null,
  eventData: {},
  existingEventId: null,
};

Provider.propTypes = {
  baseURL: PropTypes.string,
  portalURL: PropTypes.string.isRequired,
  portalEditURL: PropTypes.string,
  createBidUrl: PropTypes.string,
  orgId: PropTypes.number.isRequired,
  defaultEventTypeId: PropTypes.number.isRequired,
  isBudgetMandatory: PropTypes.bool.isRequired,
  eventData: PropTypes.shape(),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  existingEventId: PropTypes.number,
};
