import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ModalContextProvider } from './context';
import ApprovalContent from './content';

import { i18n } from '../../src/custom/Internationalization';

export const ContentWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  max-width: 300px;
`;

function ApprovalRequestForm({
  eventId,
  organizationId,
  approvers,
  filepickerOptions,
  typesUrl,
  submitUrl,
  eventData,
  isApprovalData,
  canApprove,
  approvalData,
}) {
  return (
    <React.Suspense fallback={i18n.t('actions.loading')}>
      <ModalContextProvider
        eventId={eventId}
        organizationId={organizationId}
        submitUrl={submitUrl}
        typesUrl={typesUrl}
        approvalData={approvalData}
      >
        <ContentWrapper>
          <ApprovalContent
            brandColor="#73BD5D"
            filepickerOptions={filepickerOptions}
            eventData={eventData}
            approvers={approvers}
            isApprovalData={isApprovalData}
            canApprove={canApprove}
          />
        </ContentWrapper>
      </ModalContextProvider>
    </React.Suspense>
  );
}

ApprovalRequestForm.defaultProps = {
  eventData: null,
  isApprovalData: false,
  canApprove: false,
  approvalData: null,
};

ApprovalRequestForm.propTypes = {
  eventId: PropTypes.number.isRequired,
  organizationId: PropTypes.number.isRequired,
  approvers: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  filepickerOptions: PropTypes.shape().isRequired,
  typesUrl: PropTypes.string.isRequired,
  submitUrl: PropTypes.string.isRequired,
  eventData: PropTypes.shape(),
  isApprovalData: PropTypes.bool,
  canApprove: PropTypes.bool,
  approvalData: PropTypes.shape(),
};

export default ApprovalRequestForm;
