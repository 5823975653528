import React, { useState } from 'react';
import PropTypes from 'prop-types';
import MuiMenu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import _ from 'lodash';

import { FlexFlow, ColorButton } from '../../../Common/helpers/DisplayHelpers';
import { i18n } from '../../../../src/custom/Internationalization';

import InternalSignatureForm from './InternalSignatureForm';
import DeclineForm from './DeclineForm';

function MenuActions({
  filepickerConfigs,
  event,
  stage,
  stages,
  contractSignatureData,
  onDecline,
  onChangeStage,
  onContractSignatureRequest,
}) {
  // Menus
  const [menuAnchorElement, setMenuAnchorElement] = useState(null);

  // Forms
  const [isDeclineFormOpened, setDeclineFormOpened] = useState(false);
  const [isInternalSignatureFormOpened, setInternalSignatureFormOpened] = useState(false);

  const handleActionsMenuClose = () => {
    setMenuAnchorElement(null);
  };

  const handleStageChange = (stageKey, changeStage) => {
    handleActionsMenuClose();
    if (stageKey === 'internal_signature_requested') {
      setInternalSignatureFormOpened(true);
    } else {
      changeStage(stageKey);
    }
  };

  const renderStages = (currentStage, changeStage) => _.reduce(stages, (result, _stageName, stageKey) => {
    if (stageKey !== currentStage) {
      result.push((
        <MenuItem key={stageKey} onClick={() => handleStageChange(stageKey, changeStage)}>
          {i18n.t(`bid_request.stages.${stageKey}`)}
        </MenuItem>
      ));
    }

    return result;
  }, []);

  const closeAllMenus = () => {
    setMenuAnchorElement(null);
  };

  const changeStage = (state) => {
    onChangeStage(state);
    closeAllMenus();
  };

  const handleDeclineClick = () => {
    handleActionsMenuClose();
    setDeclineFormOpened(true);
  };

  const handleActionsMenuOpen = (e) => {
    setMenuAnchorElement(e.currentTarget);
  };

  const handleInternalSignatureRequest = (data) => {
    onContractSignatureRequest(data);
    onChangeStage('internal_signature_requested');
  };

  return (
    <>
      <ColorButton
        size="small"
        variant="outlined"
        onClick={(e) => handleActionsMenuOpen(e)}
      >
        <FlexFlow
          splitSpace="0.2em"
          nowrap
          padding="0 0 0 0.5em"
          align="center"
        >
          <span>{i18n.t('bid_request.actions.change_stage')}</span>
          <ArrowRightIcon />
        </FlexFlow>
      </ColorButton>

      <MuiMenu
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        open={Boolean(menuAnchorElement)}
        anchorEl={menuAnchorElement}
        onClose={handleActionsMenuClose}
      >
        {renderStages(stage, changeStage)}
        <MenuItem onClick={() => handleDeclineClick()}>
          {i18n.t('actions.decline')}
        </MenuItem>
      </MuiMenu>

      <InternalSignatureForm
        filepickerConfigs={filepickerConfigs}
        initialBody={contractSignatureData.body}
        initialSubject={contractSignatureData.subject}
        openModal={isInternalSignatureFormOpened}
        eventData={{ ...event }}
        onClose={() => {
          setInternalSignatureFormOpened(false);
          closeAllMenus();
        }}
        onChangeStage={(data) => {
          handleInternalSignatureRequest(data);
          closeAllMenus();
          setInternalSignatureFormOpened(false);
        }}
      />

      <DeclineForm
        openModal={isDeclineFormOpened}
        onClose={() => {
          setDeclineFormOpened(false);
          closeAllMenus();
        }}
        onDecline={(data) => {
          onDecline(data);
          closeAllMenus();
          setDeclineFormOpened(false);
        }}
      />
    </>
  );
}

MenuActions.propTypes = {
  event: PropTypes.shape().isRequired,
  filepickerConfigs: PropTypes.shape().isRequired,
  contractSignatureData: PropTypes.shape().isRequired,
  onDecline: PropTypes.func.isRequired,
  onChangeStage: PropTypes.func.isRequired,
  onContractSignatureRequest: PropTypes.func.isRequired,
  stages: PropTypes.shape().isRequired,
  stage: PropTypes.string.isRequired,
};

export default MenuActions;
